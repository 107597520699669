import { sortBy } from 'lodash-es'
import moment from 'moment'

export const sortTasksByExecution = taskList => {
  if (Array.isArray(taskList)) {
    return sortBy(taskList, new Date(['lastTaskExecution.timestamp']))
  }
  return []
}

const filterRecentTasks = taskList => {
  if (Array.isArray(taskList)) {
    return taskList.filter(task => {
      if (task.lastTaskExecution.timestamp) {
        return moment(task.lastTaskExecution.timestamp).isSameOrAfter(moment().subtract(7, 'd'))
      }

      return null
    })
  }

  return []
}

export const sortRecentTasks = taskList => {
  if (Array.isArray(taskList)) {
    const filteredTasks = filterRecentTasks(taskList)
    return sortTasksByExecution(filteredTasks)
  }

  return []
}

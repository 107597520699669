import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import useSWR from 'swr'

import DateTime from '@therms/web-common/components/DateTime'
import FailedToLoadResourceMsg from 'components/FailedToLoadResourceMsg'
import Loading from '@therms/web-common/components/Loading'
import { makeSingleTaskRoute, makeSingleTaskRequestRoute } from '../shared/routePaths'
import RibbonField from 'components/RibbonField'

import './TaskThumb.scss'

const TaskThumb = ({ id }) => {
  const { data: task, error } = useSWR(makeSingleTaskRequestRoute(id))

  if (error) return <FailedToLoadResourceMsg />

  if (!task) return <Loading />

  const { lastTaskExecution, occurrence, scheduleFrequency, title } = task

  const lastExecutionField = lastTaskExecution.timestamp ? (
    <RibbonField
      name="Last Completed"
      value={<DateTime timestamp={lastTaskExecution.timestamp} relativeTime="ago" />}
    />
  ) : (
    <RibbonField name="Not Completed" />
  )

  return (
    <Link
      to={makeSingleTaskRoute(id)}
      className="bg-light-100 block mb-2 non-styled-link parent-link-highlight-background task-thumb"
    >
      <div className="flex flex-wrap">
        <RibbonField
          name="Occurrence"
          value={scheduleFrequency ? scheduleFrequency.frequency : occurrence}
        />

        <>{lastExecutionField}</>
      </div>

      <div className="p-3">
        <h3 className="font-bold mb-0 text-base">{title}</h3>
      </div>

      <div className="bg-light-400 child-link-highlight-background h-2" />
    </Link>
  )
}

TaskThumb.propTypes = {
  id: PropTypes.string.isRequired,
}

export default TaskThumb

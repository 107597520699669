import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import classnames from 'classnames'

import FAIcon from '@therms/web-common/components/Icon/FAIcon'

const TaskHeader = ({ description, path, title }) => {
  const content = (
    <div className={classnames({ 'px-4 py-3': Boolean(!path) }, 'bg-white flex-1 truncate w-full')}>
      <h2 className="font-bold mb-0 text-base truncate">{title}</h2>
      <p className="mb-0 text-base-700 truncate">{description}</p>
    </div>
  )

  if (Boolean(path))
    return (
      <Link
        to={path}
        className="bg-white block flex justify-between items-center non-styled-link parent-link-highlight-text parent-slide-right px-4 py-3 w-full"
      >
        {content}

        <div>
          <FAIcon
            name="angle-right"
            className="block child-link-highlight-text child-slide-right px-3"
            lg
          />
        </div>
      </Link>
    )

  return content
}

TaskHeader.propTypes = {
  description: PropTypes.string,
  path: PropTypes.string,
  title: PropTypes.string,
}

TaskHeader.defaultProps = {
  description: '',
  path: undefined,
  title: '',
}

export default TaskHeader

import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import {
  ROUTES_MAIL_ROOT,
  ROUTES_MAIL_INBOX,
  ROUTES_MAIL_NEW,
  ROUTES_MAIL_SENT,
} from './shared/routePaths'

import IconTab from 'components/IconTab'
import Inbox from './screens/Inbox'
import NewMail from './screens/NewMail'
import Sent from './screens/Sent'

import './index.scss'

const Mail = () => (
  <div>
    <div className="flex justify-between">
      <div className="flex">
        <IconTab iconName="inbox" linkSlug={ROUTES_MAIL_INBOX}>
          Inbox
        </IconTab>

        <IconTab iconName="paper-plane" linkSlug={ROUTES_MAIL_SENT}>
          Sent
        </IconTab>
      </div>

      <IconTab iconLast iconName="plus" linkSlug={ROUTES_MAIL_NEW}>
        New Mail
      </IconTab>
    </div>

    <div className="bg-dark-500 h-4 w-100" />

    <Switch>
      <Route path={ROUTES_MAIL_INBOX} component={Inbox} />
      <Route path={ROUTES_MAIL_NEW} component={NewMail} />
      <Route path={ROUTES_MAIL_SENT} component={Sent} />
      <Redirect from={ROUTES_MAIL_ROOT} to={ROUTES_MAIL_INBOX} />
    </Switch>
  </div>
)

export default Mail

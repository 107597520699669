import React from 'react'
import PropTypes from 'prop-types'
import useSWR from 'swr'

import FAIcon from '@therms/web-common/components/Icon/FAIcon'
import FailedToLoadResourceMsg from 'components/FailedToLoadResourceMsg'
import Loading from '@therms/web-common/components/Loading'
import { makeReportTypeRequestRoute } from '../../shared/routePaths'

const ReportTypeIcon = ({ lg, reportTypeId }) => {
  const { data: reportType, error } = useSWR(makeReportTypeRequestRoute(reportTypeId))

  if (error) return <FailedToLoadResourceMsg />

  if (!reportType) return <Loading sm />

  return <FAIcon name={reportType.icon} lg={lg} />
}

ReportTypeIcon.propTypes = {
  lg: PropTypes.bool,
  reportTypeId: PropTypes.string.isRequired,
}

ReportTypeIcon.defaultProps = {
  lg: false,
}

export default ReportTypeIcon

import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import LocationSwapItem from './components/LocationSwapItem'
import { ROUTES_LOCATIONS_ROOT } from 'modules/Locations/shared/routePaths'

import useIsMobileScreen from 'modules/Layout/shared/useIsMobileScreen'

import './LocationSwapper.scss'

const LocationSwapper = ({ setIsOpen }) => {
  const locationList = useSelector((state) => state.locations.list)
  const isMobile = useIsMobileScreen()
  const thisElRef = useRef()

  const onClickOutsideHandler = (event) => {
    const isClickInside = thisElRef.current.contains(event.target)
    if (!isClickInside) setIsOpen(false)
  }

  useEffect(() => {
    document.addEventListener('click', onClickOutsideHandler)
    return () => document.removeEventListener('click', onClickOutsideHandler)
  }, [])

  return (
    <div
      className={`bg-white location-swapper shadow ${
        isMobile ? 'location-swapper-mobile' : 'location-swapper-desktop'
      }`}
      ref={thisElRef}
    >
      <div className="px-2">
        {locationList.slice(0, 5).map((location) => (
          <LocationSwapItem location={location} setIsOpen={setIsOpen} />
        ))}
      </div>
      {locationList.length > 5 && (
        <Link
          to={ROUTES_LOCATIONS_ROOT}
          className="bg-light-100 block font-bold non-styled-link p-2 text-center"
          onClick={() => setIsOpen(false)}
        >
          Browse all locations
        </Link>
      )}
    </div>
  )
}

LocationSwapper.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
}

export default LocationSwapper

import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import ActivityTypeNames from 'modules/ActivityTypes/shared/ActivityTypeNames'
import { makeDispatchRecordRoute } from '../shared/routePaths'
import PriorityBlockBadge from 'components/PriorityBlockBadge'

import './DispatchThumb.scss'

const DispatchThumb = ({ dispatchRecord }) => {
  const { activityTypeIds, reportingPartySummary, priority } = dispatchRecord.fields

  return (
    <Link
      to={makeDispatchRecordRoute(dispatchRecord.id)}
      className="bg-light-100 block dispatch-thumb non-styled-link parent-link-highlight-background mb-2"
    >
      <div className="bg-dark-900 flex flex-wrap">
        <header className="bg-dark-800 flex flex-grow-max justify-between">
          <h2 className="font-bold mb-0 my-auto px-3 py-2 text-base-100 text-base">
            {dispatchRecord.uniqueId}
          </h2>
        </header>

        {Array.isArray(activityTypeIds) && (
          <div className="flex-grow">
            <ActivityTypeNames ids={activityTypeIds} />
          </div>
        )}
      </div>

      <p className="bg-light-100 mb-0 p-3">{reportingPartySummary}</p>

      <PriorityBlockBadge priority={priority} />

      <div className="bg-light-400 child-link-highlight-background h-2" />
    </Link>
  )
}

DispatchThumb.propTypes = {
  dispatchRecord: PropTypes.object.isRequired,
}

export default DispatchThumb

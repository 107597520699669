import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { ROUTES_RECORDS_SINGLE } from './routePaths'

import RecordUniqueId from '../components/RecordUniqueId'

const RouteBreadcrumbs = () => {
  return (
    <nav className="breadcrumb-container">
      <ol className="flex mb-0">
        <li className="route-breadcrumb">RECORDS</li>

        <Switch>
          <Route
            path={ROUTES_RECORDS_SINGLE}
            render={({ match }) => (
              <li className="route-breadcrumb">
                <span className="mx-2">/</span>
                <RecordUniqueId recordId={match.params.recordId} />
              </li>
            )}
          />
        </Switch>
      </ol>
    </nav>
  )
}

RouteBreadcrumbs.propTypes = {}
RouteBreadcrumbs.defaultProps = {}

export default RouteBreadcrumbs

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import FAIcon from '@therms/web-common/components/Icon/FAIcon'

import './PageNavigator.scss'

const PageNavigator = ({ currentPage, limit, setCurrentPage, small, totalAmount }) => {
  const checkIfLastPage = () => {
    const itemsSeen = limit * currentPage + limit
    return itemsSeen >= totalAmount
  }

  const [isFirstPage, setIsFirstPage] = useState(!currentPage)
  const [isLastPage, setIsLastPage] = useState(checkIfLastPage())

  useEffect(() => {
    setIsFirstPage(!currentPage)
    setIsLastPage(checkIfLastPage())
  }, [currentPage])

  const onNext = () => {
    if (!isLastPage) setCurrentPage(currentPage + 1)
  }
  const onPrev = () => {
    if (!isFirstPage) setCurrentPage(currentPage - 1)
  }

  if (limit >= totalAmount) return null

  return (
    <nav className="bg-dark-800 flex flex-grow justify-between page-navigator">
      {!isFirstPage && (
        <button
          className="bg-dark-700 border-r-2 border-dark-800 flex-grow highlight-hover parent-slide-left parent-link-highlight-text px-12 text-base-100 w-full"
          onClick={onPrev}
        >
          <FAIcon
            name="angle-left"
            className="child-link-highlight-text child-slide-left"
            lg={!small}
          />
        </button>
      )}

      {!isLastPage && (
        <button
          className="bg-dark-700 border-l-2 border-dark-800 flex-grow highlight-hover parent-link-highlight-text parent-slide-right px-12 text-base-100 w-full"
          onClick={onNext}
        >
          <FAIcon
            name="angle-right"
            className="child-link-highlight-text child-slide-right"
            lg={!small}
          />
        </button>
      )}
    </nav>
  )
}

PageNavigator.propTypes = {
  currentPage: PropTypes.number.isRequired,
  limit: PropTypes.number,
  setCurrentPage: PropTypes.func.isRequired,
  small: PropTypes.bool,
  totalAmount: PropTypes.number,
}

PageNavigator.defaultProps = {
  limit: 10,
  small: false,
  totalAmount: undefined,
}

export default PageNavigator

let envOverride = {}

try {
  envOverride = JSON.parse(localStorage.getItem('ENV_OVERRIDE')) || {}

  if (Object.keys(envOverride).length) console.warn('ENV OVERRIDES SET:', envOverride)
} catch (e) {
  console.log('ENV_OVERRIDE parsing errror, must be JSON object', e)
}

export const isProdEnv = process.env.NODE_ENV === 'production'

export default {
  NODE_ENV: envOverride.NODE_ENV || process.env.NODE_ENV,
  RELEASE: process.env.RELEASE,
}

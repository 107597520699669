import React from 'react'
import useSWR from 'swr'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import ActivityTypeNames from 'modules/ActivityTypes/shared/ActivityTypeNames'
import DateTime from '@therms/web-common/components/DateTime'
import DispatchEntry from '../components/DispatchEntry'
import DispatchSectionHeader from '../components/DispatchSectionHeader'
import FAIcon from '@therms/web-common/components/Icon/FAIcon'
import FailedToLoadResourceMsg from 'components/FailedToLoadResourceMsg'
import Loading from '@therms/web-common/components/Loading'
import LocationName from 'modules/Locations/shared/LocationName'
import { makeDispatchRequestRoute } from '../shared/routePaths'
import PriorityBadge from 'components/PriorityBadge'
import RecordThumbsContainer from 'modules/Records/shared/RecordThumbsContainer'
import RibbonField from 'components/RibbonField'
import { selectCurrentLocationId } from 'state/ducks/locations'
import UserName from 'modules/User/shared/UserName'

import './Dispatch.scss'
import Files from 'modules/Files/shared/Files'

const Dispatch = () => {
  const currentLocationId = useSelector(selectCurrentLocationId)
  const dispatchId = useParams().dispatchId
  const { data: dispatchRecord, error } = useSWR(
    makeDispatchRequestRoute({ dispatchId, locationId: currentLocationId }),
  )

  if (error) return <FailedToLoadResourceMsg />

  if (!dispatchRecord) return <Loading />

  const {
    locationId,
    subLocationId,
    uniqueId,
    fields: {
      actionNeededArrivedTime,
      actionNeededClearedRecordIds,
      actionNeededClearedSummary,
      actionNeededClearedTime,
      actionNeededClearedUserId,
      actionNeededNotes,
      activityTypeIds,
      assignmentByUserId,
      assignmentTime,
      assignmentToUserId,
      acknowledged,
      acknowledgedTime,
      acknowledgedUserId,
      priority,
      reportingParty,
      reportingPartySummary,
      reportingPhone,
      reportingTime,
    },
    files
  } = dispatchRecord

  const dispatchLocation = (
    <div>
      <span>
        <LocationName locationId={locationId} />{' '}
      </span>
      {subLocationId && (
        <span>
          <FAIcon name="angle-right" className="mx-2" />
          <LocationName locationId={subLocationId} />{' '}
        </span>
      )}
    </div>
  )

  return (
    <main className="dispatch-container shadow">
      <header className="bg-white flex justify-between p-3">
        <h2 className="font-bold mb-0 my-auto px-2 text-base">{uniqueId}</h2>
      </header>

      <div className="flex flex-wrap">
        <RibbonField name="Location" value={dispatchLocation} />

        <RibbonField name="Reporting Party" value={reportingParty} />

        <RibbonField name="Reporting Time" value={<DateTime timestamp={reportingTime} />} />

        {reportingPhone && (
          <RibbonField
            name="Phone"
            value={<a href={`tel:${reportingPhone}`}>{reportingPhone}</a>}
          />
        )}
      </div>

      {Array.isArray(activityTypeIds) && (
        <div className="bg-dark-900">
          <ActivityTypeNames ids={activityTypeIds} />
        </div>
      )}

      <DispatchEntry>{reportingPartySummary}</DispatchEntry>

      <section>
        <DispatchSectionHeader>Action Assignment & Handling</DispatchSectionHeader>

        <DispatchEntry>
          Action requested with <PriorityBadge priority={priority} withSimpleText />
        </DispatchEntry>

        {actionNeededNotes && (
          <DispatchEntry>
            <strong className="font-bold">Action Instruction/Notes:</strong>
            <p className="p-2 mb-0">{actionNeededNotes}</p>
          </DispatchEntry>
        )}

        {assignmentToUserId && (
          <DispatchEntry>
            <strong className="font-bold">Assigned to</strong>{' '}
            <span>
              <UserName userId={assignmentToUserId} /> <strong className="font-bold">By</strong>{' '}
            </span>
            {assignmentByUserId && (
              <span>
                <UserName userId={assignmentByUserId} /> <strong className="font-bold">On</strong>{' '}
              </span>
            )}
            {assignmentTime && <DateTime timestamp={assignmentTime} />}
          </DispatchEntry>
        )}

        {acknowledged && (
          <DispatchEntry>
            <strong className="font-bold">Acknowledged by</strong>{' '}
            <span>
              <UserName userId={acknowledgedUserId} /> <strong className="font-bold">On</strong>{' '}
            </span>
            <DateTime timestamp={acknowledgedTime} />
          </DispatchEntry>
        )}

        {actionNeededArrivedTime && (
          <DispatchEntry>
            <strong className="font-bold">Arrived at</strong>{' '}
            <DateTime timestamp={actionNeededArrivedTime} />
          </DispatchEntry>
        )}

        {actionNeededClearedTime && (
          <DispatchEntry>
            <strong className="font-bold">Cleared at</strong>{' '}
            <DateTime timestamp={actionNeededClearedTime} />{' '}
            {actionNeededClearedUserId && (
              <>
                <strong className="font-bold">By </strong>{' '}
                <UserName userId={actionNeededClearedUserId} />
              </>
            )}
          </DispatchEntry>
        )}
      </section>

      {Boolean(actionNeededClearedSummary) && (
        <section>
          <DispatchSectionHeader>Disposition Summary</DispatchSectionHeader>

          <DispatchEntry>{actionNeededClearedSummary}</DispatchEntry>

          {Boolean(actionNeededClearedRecordIds?.length) && (
            <DispatchEntry>
              <RecordThumbsContainer recordIds={actionNeededClearedRecordIds} />
            </DispatchEntry>
          )}
        </section>
      )}

      <Files fileUrls={files} />
    </main>
  )
}

export default Dispatch

import React from 'react'
import PropTypes from 'prop-types'

const MailRow = ({ children }) => {
  return <tr className="border-b border-gray-500 mail-entry">{children}</tr>
}

MailRow.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MailRow

import React from 'react'
import PropTypes from 'prop-types'

const DispatchSectionHeader = ({ children }) => {
  return (
    <div className="bg-dark-800 px-4 py-2 text-center">
      <h3 className="capitalize mb-0 text-base text-base-100">{children}</h3>
    </div>
  )
}

DispatchSectionHeader.propTypes = {
  children: PropTypes.string,
}

DispatchSectionHeader.defaultProps = {
  children: '',
}

export default DispatchSectionHeader

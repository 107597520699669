import React from 'react'
import PropTypes from 'prop-types'

import useIsMobileScreen from 'modules/Layout/shared/useIsMobileScreen'

import Img from 'components/ImageLoader'
import LocationAddress from 'modules/Locations/shared/LocationAddress'
import LocationName from 'modules/Locations/shared/LocationName'

import placeholderImg from '../../../images/missing_location_300x300.png'
import { getImageSmallUrl } from 'modules/Files/utils/imageSizeUrls'

import './LocationCard.scss'

const LocationCard = ({ location }) => {
  if (!location) return null

  const isMobile = useIsMobileScreen()

  const { id, primaryImageUrl } = location

  const imgSrc = primaryImageUrl ? getImageSmallUrl(primaryImageUrl) : placeholderImg

  if (isMobile)
    return (
      <div className="bg-light-100 flex flex-grow items-center justify-between w-full">
        <div className="py-1 px-2 text-left">
          <h3 className="font-bold mb-1 text-base">
            <LocationName locationId={id} />
          </h3>
        </div>
        <div>
          <Img src={imgSrc} alt="" className="object-cover w-16 h-16" />
        </div>
      </div>
    )

  return (
    <div className="bg-light-100 flex flex-column location-card">
      <Img src={imgSrc} alt="" className="h-40 object-cover w-100" />

      <div className="p-3 flex flex-column flex-grow">
        <h3 className="font-bold mb-1 text-base">
          <LocationName locationId={id} />
        </h3>

        <LocationAddress locationId={id} />
      </div>
    </div>
  )
}

LocationCard.propTypes = {
  location: PropTypes.object.isRequired,
}

export default LocationCard

import { combineReducers } from 'redux'

import activityTypes from './ducks/activityTypes'
import auth from './ducks/auth'
import locations from './ducks/locations'

export default combineReducers({
  activityTypes,
  auth,
  locations,
})

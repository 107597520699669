import React from 'react'
import PropTypes from 'prop-types'

import Files from 'modules/Files/shared/Files'
import RecordThumbsContainer from 'modules/Records/shared/RecordThumbsContainer'

const EntriesEquipmentDLS = ({ reportEntry }) => {
  const fields = reportEntry.fields || {}

  return (
    <div className="reportentry-container">
      {fields.description && (
        <>
          <div className="mt-2">
            <strong>Name & Description of Equipment</strong>
          </div>

          <div className="with-whitespace">{fields.description}</div>
        </>
      )}

      {fields.dls && (
        <div>
          <strong>Equipment Status</strong> {fields.dls.toUpperCase()}
        </div>
      )}

      {fields.details && (
        <>
          <div className="mt-2">
            <strong>Details</strong>
          </div>

          <div className="with-whitespace">{fields.details}</div>
        </>
      )}

      {fields.other && (
        <>
          <div className="mt-2">
            <strong>Other Notes/Details</strong>
          </div>

          <div className="with-whitespace">{fields.other}</div>
        </>
      )}

      {fields.recordIds && (
        <div>
          <div className="bg-dark-500 font-bold px-4 py-2 text-right text-base-200">
            Linked Records
          </div>
          <RecordThumbsContainer recordIds={fields.recordIds} />
        </div>
      )}

      <Files fileUrls={fields.files} />
    </div>
  )
}

EntriesEquipmentDLS.propTypes = {
  reportEntry: PropTypes.object.isRequired,
}
EntriesEquipmentDLS.defaultProps = {}

export default EntriesEquipmentDLS

import React, { useState } from 'react'
import { Route, Switch } from 'react-router-dom'

import FAIcon from '@therms/web-common/components/Icon/FAIcon'
import NavMobileMenu from './NavMobileMenu'

import { ROUTES_BOLOS_ROOT } from 'modules/BOLOs/shared/routePaths'
import { ROUTES_DASHBOARD_ROOT } from 'modules/Dashboard/shared/routePaths'
import { ROUTES_DISPATCH_ROOT } from 'modules/Dispatch/shared/routePaths'
import { ROUTES_REPORTS_ROOT } from 'modules/Reports/shared/routePaths'
import { ROUTES_LOCATIONS_ROOT } from 'modules/Locations/shared/routePaths'
import { ROUTES_TASKS_ROOT } from 'modules/Tasks/shared/routePaths'
import { ROUTES_MAIL_ROOT } from 'modules/Mail/shared/routePaths'
import { ROUTES_SETTINGS_ROOT } from 'modules/Settings/shared/routePaths'
import { ROUTES_RECORDS_SINGLE } from 'modules/Records/shared/routePaths'

const NavMobileMenuToggler = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div>
      {isOpen && <NavMobileMenu setIsOpen={setIsOpen} />}

      <button
        className="bg-white flex h-full items-center justify-center p-2 w-16"
        onClick={() => setIsOpen(!isOpen)}
      >
        <Switch>
          <Route path={ROUTES_DASHBOARD_ROOT} render={() => <FAIcon name="columns" lg />} />
          <Route path={ROUTES_BOLOS_ROOT} render={() => <FAIcon name="exclamation-circle" lg />} />
          <Route path={ROUTES_DISPATCH_ROOT} render={() => <FAIcon name="phone-square" lg />} />
          <Route path={ROUTES_RECORDS_SINGLE} render={() => <FAIcon name="archive" lg />} />
          <Route path={ROUTES_REPORTS_ROOT} render={() => <FAIcon name="file-alt" lg />} />
          <Route path={ROUTES_TASKS_ROOT} render={() => <FAIcon name="tasks" lg />} />
          <Route path={ROUTES_MAIL_ROOT} render={() => <FAIcon name="envelope" lg />} />
          <Route path={ROUTES_SETTINGS_ROOT} render={() => <FAIcon name="cog" lg />} />
          <Route path={ROUTES_LOCATIONS_ROOT} render={() => <FAIcon name="building" lg />} />
        </Switch>
      </button>
    </div>
  )
}

export default NavMobileMenuToggler

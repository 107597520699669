import React from 'react'
import { Route, Switch } from 'react-router-dom'

import DispatchBreadcrumbs from 'modules/Dispatch/shared/Breadcrumbs'
import MailBreadcrumbs from 'modules/Mail/shared/Breadcrumbs'
import ReportsBreadcrumbs from 'modules/Reports/shared/Breadcrumbs'
import RecordBreadcrumbs from 'modules/Records/shared/Breadcrumbs.jsx'

import { ROUTES_BOLOS_ROOT } from 'modules/BOLOs/shared/routePaths'
import { ROUTES_DASHBOARD_ROOT } from 'modules/Dashboard/shared/routePaths'
import { ROUTES_DISPATCH_ROOT } from 'modules/Dispatch/shared/routePaths'
import { ROUTES_LOCATIONS_ROOT } from 'modules/Locations/shared/routePaths'
import { ROUTES_MAIL_ROOT } from 'modules/Mail/shared/routePaths'
import { ROUTES_REPORTS_ROOT } from 'modules/Reports/shared/routePaths'
import { ROUTES_SETTINGS_ROOT } from 'modules/Settings/shared/routePaths'
import { ROUTES_TASKS_ROOT } from 'modules/Tasks/shared/routePaths'
import { ROUTES_RECORDS_SINGLE } from 'modules/Records/shared/routePaths'

const Breadcrumbs = () => {
  return (
    <Switch>
      <Route path={ROUTES_BOLOS_ROOT} render={() => <span>BOLOS</span>} />
      <Route path={ROUTES_DASHBOARD_ROOT} render={() => <span>DASHBOARD</span>} />
      <Route path={ROUTES_DISPATCH_ROOT} component={DispatchBreadcrumbs} />
      <Route path={ROUTES_LOCATIONS_ROOT} render={() => <span>LOCATIONS</span>} />
      <Route path={ROUTES_MAIL_ROOT} component={MailBreadcrumbs} />
      <Route path={ROUTES_RECORDS_SINGLE} render={RecordBreadcrumbs} />
      <Route path={ROUTES_REPORTS_ROOT} component={ReportsBreadcrumbs} />
      <Route path={ROUTES_SETTINGS_ROOT} render={() => <span>SETTINGS</span>} />
      <Route path={ROUTES_TASKS_ROOT} render={() => <span>TASKS</span>} />
    </Switch>
  )
}

export default Breadcrumbs

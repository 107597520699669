import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'

import Dashboard from './screens/Dashboard'

import { ROUTES_DASHBOARD_ROOT } from './shared/routePaths'

const DashboardRoutes = () => (
  <Switch>
    <Route exact path={ROUTES_DASHBOARD_ROOT} component={Dashboard} />
  </Switch>
)

export default withRouter(DashboardRoutes)

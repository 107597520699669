import React, { useState } from 'react'
import classnames from 'classnames'

import FAIcon from '@therms/web-common/components/Icon/FAIcon'
import { handleLogout } from 'services/auth'

const Logout = () => {
  const [loggingOut, setLoggingOut] = useState(false)
  const [failed, setFailed] = useState(false)

  const onLogoutClick = () => {
    setLoggingOut(true)
    try {
      handleLogout()
    } catch {
      setLoggingOut(false)
      setFailed(true)
    }
  }

  let message
  if (loggingOut) {
    message = 'Logging Out'
  } else if (failed) {
    message = 'Logout Failed'
  } else {
    message = 'Logout'
  }

  return (
    <button
      className={classnames({ 'text-danger': failed }, 'flex items-center p-2 pl-4 tab w-full')}
      onClick={onLogoutClick}
    >
      <div className="flex justify-center w-8">
        <FAIcon name="sign-out-alt" className="rotate-180" lg />
      </div>
      <span className="font-bold ml-3">{message}</span>
      {loggingOut && <span className="loading" />}
    </button>
  )
}

Logout.propTypes = {}

export default Logout

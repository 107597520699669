import React from 'react'
import PropTypes from 'prop-types'

import getSeverityBgClass from 'utils/Severity/getSeverityBgClass'
import Badge from './Badge'

const SeverityBlockBadge = ({ children, severity }) => {
  return <Badge className={getSeverityBgClass(severity)}>{children}</Badge>
}

SeverityBlockBadge.propTypes = {
  children: PropTypes.string,
  severity: PropTypes.number,
}

SeverityBlockBadge.defaultProps = {
  children: '',
  severity: 0,
}

export default SeverityBlockBadge

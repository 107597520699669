import React from 'react'
import PropTypes from 'prop-types'

import getPriorityBgClass from '@therms/web-common/components/Priority/getPriorityBgClass'
import getPrioritySimpleText from 'utils/Priority/getPrioritySimpleText'
import Badge from './Badge'

const PriorityBlockBadge = ({ children, priority, withSimpleText }) => {
  if (withSimpleText) {
    return <Badge className={getPriorityBgClass(priority)}>{getPrioritySimpleText(priority)}</Badge>
  }

  return <Badge className={getPriorityBgClass(priority)}>{children}</Badge>
}

PriorityBlockBadge.propTypes = {
  children: PropTypes.string,
  priority: PropTypes.number,
  withSimpleText: PropTypes.bool,
}

PriorityBlockBadge.defaultProps = {
  children: '',
  priority: 0,
  withSimpleText: false,
}

export default PriorityBlockBadge

import React from 'react'
import { useSelector } from 'react-redux'
import useSWR from 'swr'

import DashboardItemHeader from 'modules/Dashboard/shared/DashboardItemHeader'
import EmptySectionSign from 'components/EmptySectionSign'
import BoloThumb from 'modules/BOLOs/components/BoloThumb'
import FailedToLoadResourceMsg from 'components/FailedToLoadResourceMsg'
import Loading from '@therms/web-common/components/Loading'
import pluralCheck from 'utils/pluralCheck'
import { selectCurrentLocationId } from 'state/ducks/locations'

import { makeBolosRecentRequestRoute, ROUTES_BOLOS_ROOT } from '../../routePaths'

const DashboardItemBolos = () => {
  const currentLocationId = useSelector(selectCurrentLocationId)
  const { data: boloRecords, error } = useSWR(makeBolosRecentRequestRoute(currentLocationId))

  if (error?.response?.status === 403) {
    return (
      <div className="bg-light-400 flex flex-grow p-3 shadow">
        <EmptySectionSign forbidden iconName="exclamation-circle" name="recent BOLOs" />
      </div>
    )
  }

  if (error) return <FailedToLoadResourceMsg />

  if (boloRecords === undefined)
    return (
      <section className="bg-light-400 flex flex-grow p-3 shadow">
        <Loading />
      </section>
    )

  if (!boloRecords.length)
    return (
      <section className="bg-light-400 flex flex-grow p-3 shadow">
        <EmptySectionSign iconName="exclamation-circle" name="recent BOLOs" />
      </section>
    )

  const headerSubTitle = `last 7 days: ${boloRecords.length} ${pluralCheck(
    boloRecords.length,
    'BOLO',
    'BOLOs',
  )}`

  return (
    <section className="flex flex-column flex-grow shadow">
      <DashboardItemHeader linkPath={ROUTES_BOLOS_ROOT} subTitle={headerSubTitle} title="BOLOs" />

      <div className="bg-light-400 flex-grow overflow-auto">
        {boloRecords.map((boloRecord) => (
          <div key={boloRecord.id}>
            <BoloThumb locationBolo={boloRecord} />
          </div>
        ))}
      </div>
    </section>
  )
}

export default DashboardItemBolos

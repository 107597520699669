export default function(priority = 0) {
  switch (+priority) {
    case 4:
      return 'High Priority'
    case 3:
      return 'Medium Priority'
    case 2:
      return 'Low Priority'
    case 1:
      return 'Information'
    case 0:
      return 'No Priority'
    default:
      return ''
  }
}

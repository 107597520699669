import React from 'react'
import PropTypes from 'prop-types'

import FAIcon from '@therms/web-common/components/Icon/FAIcon'

const Rating = ({ label, rating }) => {
  function getRating() {
    switch (+rating) {
      case 1:
        return (
          <span className="text-success">
            <FAIcon name="plus-square" /> Satisfactory
          </span>
        )
      case 0:
        return <span className="text-warning">Needs Improvement</span>
      case -1:
        return (
          <span className="text-danger">
            <FAIcon name="minus-square" /> Unsatisfactory
          </span>
        )
      default:
        return <span className="text-muted">n/a</span>
    }
  }

  return (
    <div>
      {label} {getRating()}
    </div>
  )
}

Rating.propTypes = {
  label: PropTypes.string.isRequired,
  rating: PropTypes.string.isRequired,
}
Rating.defaultProps = {}

export default Rating

import React from 'react'
import PropTypes from 'prop-types'

const RenderDisplayText = ({ options, formValue }) => {
  if (!formValue) return null

  return (
    <>
      {options.displayLabel && (
      <div className="font-weight-bold">
        {options.displayLabel}
      </div>
)}

      <span>{formValue}</span>
    </>
  )
}

RenderDisplayText.propTypes = {
  formValue: PropTypes.any,
  options: PropTypes.object.isRequired,
}
RenderDisplayText.defaultProps = {
  formValue: undefined,
}

export default RenderDisplayText

import React from 'react'
import PropTypes from 'prop-types'
import useSWR from 'swr'

import { makeReportEntriesRequestRoute, makeReportRequestRoute } from '../shared/routePaths'

import FailedToLoadResourceMsg from 'components/FailedToLoadResourceMsg'
import Loading from '@therms/web-common/components/Loading'
import ReportEntry from './ReportEntry'

const ReportLog = ({ reportId }) => {
  const { data: reportEntries, error: entriesError } = useSWR(
    makeReportEntriesRequestRoute(reportId),
  )
  const { data: report, error: reportError } = useSWR(makeReportRequestRoute(reportId))

  if (entriesError || reportError) return <FailedToLoadResourceMsg />

  if (!reportEntries || !report) return <Loading />

  return (
    <div className="report-entry-log-container">
      {reportEntries.map(reportEntry => (
        <div className="mt-3" key={reportEntry.id}>
          <ReportEntry report={report} reportEntry={reportEntry} />
        </div>
      ))}
    </div>
  )
}

ReportLog.propTypes = {
  reportId: PropTypes.string.isRequired,
}

export default ReportLog

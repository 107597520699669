import React from 'react'
import PropTypes from 'prop-types'

import TaskActionOutline from 'modules/Tasks/shared/TaskActionOutline'

const EntriesTask = ({ reportEntry }) => {
  const fields = reportEntry.fields || {}

  return (
    <div className="reportentry-container">
      <TaskActionOutline taskExecutionId={fields.taskExecutionId} taskId={fields.taskId} />
    </div>
  )
}

EntriesTask.propTypes = {
  reportEntry: PropTypes.object.isRequired,
}
EntriesTask.defaultProps = {}

export default EntriesTask

import React, { useState, useEffect } from 'react'

import { useSelector } from 'react-redux'
import { selectCurrentLocationId } from 'state/ducks/locations'
import useDebounce from 'hooks/useDebounce'
import useLocalStorage from 'hooks/useLocalStorage'

import EmptySectionSign from 'components/EmptySectionSign'
import FailedToLoadResourceMsg from 'components/FailedToLoadResourceMsg'
import Loading from '@therms/web-common/components/Loading'
import { API_REPORT_SEARCH } from '../shared/routePaths'
import PageNavigator from 'components/PageNavigator'
import ReportsSearch from '../components/ReportsSearch'
import ReportSummary from '../components/ReportSummary'

import portalApi from 'services/portalApi'

const Reports = () => {
  const [currentPage, setCurrentPage] = useState(0)
  const [hits, setHits] = useState(undefined)
  const [searchError, setSearchError] = useState(false)
  const [searchLoading, setSearchLoading] = useState(false)
  const [searchResults, setSearchResults] = useState(undefined)
  const [searchText, setSearchText] = useLocalStorage('reports-search-text', '')
  const [selectedActivityTypeIds, setSelectedActivityTypeIds] = useLocalStorage(
    'reports-selected-activitytypeids',
    [],
  )

  const currentLocationId = useSelector(selectCurrentLocationId)
  const pageLimit = 10

  const handleSearchReq = async () => {
    setSearchLoading(true)

    try {
      const { data: reportRecords } = await portalApi.post(
        API_REPORT_SEARCH,
        {
          activityTypeIds: selectedActivityTypeIds,
          limit: pageLimit,
          locationId: currentLocationId,
          skip: currentPage * pageLimit,
          text: searchText,
        },
      )

      setSearchResults(reportRecords.results)
      if (hits !== reportRecords.hits) setHits(reportRecords.hits)
    } catch (error) {
      setSearchError(true)
    } finally {
      setSearchLoading(false)
    }
  }

  const debouncedSearchTerm = useDebounce(searchText, 500)

  useEffect(() => {
    handleSearchReq()
  }, [
    currentLocationId,
    currentPage,
    debouncedSearchTerm,
    pageLimit,
    searchText,
    selectedActivityTypeIds,
  ])

  useEffect(() => {
    setCurrentPage(0)
  }, [debouncedSearchTerm, selectedActivityTypeIds])

  if (searchError) return <FailedToLoadResourceMsg />

  if (searchLoading)
    return (
      <div className="relative h-full">
        <div className="bg-dark-900 flex flex-wrap justify-between shadow sticky top-0 z-index-sticky">
          <ReportsSearch
            activityTypeValue={selectedActivityTypeIds}
            onActivityTypeChange={({ target }) => setSelectedActivityTypeIds(target.value)}
            onTextChange={setSearchText}
            textValue={searchText}
          />

          <PageNavigator
            currentPage={currentPage}
            limit={pageLimit}
            setCurrentPage={setCurrentPage}
            totalAmount={hits}
          />
        </div>

        <Loading />
      </div>
    )

  if (!searchResults?.length)
    return (
      <div className="relative h-full">
        <div className="bg-dark-900 flex flex-wrap justify-between shadow sticky top-0 z-index-sticky">
          <ReportsSearch
            activityTypeValue={selectedActivityTypeIds}
            onActivityTypeChange={({ target }) => setSelectedActivityTypeIds(target.value)}
            onTextChange={setSearchText}
            textValue={searchText}
          />

          <PageNavigator
            currentPage={currentPage}
            limit={pageLimit}
            setCurrentPage={setCurrentPage}
            totalAmount={hits}
          />
        </div>

        <EmptySectionSign iconName="file-alt" name="Reports" />
      </div>
    )

  return (
    <div className="relative">
      <div className="bg-dark-900 flex flex-wrap justify-between shadow sticky top-0 z-index-sticky">
        <ReportsSearch
          activityTypeValue={selectedActivityTypeIds}
          onActivityTypeChange={({ target }) => setSelectedActivityTypeIds(target.value)}
          onTextChange={setSearchText}
          textValue={searchText}
        />

        <PageNavigator
          currentPage={currentPage}
          limit={pageLimit}
          setCurrentPage={setCurrentPage}
          totalAmount={hits}
        />
      </div>

      {searchResults.map(report => (
        <div className="list-element-wrapper" key={report.id}>
          <ReportSummary reportId={report.id} />
        </div>
      ))}
    </div>
  )
}

export default Reports

import React from 'react'
import PropTypes from 'prop-types'

import DateTime from '@therms/web-common/components/DateTime'
import FAIcon from '@therms/web-common/components/Icon/FAIcon'
import Files from 'modules/Files/shared/Files'
import RecordThumbsContainer from 'modules/Records/shared/RecordThumbsContainer'

const EntriesOnDutyInjury = ({ reportEntry }) => {
  const fields = reportEntry.fields || {}

  return (
    <div className="reportentry-container">
      <div className="mt-2">
        <strong>Date & Time of Injury</strong> <DateTime timestamp={fields.timeOfInjury} />
      </div>

      {fields.locationOfInjury && (
        <>
          <div className="mt-2">
            <strong>Exact Location Where Injury Occurred</strong>
          </div>

          <div className="with-whitespace">{fields.locationOfInjury}</div>
        </>
      )}

      {fields.injuredBodyParts && (
        <>
          <div className="mt-2">
            <strong>What are the Injured Body Parts</strong>
          </div>

          <div className="with-whitespace">{fields.injuredBodyParts}</div>
        </>
      )}

      {fields.priorInjury && (
        <div className="text-secondary">
          <FAIcon name="check-square" />{' '}
          <strong>Injured Body Parts Have Been Injured Before</strong>
        </div>
      )}

      {fields.events && (
        <>
          <div className="mt-2">
            <strong>Step by Step Events or Activity Leading Up to the Injury</strong>
          </div>

          <div className="with-whitespace">{fields.events}</div>
        </>
      )}

      {fields.witnessNames && (
        <>
          <div className="mt-2">
            <strong>Name of Witnesses</strong>
          </div>

          <div className="with-whitespace">{fields.witnessNames}</div>
        </>
      )}

      {fields.avoidance && (
        <>
          <div className="mt-2">
            <strong>What Could Have Been Done to Avoid the Injury</strong>
          </div>

          <div className="with-whitespace">{fields.avoidance}</div>
        </>
      )}

      <div className="mt-2">
        <div className="text-secondary">
          <FAIcon name={fields.medicalDispatched ? 'check-square' : 'times'} />{' '}
          <strong>Medical Dispatched at the Time of Injury</strong>
        </div>

        <div className="text-secondary">
          <FAIcon name={fields.medicalLater ? 'check-square' : 'times'} />{' '}
          <strong>Medical Attention Required at a Later Date</strong>
        </div>
      </div>

      {fields.other && (
        <>
          <div className="mt-2">
            <strong>Other Notes/Details</strong>
          </div>

          <div className="with-whitespace">{fields.other}</div>
        </>
      )}

      {fields.recordIds && (
        <div>
          <div className="bg-dark-500 font-bold px-4 py-2 text-right text-base-200">
            Linked Records
          </div>
          <RecordThumbsContainer recordIds={fields.recordIds} />
        </div>
      )}

      <Files fileUrls={fields.files} />      
    </div>
  )
}

EntriesOnDutyInjury.propTypes = {
  reportEntry: PropTypes.object.isRequired,
  // reportEntryType: PropTypes.object.isRequired,
}
EntriesOnDutyInjury.defaultProps = {}

export default EntriesOnDutyInjury

import React from 'react'
import PropTypes from 'prop-types'

const VehicleFullFieldList = ({ fields }) => {
  const { color, make, model, plate, state, type, vin, year } = fields

  return (
    <ul className="flex flex-column flex-grow mb-0 text-base-900">
      <li className="flex flex-grow items-center list-entry-light px-3 py-1 rounded-t">
        <span>
          <strong className="font-strong">Model:</strong> {model || 'N/A'}
        </span>
      </li>

      <li className="flex flex-grow items-center list-entry-light px-3 py-1">
        <span>
          <strong className="font-strong">Make:</strong> {make || 'N/A'}
        </span>
      </li>

      <li className="flex flex-grow items-center list-entry-light px-3 py-1">
        <span>
          <strong className="font-strong">Color:</strong> {color || 'N/A'}
        </span>
      </li>

      <li className="flex flex-grow items-center list-entry-light px-3 py-1">
        <span>
          <strong className="font-strong">Type:</strong> {type || 'N/A'}
        </span>
      </li>

      <li className="flex flex-grow items-center list-entry-light px-3 py-1">
        <span>
          <strong className="font-strong">Plate:</strong> {plate || 'N/A'}
        </span>
      </li>

      <li className="flex flex-grow items-center list-entry-light px-3 py-1">
        <span>
          <strong className="font-strong">State:</strong> {state || 'N/A'}
        </span>
      </li>

      <li className="flex flex-grow items-center list-entry-light px-3 py-1">
        <span>
          <strong className="font-strong">Year:</strong> {year || 'N/A'}
        </span>
      </li>

      <li className="flex flex-grow items-center list-entry-light px-3 py-1 rounded-b">
        <span>
          <strong className="font-strong">Vin:</strong> {vin || 'N/A'}
        </span>
      </li>
    </ul>
  )
}

VehicleFullFieldList.propTypes = {
  fields: PropTypes.shape({
    color: PropTypes.string,
    make: PropTypes.string,
    model: PropTypes.string,
    plate: PropTypes.string,
    state: PropTypes.string,
    type: PropTypes.string,
    vin: PropTypes.string,
    year: PropTypes.number,
  }),
}

VehicleFullFieldList.defaultProps = {
  fields: PropTypes.shape({
    color: 'N/A',
    make: 'N/A',
    model: 'N/A',
    plate: 'N/A',
    state: 'N/A',
    type: 'N/A',
    vin: 'N/A',
    year: undefined,
  }),
}

export default VehicleFullFieldList

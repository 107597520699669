import React from 'react'
import PropTypes from 'prop-types'

import RecordThumb from '../components/RecordThumb'

const RecordThumbsContainer = ({ recordIds }) => {
  if (!Array.isArray(recordIds) || !recordIds.length) return null

  return (
    <div className="flex flex-wrap">
      {recordIds.map(id => (
        <div className="m-1" key={id}>
          <RecordThumb recordId={id} />
        </div>
      ))}
    </div>
  )
}

RecordThumbsContainer.propTypes = {
  recordIds: PropTypes.arrayOf(PropTypes.string),
}

RecordThumbsContainer.defaultProps = {
  recordIds: [],
}

export default RecordThumbsContainer

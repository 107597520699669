import React from 'react'
// import PropTypes from 'prop-types'

import MailHeader from '../components/MailHeader'
import MailNavigation from '../components/MailNavigation.jsx'
import MailRow from '../components/MailRow'

const Inbox = () => {
  const mails = []

  // placeholder
  for (let step = 0; step < 20; step++) {
    mails.push({
      from: 'Patrolman, Bob',
      id: step,
      subject:
        'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nihil vitae, similique odio in qui provident animi rem quae maxime ut labore eius? Tenetur',
      time: '2019-11-2110:17',
    })
  }

  return (
    <div>
      <div className="bg-light-100 p-4">
        <table className="table-fixed w-100">
          <MailHeader>
            <th className="w-3/12 md:w-3/12 lg:w-2/12">Time</th>
            <th className="w-4/12 md:w-3/12 lg:w-2/12">From</th>
            <th className="w-5/12 md:w-6/12 lg:w-8/12">Subject</th>
          </MailHeader>
          <tbody>
            {mails.map(mail => (
              <MailRow key={mail.id}>
                <td className="py-3 truncate">{mail.time}</td>
                <td className="font-semibold py-3 truncate">{mail.from}</td>
                <td className="font-light py-3 truncate">{mail.subject}</td>
              </MailRow>
            ))}
          </tbody>
        </table>
      </div>
      <MailNavigation />
    </div>
  )
}

Inbox.propTypes = {}

export default Inbox

import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import FAIcon from '@therms/web-common/components/Icon/FAIcon'
import ActivityTypeNames from '../../ActivityTypes/shared/ActivityTypeNames'
import ReportItemCard from './ReportItemCard'
import ReportTypeIcon from './ReportType/ReportTypeIcon'
import ReportTypeName from './ReportType/ReportTypeName'
import ReportEntriesNumber from './ReportEntriesNumber'
import UserNames from 'modules/User/shared/UserNames'

import { getFormattedReportDate } from '@therms/models/models/report/utils'
import pluralCheck from 'utils/pluralCheck'

import { makeReportRecordRoute } from '../shared/routePaths'

import './ReportThumb.scss'

const ReportThumb = ({ reportRecord }) => {
  const {
    activityTypeIds,
    contributorUserIds,
    id,
    reportEntryCount,
    reportTypeId,
    uniqueId,
  } = reportRecord

  return (
    <Link
      to={makeReportRecordRoute(id)}
      className="bg-light-100 block mb-2 non-styled-link parent-link-highlight-background report-thumb"
    >
      <div className="bg-dark-900 flex flex-wrap">
        <header className="bg-dark-800 flex flex-grow-max justify-between">
          <h2 className="font-bold mb-0 my-auto px-3 py-2 text-base-100 text-base">{uniqueId}</h2>
        </header>

        {Array.isArray(activityTypeIds) && (
          <div className="flex-grow">
            <ActivityTypeNames ids={activityTypeIds} />
          </div>
        )}
      </div>

      <div className="flex flex-wrap items-center justify-center p-3">
        <ReportItemCard icon={<FAIcon name="calendar-day" lg />} name="Date">
          {getFormattedReportDate(reportRecord)}
        </ReportItemCard>

        <ReportItemCard icon={<ReportTypeIcon reportTypeId={reportTypeId} lg />} name="Type">
          <ReportTypeName reportTypeId={reportTypeId} />
        </ReportItemCard>

        <ReportItemCard
          icon={<FAIcon name="user" lg />}
          name={pluralCheck(contributorUserIds, 'Contributor', 'Contributors')}
        >
          <UserNames ids={contributorUserIds} />
        </ReportItemCard>

        <ReportEntriesNumber
          itemCard
          reportEntryCount={reportEntryCount}
          reportTypeId={reportTypeId}
        />
      </div>

      <div className="bg-light-400 h-2 child-link-highlight-background" />
    </Link>
  )
}

ReportThumb.propTypes = {
  reportRecord: PropTypes.object.isRequired,
}

export default ReportThumb

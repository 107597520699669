import { useEffect, useRef, useState } from 'react'

import { debounce } from 'lodash-es'

const useIsMobileScreen = () => {
  const mobileCheck = () => window.innerWidth <= 500 && window.innerHeight >= 500

  const isMounted = useRef(true)
  const [isMobile, setIsMobile] = useState(mobileCheck())

  useEffect(() => {
    const handleResize = debounce(() => {
      if (isMounted.current) setIsMobile(mobileCheck())
    }, 100)

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  return isMobile
}

export default useIsMobileScreen

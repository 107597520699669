import React from 'react'
import PropTypes from 'prop-types'

import getPriorityBgClass from '@therms/web-common/components/Priority/getPriorityBgClass'
import getPrioritySimpleText from 'utils/Priority/getPrioritySimpleText'

const PriorityBlockBadge = ({ priority }) => {
  return (
    <div
      className={`${getPriorityBgClass(
        priority,
      )} font-bold flex h-full items-center justify-center px-3 py-1 text-base-100 text-center w-full`}
    >
      {getPrioritySimpleText(priority)}
    </div>
  )
}

PriorityBlockBadge.propTypes = {
  priority: PropTypes.number,
}

PriorityBlockBadge.defaultProps = {
  priority: 0,
}

export default PriorityBlockBadge

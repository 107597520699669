import React from 'react'
import PropTypes from 'prop-types'

import DateTime from '@therms/web-common/components/DateTime'

const PersonFullFieldList = ({ fields }) => {
  const { alias, dob, eyes, gender, hair, height, name, race, weight } = fields

  return (
    <ul className="flex flex-column flex-grow mb-0 text-base-900">
      <li className="flex flex-grow items-center list-entry-light px-3 py-1 rounded-t">
        <span>
          <strong className="font-strong">Name:</strong> {name}
        </span>
      </li>

      {alias && (
        <li className="flex flex-grow items-center list-entry-light px-3 py-1">
          <span>
            <strong className="font-strong">Alias:</strong> {alias}
          </span>
        </li>
      )}

      <li className="flex flex-grow items-center list-entry-light px-3 py-1">
        <span>
          <strong className="font-strong">Gender:</strong> {gender}
        </span>
      </li>

      <li className="flex flex-grow items-center list-entry-light px-3 py-1">
        {dob && (
          <span>
            <strong className="font-strong">Birth:</strong>{' '}
            <DateTime timestamp={dob} time={false} />
          </span>
        )}

        {!dob && (
          <span>
            <strong className="font-strong">Birth:</strong> 'N/A'
          </span>
        )}
      </li>

      <li className="flex flex-grow items-center list-entry-light px-3 py-1">
        <span>
          <strong className="font-strong">Race:</strong> {race}
        </span>
      </li>

      <li className="flex flex-grow items-center list-entry-light px-3 py-1">
        <span>
          <strong className="font-strong">Hair:</strong> {hair}
        </span>
      </li>

      <li className="flex flex-grow items-center list-entry-light px-3 py-1">
        <span>
          <strong className="font-strong">Eyes:</strong> {eyes}
        </span>
      </li>

      <li className="flex flex-grow items-center list-entry-light px-3 py-1">
        <span>
          <strong className="font-strong">Gender:</strong> {gender}
        </span>
      </li>

      <li className="flex flex-grow items-center list-entry-light px-3 py-1">
        <span>
          <strong className="font-strong">Height:</strong> {height}
        </span>
      </li>

      <li className="flex flex-grow items-center list-entry-light px-3 py-1 rounded-b">
        <span>
          <strong className="font-strong">Weight:</strong> {weight || 'N/A'}
        </span>
      </li>
    </ul>
  )
}

PersonFullFieldList.propTypes = {
  fields: PropTypes.shape({
    alias: PropTypes.string,
    dob: PropTypes.string,
    eyes: PropTypes.string,
    gender: PropTypes.string,
    hair: PropTypes.string,
    height: PropTypes.string,
    name: PropTypes.string,
    race: PropTypes.string,
    weight: PropTypes.number,
  }),
}

PersonFullFieldList.defaultProps = {
  fields: PropTypes.shape({
    alias: 'N/A',
    dob: undefined,
    eyes: 'N/A',
    gender: 'N/A',
    hair: 'N/A',
    height: 'N/A',
    name: 'N/A',
    race: 'N/A',
    weight: undefined,
  }),
}

export default PersonFullFieldList

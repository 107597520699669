export const ROUTES_BOLOS_ROOT = '/bolos'
export const API_LOCATION_BOLOS = '/location/:locationId/bolo'
export const API_BOLO_COUNT = '/location/:locationId/bolo?count=true'
export const API_BOLO_RECENT = '/location/:locationId/bolo?recent=true'

export const makeBolosRequestRoute = (locationId, { limit = 10, skip = 0 } = {}) => {
  const path = API_LOCATION_BOLOS.replace(':locationId', locationId)
  return `${path}?limit=${limit}&skip=${skip}`
}

export const makeBoloCountRequestRoute = locationId => {
  return API_BOLO_COUNT.replace(':locationId', locationId)
}

export const makeBolosRecentRequestRoute = locationId => {
  return API_BOLO_RECENT.replace(':locationId', locationId)
}
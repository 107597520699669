import React from 'react'
import PropTypes from 'prop-types'
import useSWR from 'swr'

import FAIcon from '@therms/web-common/components/Icon/FAIcon'
import FailedToLoadResourceMsg from 'components/FailedToLoadResourceMsg'
import Loading from '@therms/web-common/components/Loading'
import LocationNames from 'modules/Locations/shared/LocationNames.jsx'
import { makeReportRequestRoute } from '../shared/routePaths'
import pluralCheck from 'utils/pluralCheck'
import ReportItemCard from './ReportItemCard'
import ReportTypeName from './ReportType/ReportTypeName'
import ReportTypeIcon from './ReportType/ReportTypeIcon'
import UserNames from 'modules/User/shared/UserNames'
import ReportEntriesNumber from './ReportEntriesNumber'

import { getFormattedReportDate } from '@therms/models/models/report/utils'

const ReportDetails = ({ reportId }) => {
  const { data: reportRecord, error } = useSWR(makeReportRequestRoute(reportId))

  if (error) return <FailedToLoadResourceMsg />

  if (!reportRecord) return <Loading />

  const { contributorUserIds, locationIds, reportTypeId, reportEntryCount } = reportRecord

  return (
    <div className="bg-light-100 flex flex-wrap items-center justify-center p-3">
      <ReportItemCard icon={<FAIcon name="calendar-day" lg />} name="Date">
        {getFormattedReportDate(reportRecord)}
      </ReportItemCard>

      <ReportItemCard icon={<ReportTypeIcon reportTypeId={reportTypeId} lg />} name="Type">
        <ReportTypeName reportTypeId={reportTypeId} />
      </ReportItemCard>

      <ReportItemCard
        icon={<FAIcon name="user" lg />}
        name={pluralCheck(contributorUserIds, 'Contributor', 'Contributors')}
      >
        <UserNames ids={contributorUserIds} />
      </ReportItemCard>

      <ReportItemCard icon={<FAIcon name="map-marker-alt" lg />} name="Location">
        <LocationNames ids={locationIds} />
      </ReportItemCard>

      <ReportEntriesNumber
        itemCard
        reportEntryCount={reportEntryCount}
        reportTypeId={reportTypeId}
      />
    </div>
  )
}

ReportDetails.propTypes = {
  reportId: PropTypes.string.isRequired,
}

export default ReportDetails

import React from 'react'
import PropTypes from 'prop-types'

import DateTime from '@therms/web-common/components/DateTime'
import FAIcon from '@therms/web-common/components/Icon/FAIcon'
import Files from 'modules/Files/shared/Files'
import RecordThumbsContainer from 'modules/Records/shared/RecordThumbsContainer'

const EntriesVehicleAccident = ({ reportEntry }) => {
  const fields = reportEntry.fields || {}

  return (
    <div className="reportentry-container">
      <div className="mt-2">
        <strong>Date & Time of Accident</strong> <DateTime timestamp={fields.timeOfAccident} />
      </div>

      {fields.locationOfAccident && (
        <>
          <div className="mt-2">
            <strong>Exact Location Where Accident Occurred</strong>
          </div>

          <div className="with-whitespace">{fields.locationOfAccident}</div>
        </>
      )}

      {fields.vehicles && (
        <>
          <div className="mt-2">
            <strong>Vehicles & Drivers Involved</strong>
          </div>

          <div className="with-whitespace">{fields.vehicles}</div>
        </>
      )}

      {fields.injuries && (
        <div className="text-warning">
          <FAIcon name="exclamation-triangle" />{' '}
          <strong>Injuries Sustained as a Result of Accident</strong>
        </div>
      )}

      {fields.inuriesDetails && (
        <>
          <div className="mt-2">
            <strong>Who and How Injuries Occurred</strong>
          </div>

          <div className="with-whitespace">{fields.inuriesDetails}</div>
        </>
      )}

      {fields.summary && (
        <>
          <div className="mt-2">
            <strong>Summary of How Accident Occurred</strong>
          </div>

          <div className="with-whitespace">{fields.summary}</div>
        </>
      )}

      {fields.witnessNames && (
        <>
          <div className="mt-2">
            <strong>Name of Witnesses</strong>
          </div>

          <div className="with-whitespace">{fields.witnessNames}</div>
        </>
      )}

      {fields.avoidance && (
        <>
          <div className="mt-2">
            <strong>What Could Have Been Done to Avoid the Accident</strong>
          </div>

          <div className="with-whitespace">{fields.avoidance}</div>
        </>
      )}

      {fields.policeReport && (
        <>
          <div className="mt-2">
            <strong>Police/Highway Patrol Report Number</strong>
          </div>

          <div className="with-whitespace">{fields.policeReport}</div>
        </>
      )}

      {fields.other && (
        <>
          <div className="mt-2">
            <strong>Other Notes/Details</strong>
          </div>

          <div className="with-whitespace">{fields.other}</div>
        </>
      )}

      {fields.recordIds && (
        <div>
          <div className="bg-dark-500 font-bold px-4 py-2 text-right text-base-200">
            Linked Records
          </div>
          <RecordThumbsContainer recordIds={fields.recordIds} />
        </div>
      )}

      <Files fileUrls={fields.files} />
    </div>
  )
}

EntriesVehicleAccident.propTypes = {
  reportEntry: PropTypes.object.isRequired,
  // reportEntryType: PropTypes.object.isRequired,
}
EntriesVehicleAccident.defaultProps = {}

export default EntriesVehicleAccident

import React from 'react'
import { NavLink, Route, Switch } from 'react-router-dom'

import {
  ROUTES_MAIL_ROOT,
  ROUTES_MAIL_INBOX,
  ROUTES_MAIL_NEW,
  ROUTES_MAIL_SENT,
} from 'modules/Mail/shared/routePaths'

const RouteBreadcrumbs = () => {
  // TODO: Index shouldn't be a link if currently on that page

  return (
    <nav className="breadcrumb-container">
      <ol className="flex mb-0">
        <li className="route-breadcrumb">
          <NavLink to={ROUTES_MAIL_ROOT} className="non-styled-link">
            Mail
          </NavLink>
        </li>

        <Switch>
          <Route
            path={ROUTES_MAIL_INBOX}
            render={() => <li className="route-breadcrumb">Inbox</li>}
          />
          <Route path={ROUTES_MAIL_NEW} render={() => <li className="route-breadcrumb">New</li>} />

          <Route
            path={ROUTES_MAIL_SENT}
            render={() => <li className="route-breadcrumb">Sent</li>}
          />
        </Switch>
      </ol>
    </nav>
  )
}

RouteBreadcrumbs.propTypes = {}
RouteBreadcrumbs.defaultProps = {}

export default RouteBreadcrumbs

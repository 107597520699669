import React from 'react'
import { useParams } from 'react-router-dom'

import ReportDetails from '../components/ReportDetails'
import ReportHeader from '../components/ReportHeader'
import ReportLog from '../components/ReportLog'

const Report = () => {
  const { reportId } = useParams()

  return (
    <main>
      <div className="reportentry-container shadow">
        <ReportHeader reportId={reportId} />

        <ReportDetails reportId={reportId} />
      </div>

      <ReportLog reportId={reportId} />
    </main>
  )
}

export default Report

import React from 'react'
import PropTypes from 'prop-types'

import LocationName from './LocationName'

const LocationNames = ({ ids }) => {
  let names
  if ((ids || []).length) {
    names = ids.map(id => <LocationName locationId={id} key={id} />)
  }

  return <>{names}</>
}

LocationNames.propTypes = {
  ids: PropTypes.array.isRequired,
}

export default LocationNames

import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import SeverityBadge from 'components/SeverityBadge.jsx'

const ActivityTypeName = ({ id, raw }) => {
  const activityTypesById = useSelector(state => state.activityTypes.byId)

  if (raw) return activityTypesById[id].name

  return (
    <SeverityBadge severity={activityTypesById[id].severity}>
      {activityTypesById[id].name}
    </SeverityBadge>
  )
}

ActivityTypeName.propTypes = {
  id: PropTypes.string.isRequired,
  raw: PropTypes.bool,
}

ActivityTypeName.defaultProps = {
  raw: false,
}

export default ActivityTypeName

export default function(severity = 0) {
  switch (+severity) {
    case 3:
      return 'bg-danger'
    case 2:
      return 'bg-warning'
    case 1:
      return 'bg-secondary'
    case 0:
      return 'bg-'
    default:
      return ''
  }
}

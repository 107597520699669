import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ellipsize from 'ellipsize'

import FAIcon from '@therms/web-common/components/Icon/FAIcon'
import MimeTypeFAIcon from '@therms/web-common/components/Icon/MimeTypeFAIcon'

import './FileCard.scss'

const FileCard = ({ contentType, downloading, name, onClick, thumbnailUrl }) => {
  const [delaying, setDelaying] = useState(false)

  useEffect(() => {
    if (delaying) {
      const t = setTimeout(() => {
        setDelaying(false)
      }, 2000)

      return () => clearTimeout(t)
    }

    return undefined
  }, [delaying])

  if (delaying || downloading)
    return (
      <div className="file-card-container bg-secondary-opacity-25">
        <FAIcon className="fa-spin" name="circle-notch" lg />
      </div>
    )

  if (thumbnailUrl)
    return (
      <div
        className="file-card-container bg-secondary-opacity-25"
        onClick={onClick}
      >
        <img
          alt=""
          className="file-card-image-thumbnail"
          onError={() => setDelaying(true)}
          src={thumbnailUrl}
        />
      </div>
    )

  return (
    <div
      className="d-flex file-card-container flex-row bg-secondary-opacity-25 p-3"
      onClick={onClick}
    >
      <MimeTypeFAIcon lg mimeType={contentType} />

      {(!!contentType || !!name) && (
        <div className="ml-3">
          {!!contentType && (
            <p className="mb-0 font-weight-bold font-small">{contentType}</p>
          )}
          {!!name && <p className="mb-0 font-small">{ellipsize(name, 25)}</p>}
        </div>
      )}
    </div>
  )
}

FileCard.propTypes = {
  contentType: PropTypes.string.isRequired,
  downloading: PropTypes.bool,
  name: PropTypes.string,
  onClick: PropTypes.func,
  thumbnailUrl: PropTypes.string,
}

FileCard.defaultProps = {
  downloading: false,
  name: undefined,
  onClick: undefined,
  thumbnailUrl: undefined,
}

export default FileCard

import React from 'react'
import PropTypes from 'prop-types'

import ActivityTypeNames from 'modules/ActivityTypes/shared/ActivityTypeNames'
import FAIcon from '@therms/web-common/components/Icon/FAIcon'
import Files from 'modules/Files/shared/Files'
import RecordThumbsContainer from '../../../../Records/shared/RecordThumbsContainer'

const EntriesArrest = ({ reportEntry }) => {
  const fields = reportEntry.fields || {}

  let methodOfArrest = ''

  switch (fields.methodOfArrest) {
    case 'verbal':
      methodOfArrest = 'Verbal (no physical)'
      break
    case 'limitedPhysical':
      methodOfArrest = 'Limited Physical Contact'
      break
    case 'fullPhysical':
      methodOfArrest = 'Full Physical Contact'
      break
    default:
      methodOfArrest = ''
  }

  return (
    <div className="reportentry-container">
      {fields.activityTypeIds && (
        <div className="p-1">
          <ActivityTypeNames ids={fields.activityTypeIds} badge />
        </div>
      )}

      {fields.personMakingArrest && (
        <div className="list-entry-light p-2">
          <div>
            <strong>Person(s) Making Arrest</strong>
          </div>

          <div>{fields.personMakingArrest}</div>
        </div>
      )}

      {fields.nameOfArrested && (
        <div className="list-entry-light p-2">
          <div>
            <strong>Name of Person Arrested</strong>
          </div>

          <div>{fields.nameOfArrested}</div>
        </div>
      )}

      {fields.locationOfArrest && (
        <div className="list-entry-light p-2">
          <div>
            <strong>Location of Arrest</strong>
          </div>

          <div>{fields.locationOfArrest}</div>
        </div>
      )}

      {fields.methodOfArrest && (
        <div className="list-entry-light p-2">
          <div>
            <strong>Method of Arrest</strong> <span>{methodOfArrest}</span>
          </div>
        </div>
      )}

      <div>
        {fields.restraints && (
          <div className="font-italic list-entry-light p-2">
            <FAIcon name="check-square" /> Restraints Used
          </div>
        )}
        {fields.lawEnforcement && (
          <div className="font-italic list-entry-light p-2">
            <FAIcon name="check-square" /> Law Enforcement Authority Dispatch
          </div>
        )}
        {fields.medical && (
          <div className="font-italic list-entry-light p-2">
            <FAIcon name="check-square" /> Medical Dispatch
          </div>
        )}
        {fields.medicalReason && (
          <div className="list-entry-light p-2">
            <div className="font-weight-bold">Reason for Medical</div>

            <div className="with-whitespace">{fields.medicalReason}</div>
          </div>
        )}
      </div>

      {fields.reason && (
        <div className="list-entry-light p-2">
          <div className="font-weight-bold">Reason for Arrest</div>

          <div className="with-whitespace">{fields.reason}</div>
        </div>
      )}

      {fields.notes && (
        <div className="list-entry-light p-2">
          <div className="font-weight-bold">Notes</div>

          <div className="with-whitespace">{fields.notes}</div>
        </div>
      )}

      {fields.recordIds && (
        <div>
          <div className="bg-dark-500 font-bold px-4 py-2 text-right text-base-200">
            Linked Records
          </div>
          <RecordThumbsContainer recordIds={fields.recordIds} />
        </div>
      )}

      <Files fileUrls={fields.files} />
    </div>
  )
}

EntriesArrest.propTypes = {
  reportEntry: PropTypes.object.isRequired,
}
EntriesArrest.defaultProps = {}

export default EntriesArrest

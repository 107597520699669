import React from 'react'
import { find } from 'lodash-es'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import useSWR from 'swr'

import Img from 'components/ImageLoader'
import FailedToLoadResourceMsg from 'components/FailedToLoadResourceMsg'
import Loading from '@therms/web-common/components/Loading'
import { makeOrganizationRequestRoute } from './routePaths'
import { selectCurrentLocationId } from 'state/ducks/locations'
import Modal from '@therms/web-common/components/Modal'
import AddressBlock from '@therms/web-common/components/Address/AddressBlock'

const OrganizationModal = ({ closeHandler }) => {
  const locationId = useSelector(selectCurrentLocationId)
  const locationsList = useSelector(state => state.locations.list)
  const currentLocation = find(locationsList, location => location.id === locationId)

  const organizationId = currentLocation.organizationId

  const { data: organizationRecord, error } = useSWR(makeOrganizationRequestRoute(organizationId))

  if (error)
    return (
      <Modal closeHandler={closeHandler} title="Organization Information">
        <FailedToLoadResourceMsg />
      </Modal>
    )

  if (!organizationRecord)
    return (
      <Modal closeHandler={closeHandler} title="Organization Information">
        <Loading />
      </Modal>
    )

  const { address, logoUrl, name, phone, website } = organizationRecord

  return (
    <Modal closeHandler={closeHandler} title="Organization Information">
      <div className="flex flex-column items-center text-center">
        <div className="mb-3">
          <Img src={logoUrl} alt="" className="object-contain" height="200" width="200" />
        </div>

        <h3 className="font-bold">{name}</h3>
        <a href={website} className="mb-5">
          {website}
        </a>

        <p className="font-bold mb-1">Address</p>
        <p>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <AddressBlock {...address} />
        </p>

        <div>
          <p className="font-bold mb-1">Contact Number</p>
          <a href={`tel:${phone}`}>{phone}</a>
        </div>
      </div>
    </Modal>
  )
}

OrganizationModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
}

export default OrganizationModal

import axios from 'axios'

// eslint-disable-next-line import/no-cycle
import { handleAuthFailed } from './auth'
import cookies from 'js-cookie'

const axiosConfig = {
  baseURL: process.env.AUTH_SERVER,
  headers: { 'Cache-Control': 'no-cache' },
  withCredentials: true,
}

const authApi = axios.create(axiosConfig)

authApi.interceptors.request.use(
  function(config) {
    // add jwt to req header
    // eslint-disable-next-line no-param-reassign
    config.headers[process.env.JWT_HEADER_KEY] = cookies.get(process.env.AUTH_COOKIE_NAME)
    return config
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error)
  },
)

// Add a response interceptor
authApi.interceptors.response.use(
  response =>
    // Do something with response data
    // console.log('ok', response.status)
    response,
  error => {
    if (error && error.response && error.response.status === 401) handleAuthFailed()

    return Promise.reject(error)
  },
)

export default authApi

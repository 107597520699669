export const ROUTES_TASKS_ROOT = '/tasks'
export const ROUTES_TASKS_SINGLE = '/tasks/:taskId'

export const API_LOCATION_TASKS = '/task?locationId=:locationId'
export const API_LOCATION_TASKS_RECENT = '/task?locationId=:locationId&recent=true'
export const API_SINGLE_TASK = '/task/:taskId'
export const API_TASK_COUNT = '/task?locationId=:locationId&count=true'

export const API_LOCATION_TASK_EXECUTIONS = '/task/:taskId/executions'
export const API_TASK_SINGLE_EXECUTION = '/task/execution/:taskExecutionId'
export const API_TASK_EXECUTIONS_COUNT = '/task/:taskId/executions?count=true'

export const makeSingleTaskRoute = (taskId) => {
  return ROUTES_TASKS_SINGLE.replace(':taskId', taskId)
}

export const makeTasksRequestRoute = (locationId, { limit = 10, skip = 0 } = {}) => {
  const path = API_LOCATION_TASKS.replace(':locationId', locationId)
  return `${path}&limit=${limit}&skip=${skip}`
}

export const makeTasksRecentRequestRoute = (locationId) => {
  return API_LOCATION_TASKS_RECENT.replace(':locationId', locationId)
}

export const makeSingleTaskRequestRoute = (taskId) => {
  return API_SINGLE_TASK.replace(':taskId', taskId)
}

export const makeTaskExecutionsRequestRoute = (taskId, { limit = 10, skip = 0 } = {}) => {
  const path = API_LOCATION_TASK_EXECUTIONS.replace(':taskId', taskId)
  return `${path}?limit=${limit}&skip=${skip}`
}

export const makeTaskExecutionSingleRequestRoute = (taskExecutionId) => {
  return API_TASK_SINGLE_EXECUTION.replace(':taskExecutionId', taskExecutionId)
}

export const makeTaskCountRequestRoute = (locationId) => {
  return API_TASK_COUNT.replace(':locationId', locationId)
}

export const makeTaskExecutionsCountRequestRoute = (taskId) => {
  return API_TASK_EXECUTIONS_COUNT.replace(':taskId', taskId)
}

import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import useSWR from 'swr'

import DashboardItemHeader from 'modules/Dashboard/shared/DashboardItemHeader'
import EmptySectionSign from 'components/EmptySectionSign'
import FailedToLoadResourceMsg from 'components/FailedToLoadResourceMsg'
import Loading from '@therms/web-common/components/Loading'
import pluralCheck from 'utils/pluralCheck'
import { selectCurrentLocationId } from 'state/ducks/locations'
import { sortRecentTasks } from '../../../utils/sortTasks'
import TaskThumb from '../../../components/TaskThumb'

import { makeTasksRecentRequestRoute, ROUTES_TASKS_ROOT } from '../../routePaths'

const TasksDashboardItem = () => {
  const currentLocationId = useSelector(selectCurrentLocationId)
  const { data: taskRecordsRecent, error } = useSWR(makeTasksRecentRequestRoute(currentLocationId))

  const sortedTasks = useMemo(() => sortRecentTasks(taskRecordsRecent), [taskRecordsRecent])

  if (error?.response?.status === 403) {
    return (
      <section className="bg-light-400 flex flex-grow p-3 shadow">
        <EmptySectionSign forbidden iconName="tasks" name="recent Tasks" />
      </section>
    )
  }

  if (error) return <FailedToLoadResourceMsg />

  if (taskRecordsRecent === undefined)
    return (
      <section className="bg-light-400 flex flex-grow p-3 shadow">
        <Loading />
      </section>
    )

  if (!sortedTasks.length)
    return (
      <div className="bg-light-400 flex flex-grow p-3 shadow">
        <EmptySectionSign iconName="tasks" name="recent Tasks" />
      </div>
    )

  const headerSubTitle = `last 7 days: ${sortedTasks.length} ${pluralCheck(
    sortedTasks.length,
    'task',
    'tasks',
  )}`

  return (
    <section className="flex flex-column flex-grow shadow">
      <DashboardItemHeader linkPath={ROUTES_TASKS_ROOT} subTitle={headerSubTitle} title="Tasks" />

      <div className="bg-light-400 flex-grow overflow-auto">
        {sortedTasks.map((taskRecord) => (
          <div key={taskRecord.id}>
            <TaskThumb id={taskRecord.id} />
          </div>
        ))}
      </div>
    </section>
  )
}

export default TasksDashboardItem

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import BottomTabBar from './components/Mobile/BottomTabBar'
import Header from './components/Desktop/Header'
import LeftSideMenu from './components/Desktop/LeftSideMenu'
import Loading from '@therms/web-common/components/Loading'
import Main from './components/Desktop/Main'
import MobileMain from './components/Mobile/MobileMain'
import SiteBreadcrumbs from './components/SiteBreadcrumbs'

import useIsMobileScreen from './shared/useIsMobileScreen'

import { fetchAndSetLocations } from 'state/ducks/locations'

import './index.scss'

const Layout = ({ children }) => {
  const dispatch = useDispatch()
  const isMobile = useIsMobileScreen()

  const locationsFetched = useSelector(state => state.locations.hasFetched)

  useEffect(() => {
    if (!locationsFetched) {
      dispatch(fetchAndSetLocations())
    }
  }, [locationsFetched])

  if (!locationsFetched) return <Loading />

  if (isMobile)
    return (
      <div className="layout-mobile-container">
        <div className="bg-white sticky flex font-bold items-center justify-center p-px text-center tracking-wider shadow top-0">
          <SiteBreadcrumbs />
        </div>

        <MobileMain>{children}</MobileMain>

        <BottomTabBar />
      </div>
    )

  return (
    <div className="layout-desktop-container flex">
      <LeftSideMenu />

      <div className="w-full">
        <Header />

        <Main>{children}</Main>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
Layout.defaultProps = {}

export default Layout

import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import useCurrentLocation from 'modules/Locations/shared/useCurrentLocation'

import LocationCard from 'modules/Locations/shared/LocationCard'
import LocationSwapper from 'modules/Locations/shared/modules/Layout/LocationSwapper'
import NavMobileMenuToggler from './BottomTabBar/NavMobileMenuToggler'

import './BottomTabBar.scss'

const BottomTabBar = () => {
  const currentLocation = useCurrentLocation()
  const locationsList = useSelector(state => state.locations.list)
  const [isSwapperOpen, setIsSwapperOpen] = useState(false)

  const hasManyLocations = locationsList.length > 1

  const showLocation = hasManyLocations ? (
    <button
      className="highlight-hover cursor-pointer w-full"
      onClick={() => setIsSwapperOpen(!isSwapperOpen)}
    >
      <LocationCard location={currentLocation} />
    </button>
  ) : (
    <LocationCard location={currentLocation} />
  )

  return (
    <div className="bg-dark-700 flex layout-bottomtabbar-container relative">
      <NavMobileMenuToggler />

      {showLocation}

      {isSwapperOpen && <LocationSwapper setIsOpen={setIsSwapperOpen} />}
    </div>
  )
}

export default BottomTabBar

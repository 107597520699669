import React from 'react'
import { NavLink, Route, Switch } from 'react-router-dom'

import DispatchUniqueId from './DispatchUniqueId'

import { ROUTES_DISPATCH_ROOT, ROUTES_DISPATCH_SINGLE } from 'modules/Dispatch/shared/routePaths'

const RouteBreadcrumbs = () => {
  return (
    <nav className="breadcrumb-container">
      <ol className="flex mb-0">
        <li className="route-breadcrumb">
          <NavLink exact activeClassName="non-styled-link cursor-text" to={ROUTES_DISPATCH_ROOT}>
            DISPATCHES
          </NavLink>
        </li>

        <Switch>
          <Route
            path={ROUTES_DISPATCH_SINGLE}
            render={({ match }) => (
              <li className="route-breadcrumb">
                <span className="mx-2">/</span>
                <DispatchUniqueId dispatchId={match.params.dispatchId} />
              </li>
            )}
          />
        </Switch>
      </ol>
    </nav>
  )
}

RouteBreadcrumbs.propTypes = {}
RouteBreadcrumbs.defaultProps = {}

export default RouteBreadcrumbs

import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import useSWR from 'swr'

import DispatchSummary from '../DispatchSummary'
import FailedToLoadResourceMsg from 'components/FailedToLoadResourceMsg'
import EmptySectionSign from 'components/EmptySectionSign'
import Loading from '@therms/web-common/components/Loading'
import { makeDispatchCountRequestRoute, makeDispatchesRequestRoute } from '../shared/routePaths'
import { selectCurrentLocationId } from 'state/ducks/locations'
import PageNavigator from 'components/PageNavigator'

const Dispatches = () => {
  const currentLocationId = useSelector(selectCurrentLocationId)

  const [currentPage, setCurrentPage] = useState(0)
  const pageLimit = 10

  const { data: dispatchRecords, error } = useSWR(
    makeDispatchesRequestRoute(currentLocationId, {
      limit: pageLimit,
      skip: currentPage * pageLimit,
    }),
  )

  const { data: dispatchCount, dispatchCountError } = useSWR(
    makeDispatchCountRequestRoute(currentLocationId),
  )

  if (error || dispatchCountError) return <FailedToLoadResourceMsg />

  if (dispatchRecords === undefined)
    return (
      <div className="relative h-full">
        <div className="bg-dark-900 flex justify-end shadow sticky top-0 z-index-sticky">
          {dispatchCount?.length > pageLimit && (
            <PageNavigator
              currentPage={currentPage}
              limit={pageLimit}
              setCurrentPage={setCurrentPage}
              totalAmount={dispatchCount}
            />
          )}
        </div>

        <Loading />
      </div>
    )

  if (!dispatchRecords.length) return <EmptySectionSign iconName="phone-square" name="Dispatches" />

  return (
    <div className="relative">
      <div className="bg-dark-900 flex justify-end shadow sticky top-0 z-index-sticky">
        <PageNavigator
          currentPage={currentPage}
          limit={pageLimit}
          setCurrentPage={setCurrentPage}
          totalAmount={dispatchCount}
        />
      </div>

      {dispatchRecords.map(dispatch => (
        <div className="list-element-wrapper" key={dispatch.id}>
          <DispatchSummary dispatchId={dispatch.id} />
        </div>
      ))}
    </div>
  )
}

export default Dispatches

import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import NavItem from 'components/NavItem'
import LocationCard from 'modules/Locations/shared/LocationCard'
import LocationSwapper from 'modules/Locations/shared/modules/Layout/LocationSwapper'
import Logout from 'modules/Auth/shared/Logout'
import useCurrentLocation from 'modules/Locations/shared/useCurrentLocation'

import UserName from 'modules/User/shared/UserName'
import UserTitle from 'modules/User/shared/UserTitle'
import ModalController from '@therms/web-common/components/Modal/ModalController'
import OrganizationLogo from 'modules/Organization/shared/OrganizationLogo'
import OrganizationModal from 'modules/Organization/shared/OrganizationModal'
import OrganizationName from 'modules/Organization/shared/OrganizationName'
import FAIcon from '@therms/web-common/components/Icon/FAIcon'

import { ROUTES_DASHBOARD_ROOT } from 'modules/Dashboard/shared/routePaths'
import { ROUTES_BOLOS_ROOT } from 'modules/BOLOs/shared/routePaths'
import { ROUTES_DISPATCH_ROOT } from 'modules/Dispatch/shared/routePaths'
// import { ROUTES_MAIL_ROOT } from 'modules/Mail/shared/routePaths'
import { ROUTES_REPORTS_ROOT } from 'modules/Reports/shared/routePaths'
// import { ROUTES_SETTINGS_ROOT } from 'modules/Settings/shared/routePaths'
import { ROUTES_TASKS_ROOT } from 'modules/Tasks/shared/routePaths'

import './LeftSideMenu.scss'

const LeftSideMenu = () => {
  const currentLocation = useCurrentLocation()
  const locationsList = useSelector(state => state.locations.list)
  const aggregatedModuleAccess = useSelector(state => state.auth.aggregatedModuleAccess)

  const [isOpen, setIsOpen] = useState(false)

  const hasManyLocations = locationsList.length > 1

  const { dispatch, locationBolos, reports, tasks } = aggregatedModuleAccess

  return (
    <div className="bg-white flex flex-col layout-leftsidemenu-container z-index-fixed">
      <div className="flex mb-2 p-2">
        <OrganizationLogo className="object-contain h-12 w-12 mr-2" />

        <div className="font-bold truncate">
          <OrganizationName />

          <ModalController modal={OrganizationModal}>
            {({ show }) => (
              <button
                className="block font-bold highlight-hover rounded text-xs text-link-500"
                onClick={show}
              >
                <FAIcon name="info-circle" /> Contact Info
              </button>
            )}
          </ModalController>
        </div>
      </div>

      <div className="mb-2 p-2">
        <div className="font-bold">
          <UserName isCurrentUser />
        </div>

        <div className="text-base-800 text-sm">
          <UserTitle isCurrentUser />
        </div>
      </div>

      <div className="relative mb-2">
        <LocationCard location={currentLocation} />

        {hasManyLocations && (
          <button
            className="location-switcher float-right font-bold m-1 p-1 text-xs text-right"
            onClick={() => {
              setIsOpen(!isOpen)
            }}
          >
            Change Location
          </button>
        )}

        {isOpen && <LocationSwapper setIsOpen={setIsOpen} />}
      </div>

      <div className="left-side-menu-navigation flex-grow overflow-y-auto">
        <NavItem iconName="columns" itemTitle="Dashboard" linkSlug={ROUTES_DASHBOARD_ROOT} />

        {dispatch && (
          <NavItem iconName="phone-square" itemTitle="Dispatch" linkSlug={ROUTES_DISPATCH_ROOT} />
        )}

        {locationBolos && (
          <NavItem iconName="exclamation-circle" itemTitle="BOLO" linkSlug={ROUTES_BOLOS_ROOT} />
        )}

        {reports && (
          <NavItem iconName="file-alt" itemTitle="Reports" linkSlug={ROUTES_REPORTS_ROOT} />
        )}

        {tasks && <NavItem iconName="tasks" itemTitle="Tasks" linkSlug={ROUTES_TASKS_ROOT} />}

        {/* <NavItem iconName="envelope" itemTitle="Mail" linkSlug={ROUTES_MAIL_ROOT} /> */}
      </div>

      <div className="pt-3">
        {/* <NavItem iconName="cog" itemTitle="Settings" linkSlug={ROUTES_SETTINGS_ROOT} /> */}
        <Logout />
      </div>
    </div>
  )
}

export default LeftSideMenu

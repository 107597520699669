import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import Img from 'components/ImageLoader'
import Loading from '@therms/web-common/components/Loading'

import placeholderImg from 'images/missing_location_75x75.png'
import { selectCurrentLocationId, setCurrentLocation } from 'state/ducks/locations'
import { getImageThumbnailUrl } from 'modules/Files/utils/imageSizeUrls'

const LocationSwapItem = ({ location, setIsOpen }) => {
  const currentLocationId = useSelector(selectCurrentLocationId)
  const dispatch = useDispatch()

  if (currentLocationId === location.id) return null

  if (!location) return <Loading />

  const imgSrc = location.primaryImageUrl
    ? getImageThumbnailUrl(location.primaryImageUrl)
    : placeholderImg

  const pickItem = () => {
    dispatch(setCurrentLocation(location))
    setIsOpen(false)
  }

  return (
    <button
      className="bg-light-100 flex highlight-hover items-center my-2 swap-item h-12 w-full"
      onClick={() => pickItem()}
    >
      <Img className="h-12 object-cover w-12" src={imgSrc} alt="" />
      <div className="font-bold p-2 text-left truncate">{location.name}</div>
    </button>
  )
}

LocationSwapItem.propTypes = {
  location: PropTypes.object.isRequired,
  setIsOpen: PropTypes.func.isRequired,
}

export default LocationSwapItem

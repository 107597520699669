import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { findKey } from 'lodash-es'

import BoloDashboardItem from 'modules/BOLOs/shared/modules/Dashboard/BoloDashboardItem.jsx'
import DispatchDashboardItem from 'modules/Dispatch/shared/modules/Dashboard/DispatchDashboardItem'
import ReportDashboardItem from 'modules/Reports/shared/modules/Dashboard/ReportDashboardItem'
import TasksDashboardItem from 'modules/Tasks/shared/modules/Dashboard/TasksDashboardItem.jsx'
import ModuleMobileSwitcher from '../components/ModuleMobileSwitcher'

import useIsMobileScreen from 'modules/Layout/shared/useIsMobileScreen'

import './Dashboard.scss'

const Dashboard = () => {
  const modulesWithAccess = useSelector(state => state.auth.aggregatedModuleAccess)
  const [activeMobileModule, setActiveMobileModule] = useState(undefined)
  const isMobile = useIsMobileScreen()

  useEffect(() => {
    if (isMobile) setActiveMobileModule(findKey(modulesWithAccess, moduleStatus => moduleStatus))
  }, [modulesWithAccess])

  const isModuleActive = moduleName => {
    if (!isMobile) return true
    if (modulesWithAccess && modulesWithAccess[moduleName]) return activeMobileModule === moduleName
    return false
  }

  const isBolosActive = useMemo(() => isModuleActive('locationBolos'), [
    activeMobileModule,
    modulesWithAccess,
  ])
  const isDispatchActive = useMemo(() => isModuleActive('dispatch'), [
    activeMobileModule,
    modulesWithAccess,
  ])
  const isReportsActive = useMemo(() => isModuleActive('reports'), [
    activeMobileModule,
    modulesWithAccess,
  ])
  const isTasksActive = useMemo(() => isModuleActive('tasks'), [
    activeMobileModule,
    modulesWithAccess,
  ])

  return (
    <div className="h-full flex flex-col relative sm:static">
      <main className="dashboard-container flex flex-grow flex-wrap justify-center sm:h-full">
        {isReportsActive && (
          <div className="dashboard-item sm:flex sm:flex-grow lg:w-6/12 w-full">
            <ReportDashboardItem />
          </div>
        )}

        {isTasksActive && (
          <div className="dashboard-item sm:flex sm:flex-grow lg:w-6/12 w-full">
            <TasksDashboardItem />
          </div>
        )}

        {isBolosActive && (
          <div className="dashboard-item sm:flex sm:flex-grow lg:w-6/12 w-full">
            <BoloDashboardItem />
          </div>
        )}

        {isDispatchActive && (
          <div className="dashboard-item sm:flex sm:flex-grow lg:w-6/12 w-full">
            <DispatchDashboardItem />
          </div>
        )}
      </main>

      {isMobile && (
        <div className="bottom-0 sticky">
          <ModuleMobileSwitcher
            activeModule={activeMobileModule}
            allowedModules={modulesWithAccess}
            setActiveModule={setActiveMobileModule}
          />
        </div>
      )}
    </div>
  )
}

Dashboard.propTypes = {}
Dashboard.defaultProps = {}

export default Dashboard

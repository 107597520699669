import React from 'react'
import PropTypes from 'prop-types'

import './Main.scss'

const Main = ({ children }) => <div className="layout-main-container">{children}</div>

Main.propTypes = {
  children: PropTypes.node.isRequired,
}
Main.defaultProps = {}

export default Main

import React from 'react'
import PropTypes from 'prop-types'
import useSWR from 'swr'

import Img from 'components/ImageLoader'
import FailedToLoadResourceMsg from 'components/FailedToLoadResourceMsg'
import Loading from '@therms/web-common/components/Loading'
import { makeRecordRequestRoute } from '../shared/routePaths'
import ModalController from '@therms/web-common/components/Modal/ModalController'
import placeholderImg from '../../../images/missing_record_150x150.png'
import PersonFields from './RecordThumb/PersonFields'
import PropertyFields from './RecordThumb/PropertyFields'
import RecordModal from './RecordModal'
import VehicleFields from './RecordThumb/VehicleFields'

import { getImageThumbnailUrl } from 'modules/Files/utils/imageSizeUrls'

const RecordThumb = ({ recordId }) => {
  const { data: recordRecord, error } = useSWR(makeRecordRequestRoute(recordId))

  if (error) return <FailedToLoadResourceMsg />

  if (!recordRecord) return <Loading />

  const { primaryImageUrl, type, fields } = recordRecord

  const imgSrc = primaryImageUrl ? getImageThumbnailUrl(primaryImageUrl) : placeholderImg

  let typeFields
  let typeName

  switch (type) {
    case 'person':
      typeFields = <PersonFields recordFields={fields} />
      typeName = 'Person'
      break

    case 'property':
      typeFields = <PropertyFields recordFields={fields} />
      typeName = 'Property'
      break

    case 'vehicle':
      typeFields = <VehicleFields recordFields={fields} />
      typeName = 'Vehicle'
      break

    default:
      break
  }

  return (
    <ModalController modal={RecordModal} modalProps={{ recordId }}>
      {({ show }) => (
        <div className="cursor-pointer highlight-hover non-styled-link" onClick={show}>
          <div className="bg-dark-300 font-bold px-3 rounded-t text-base-200 text-xs tracking-wider uppercase">
            {typeName}
          </div>

          <div className="flex">
            <div>
              <Img src={imgSrc} alt="" className="rounded-bl h-full object-cover" width="75" />
            </div>

            <div className="bg-dark-100 flex items-center px-2 rounded-br text-xs">
              {typeFields}
            </div>
          </div>
        </div>
      )}
    </ModalController>
  )
}

RecordThumb.propTypes = {
  recordId: PropTypes.string.isRequired,
}

export default RecordThumb

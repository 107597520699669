import React from 'react'
import PropTypes from 'prop-types'

const ReportItemCard = ({ children, icon, name }) => {
  return (
    <div className="bg-dark-100 flex flex-grow items-center justify-center p-2 m-1 rounded text-center">
      {icon}
      <div className="ml-3 text-left">
        <p className="font-bold mb-0">{name}</p>
        <div>{children}</div>
      </div>
    </div>
  )
}

ReportItemCard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]),
  icon: PropTypes.element,
  name: PropTypes.string,
}

ReportItemCard.defaultProps = {
  children: undefined,
  icon: undefined,
  name: undefined,
}

export default ReportItemCard

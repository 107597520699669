import React from 'react'
import { NavLink, Route, Switch } from 'react-router-dom'

import { ROUTES_REPORTS_ROOT, ROUTES_REPORTS_SINGLE } from 'modules/Reports/shared/routePaths'

import ReportUniqueId from 'modules/Reports/shared/ReportUniqueId'

const RouteBreadcrumbs = () => {
  return (
    <nav className="breadcrumb-container">
      <ol className="flex mb-0">
        <li className="route-breadcrumb">
          <NavLink exact activeClassName="non-styled-link cursor-text" to={ROUTES_REPORTS_ROOT}>
            REPORTS
          </NavLink>
        </li>

        <Switch>
          <Route
            path={ROUTES_REPORTS_SINGLE}
            render={({ match }) => (
              <li className="route-breadcrumb">
                <span className="mx-2">/</span>
                <ReportUniqueId reportId={match.params.reportId} />
              </li>
            )}
          />
        </Switch>
      </ol>
    </nav>
  )
}

RouteBreadcrumbs.propTypes = {}
RouteBreadcrumbs.defaultProps = {}

export default RouteBreadcrumbs

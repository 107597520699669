import React from 'react'
import PropTypes from 'prop-types'

import CallActionOutline from 'modules/Dispatch/shared/ServiceCallActionOutline.jsx'

const EntriesDispatchCall = ({ reportEntry }) => {
  const fields = reportEntry.fields || {}

  return (
    <div className="reportentry-container">
      <CallActionOutline dispatchId={fields.dispatchId} showActionAssignmentDetails />
    </div>
  )
}

EntriesDispatchCall.propTypes = {
  reportEntry: PropTypes.object.isRequired,
}
EntriesDispatchCall.defaultProps = {}

export default EntriesDispatchCall

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const RibbonField = ({ name, value }) => {
  return (
    <div className="flex flex-grow sm:truncate">
      <div
        className={classnames(
          { 'flex-grow': Boolean(!value) },
          'bg-dark-700 first-uppercase flex font-bold items-center min-w-max-content px-2 py-1 text-base-100 text-sm',
        )}
      >
        {name}
      </div>
      {Boolean(value) && (
        <div className="bg-dark-100 first-uppercase flex flex-grow items-center px-2 py-1 sm:truncate text-sm">
          {value}
        </div>
      )}
    </div>
  )
}

RibbonField.propTypes = {
  name: PropTypes.node.isRequired,
  value: PropTypes.node,
}

RibbonField.defaultProps = {
  value: undefined,
}

export default RibbonField

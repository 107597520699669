import React from 'react'
import PropTypes from 'prop-types'
import useSWR from 'swr'

import ActivityTypeNames from 'modules/ActivityTypes/shared/ActivityTypeNames.jsx'
import FAIcon from '@therms/web-common/components/Icon/FAIcon'
import FailedToLoadResourceMsg from 'components/FailedToLoadResourceMsg'
import Loading from '@therms/web-common/components/Loading'
import ReportItemCard from './ReportItemCard'
import ReportTypeIcon from './ReportType/ReportTypeIcon'
import ReportHeader from './ReportHeader'
import ReportTypeName from './ReportType/ReportTypeName'
import ReportEntriesNumber from './ReportEntriesNumber'
import UserNames from 'modules/User/shared/UserNames'

import { getFormattedReportDate } from '@therms/models/models/report/utils'
import pluralCheck from 'utils/pluralCheck'

import { makeReportRequestRoute } from '../shared/routePaths'

const ReportSummary = ({ reportId }) => {
  const { data: reportRecord, error } = useSWR(makeReportRequestRoute(reportId))

  if (error) return <FailedToLoadResourceMsg />

  if (!reportRecord) return <Loading />

  const { activityTypeIds, contributorUserIds, reportEntryCount, reportTypeId } = reportRecord

  return (
    <section className="dispatch-summary-container shadow">
      <ReportHeader isLink reportId={reportId} />

      <div className="bg-light-100 flex flex-wrap items-center justify-center p-3">
        <ReportItemCard icon={<FAIcon name="calendar-day" lg />} name="Date">
          {getFormattedReportDate(reportRecord)}
        </ReportItemCard>

        <ReportItemCard icon={<ReportTypeIcon reportTypeId={reportTypeId} lg />} name="Type">
          <ReportTypeName reportTypeId={reportTypeId} />
        </ReportItemCard>

        <ReportItemCard
          icon={<FAIcon name="user" lg />}
          name={pluralCheck(contributorUserIds, 'Contributor', 'Contributors')}
        >
          <UserNames ids={contributorUserIds} />
        </ReportItemCard>

        <ReportEntriesNumber
          itemCard
          reportEntryCount={reportEntryCount}
          reportTypeId={reportTypeId}
        />
      </div>

      <footer className="flex flex-wrap">
        {Boolean((activityTypeIds || 0).length) && (
          <div className="flex-grow-max">
            <ActivityTypeNames ids={activityTypeIds} />
          </div>
        )}
      </footer>
    </section>
  )
}

ReportSummary.propTypes = {
  reportId: PropTypes.string.isRequired,
}

export default ReportSummary

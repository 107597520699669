import React from 'react'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'

import Img from 'components/ImageLoader'
import FailedToLoadResourceMsg from 'components/FailedToLoadResourceMsg'
import getSeverityBgClass from 'utils/Severity/getSeverityBgClass'
import Loading from '@therms/web-common/components/Loading'
import { makeRecordRequestRoute } from '../shared/routePaths'
import PersonFullFieldList from '../components/PersonFullFieldList'
import { getImageSmallUrl } from 'modules/Files/utils/imageSizeUrls'

const RecordPerson = () => {
  const { recordId } = useParams()
  const { data: recordRecord, error } = useSWR(makeRecordRequestRoute(recordId))

  if (error) return <FailedToLoadResourceMsg />

  if (!recordRecord) return <Loading />

  const { fields, primaryImageUrl, uniqueId } = recordRecord

  const { dangerous, disease, mentalIllness } = fields

  return (
    <main>
      <header className="bg-white font-bold px-4 py-2">Person Record - {uniqueId}</header>
      <div className="flex flex-col md:flex-row">
        {!!primaryImageUrl && (
          <div>
            <Img
              src={getImageSmallUrl(primaryImageUrl)}
              alt=""
              className="mx-auto object-cover md:w-auto"
              height="175"
              width="175"
            />
          </div>
        )}

        <PersonFullFieldList fields={fields} />
      </div>

      {(dangerous || disease || mentalIllness) && (
        <div className="flex mt-3 space-x-3">
          {dangerous && (
            <div className={`${getSeverityBgClass(3)} flex items-center rounded`}>
              <div className="font-bold p-2 text-base-100 text-center">Dangerous or Combative</div>
            </div>
          )}

          {disease && (
            <div className={`${getSeverityBgClass(2)} flex items-center rounded`}>
              <div className="font-bold p-2 text-base-100 text-center">Disease/Infection</div>
            </div>
          )}

          {mentalIllness && (
            <div className={`${getSeverityBgClass(0)} flex items-center rounded`}>
              <div className="font-bold p-2 text-base-100 text-center">Mental Illness or 5150</div>
            </div>
          )}
        </div>
      )}
    </main>
  )
}

export default RecordPerson

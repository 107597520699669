import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'

import AuthWrapper from './modules/Auth/shared/AuthWrapper'
import Layout from 'modules/Layout'
import Routes from './modules/Routes'
import store from './state'
import { SwrAppWrapper } from './services/swr'

import 'styles/index.scss'

export default () => (
  <Provider store={store}>
    <AuthWrapper>
      <BrowserRouter>
        <SwrAppWrapper>
          <Layout>
            <Routes />
          </Layout>
        </SwrAppWrapper>
      </BrowserRouter>
    </AuthWrapper>
  </Provider>
)

import React, { Suspense } from 'react'
import PropTypes from 'prop-types'

import { useImage } from 'react-image'
import Loading from '@therms/web-common/components/Loading'
import placeholderImg from 'images/image-placeholder.png'

const ImageLoaderComponent = ({ className, height, src, width }) => {
  const { src: imageSrc } = useImage({
    srcList: [src, placeholderImg],
  })

  return (
    <img src={imageSrc} alt="" className={className} height={height} width={width} key={imageSrc} />
  )
}

ImageLoaderComponent.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  src: PropTypes.string.isRequired,
  width: PropTypes.string,
}

ImageLoaderComponent.defaultProps = {
  className: undefined,
  height: undefined,
  width: undefined,
}

const ImageLoader = ({ className, height, src, width }) => (
  <Suspense
    fallback={(
      <div className={`${className} overflow-hidden`}>
        <Loading sm />
      </div>
    )}
  >
    <ImageLoaderComponent className={className} height={height} src={src} width={width} />
  </Suspense>
)

ImageLoader.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  src: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  width: PropTypes.string,
}

ImageLoader.defaultProps = {
  className: undefined,
  height: undefined,
  width: undefined,
}

export default ImageLoader

import React from 'react'
import { find } from 'lodash-es'
import { useSelector } from 'react-redux'
import useSWR from 'swr'

import FailedToLoadResourceMsg from 'components/FailedToLoadResourceMsg'
import { makeOrganizationRequestRoute } from './routePaths'
import { selectCurrentLocationId } from 'state/ducks/locations'

const OrganizationName = () => {
  const locationId = useSelector(selectCurrentLocationId)
  const locationsList = useSelector(state => state.locations.list)
  const currentLocation = find(locationsList, location => location.id === locationId)

  const organizationId = currentLocation.organizationId

  const { data: organizationRecord, error } = useSWR(makeOrganizationRequestRoute(organizationId))

  if (error) return <FailedToLoadResourceMsg />

  if (!organizationRecord) return <span className="loading" />

  return <span>{organizationRecord.name} </span>
}

export default OrganizationName

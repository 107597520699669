import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import useSWR from 'swr'

import { makeDispatchRequestRoute } from './routePaths'
import { selectCurrentLocationId } from 'state/ducks/locations'

import FailedToLoadResourceMsg from 'components/FailedToLoadResourceMsg'

const DispatchUniqueId = ({ dispatchId }) => {
  const locationId = useSelector(selectCurrentLocationId)
  const { data: dispatchRecord, error } = useSWR(
    makeDispatchRequestRoute({ dispatchId, locationId }),
  )

  if (error) return <FailedToLoadResourceMsg />

  if (!dispatchRecord) return <span className="loading" />

  return <>{dispatchRecord.uniqueId}</>
}

DispatchUniqueId.propTypes = {
  dispatchId: PropTypes.string.isRequired,
}

export default DispatchUniqueId

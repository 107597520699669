import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'

import BOLOs from './screens/BOLOs'
import BoloModuleConditional from './shared/BoloModuleConditional'
import EmptySectionSign from 'components/EmptySectionSign'

import { ROUTES_BOLOS_ROOT } from './shared/routePaths'

const BOLORoutes = () => (
  <BoloModuleConditional
    fallbackComponent={<EmptySectionSign forbidden iconName="exclamation-circle" name="BOLOs" />}
  >
    <Switch>
      <Route exact path={ROUTES_BOLOS_ROOT} component={BOLOs} />
    </Switch>
  </BoloModuleConditional>
)

export default withRouter(BOLORoutes)

import React from 'react'
import PropTypes from 'prop-types'

import ActivityTypeNames from '../../../../ActivityTypes/shared/ActivityTypeNames'
import Files from 'modules/Files/shared/Files'
import RecordThumbsContainer from 'modules/Records/shared/RecordThumbsContainer'

const EntriesObservation = ({ reportEntry }) => {
  const fields = reportEntry.fields || {}

  return (
    <div className="reportentry-container">
      {fields.activityTypeIds && (
        <div className="p-1">
          <ActivityTypeNames ids={fields.activityTypeIds} badge />
        </div>
      )}

      <div className="list-entry-light p-2">
        <strong>Location Observed From</strong>

        <div>{fields.location}</div>
      </div>

      <div className="list-entry-light p-2">
        <strong>Observation Details</strong>

        <div className="with-whitespace">{fields.details}</div>
      </div>

      {fields.recordIds && (
        <div>
          <div className="bg-dark-500 font-bold px-4 py-2 text-right text-base-200">
            Linked Records
          </div>
          <RecordThumbsContainer recordIds={fields.recordIds} />
        </div>
      )}

      <Files fileUrls={fields.files} />      
    </div>
  )
}

EntriesObservation.propTypes = {
  reportEntry: PropTypes.object.isRequired,
}
EntriesObservation.defaultProps = {}

export default EntriesObservation

import React from 'react'
import PropTypes from 'prop-types'

import './MobileMain.scss'

const MobileMain = ({ children }) => <div className="layout-mobilemain-container">{children}</div>

MobileMain.propTypes = {
  children: PropTypes.element.isRequired,
}

export default MobileMain

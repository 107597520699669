import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { pick, orderBy } from 'lodash-es'

import FailedToLoadResourceMsg from 'components/FailedToLoadResourceMsg'
import { fetchAndSetActivityTypesById } from 'state/ducks/activityTypes'
import ActivityTypeName from './ActivityTypeName'

function ActivityTypeNames({ ids }) {
  const dispatch = useDispatch()
  const activityTypesFetched = useSelector(state => state.activityTypes.hasFetched)
  const activityTypesById = useSelector(state => state.activityTypes.byId)

  const [sortedActivityTypes, setSortedActivityTypes] = useState([])

  useEffect(() => {
    if (!activityTypesFetched) {
      dispatch(fetchAndSetActivityTypesById())
    }
  }, [activityTypesFetched])

  useEffect(() => {
    if (activityTypesFetched) {
      setSortedActivityTypes(() => {
        const filteredList = pick(activityTypesById, ids)
        const sortedList = orderBy(filteredList, ['severity', 'name'], ['desc'])
        setSortedActivityTypes(sortedList)
      })
    }
  }, [activityTypesFetched, ids])

  if (!activityTypesFetched)
    return (
      <div className="bg-dark-100 flex flex-wrap items-center p-1">
        <div className="m-1">
          <div className="bg-dark-500 p1 rounded text-center">
            <span className="loading" />
          </div>
        </div>
      </div>
    )

  if ((sortedActivityTypes || []).length === 0) return null

  if ((sortedActivityTypes || []).length) {
    return (
      <div className="bg-dark-100 flex flex-wrap items-center p-1">
        {sortedActivityTypes.map(({ id }) => (
          <span className="m-1" key={id}>
            <ActivityTypeName id={id} />
          </span>
        ))}
      </div>
    )
  }

  return <FailedToLoadResourceMsg />
}

ActivityTypeNames.propTypes = {
  ids: PropTypes.array.isRequired,
}

export default ActivityTypeNames

import React from 'react'
import { Link } from 'react-router-dom'
// import PropTypes from 'prop-types'

import FAIcon from '@therms/web-common/components/Icon/FAIcon'

const MailNavigation = () => {
  return (
    <div className="bg-dark-500 flex items-center justify-end text-gray-100">
      <Link to="#" className="non-styled-link py-3 px-12 tab text-gray-100">
        <FAIcon name="angle-left" lg />
      </Link>
      <div className="bg-dark-300 h-8 -mx-px w-px" />
      <Link to="#" className="non-styled-link py-3 px-12 tab text-gray-100">
        <FAIcon name="angle-right" lg />
      </Link>
    </div>
  )
}

MailNavigation.propTypes = {}

export default MailNavigation

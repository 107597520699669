// eslint-disable-next-line import/no-cycle
import authApi from './authApi'
import cookies from 'js-cookie'

const LAST_URLPATH_COOKIE_NAME = 'portal-auth-failed-at-url'

export function getLastAuthUrlPath() {
  return cookies.get(LAST_URLPATH_COOKIE_NAME)
}

export function handleAuthFailed() {
  // store current url in "lastUrl" cookie (so we can expire it after 2hr)
  const inTwoHr = new Date(new Date().getTime() + 120 * 60 * 1000)
  cookies.set(LAST_URLPATH_COOKIE_NAME, window.location.pathname, {
    expires: inTwoHr,
  })

  window.location = process.env.AUTH_UI
}

export async function handleLogout() {
  try {
    authApi.get('logout')
    cookies.remove(process.env.AUTH_COOKIE_NAME)
    window.location.href = process.env.AUTH_UI
  } catch (error) {
    console.log('there was an error while trying to log out', error)
  }
}

export function resetLastAuthUrlPath() {
  return cookies.remove(LAST_URLPATH_COOKIE_NAME)
}

import React from 'react'
import PropTypes from 'prop-types'

import UserName from './UserName'

const UserNames = ({ ids }) => {
  let names
  if ((ids || []).length) {
    names = ids.map(id => <UserName userId={id} key={id} />)
  }

  return <>{names}</>
}

UserNames.propTypes = {
  ids: PropTypes.array.isRequired,
}

export default UserNames

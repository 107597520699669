import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useSWR from 'swr'

import FailedToLoadResourceMsg from 'components/FailedToLoadResourceMsg'
import Loading from '@therms/web-common/components/Loading'
import {
  makeTaskExecutionsCountRequestRoute,
  makeTaskExecutionsRequestRoute,
} from './shared/routePaths'
import PageNavigator from 'components/PageNavigator'
import TaskExecution from './components/TaskExecution'

const TaskExecutions = ({ small, taskId }) => {
  const [currentPage, setCurrentPage] = useState(0)
  const pageLimit = small ? 5 : 10

  const { data: executions, error } = useSWR(
    makeTaskExecutionsRequestRoute(taskId, { limit: pageLimit, skip: currentPage * pageLimit }),
  )

  const { data: taskCount, taskCountError } = useSWR(makeTaskExecutionsCountRequestRoute(taskId))

  if (error || taskCountError) return <FailedToLoadResourceMsg />

  if (executions === undefined) return <Loading />

  return (
    <div className="bg-dark-100">
      {executions.map(execution => (
        <TaskExecution id={execution.id} key={execution.id} small={small} />
      ))}

      <div className="bg-dark-900 flex justify-end">
        <PageNavigator
          currentPage={currentPage}
          limit={pageLimit}
          setCurrentPage={setCurrentPage}
          small={small}
          totalAmount={taskCount}
        />
      </div>
    </div>
  )
}

TaskExecutions.propTypes = {
  small: PropTypes.bool,
  taskId: PropTypes.string.isRequired,
}

TaskExecutions.defaultProps = {
  small: false,
}

export default TaskExecutions

import React, { useState } from 'react'
import useSWR from 'swr'
import { useSelector } from 'react-redux'

import Bolo from '../components/Bolo'
import EmptySectionSign from 'components/EmptySectionSign'
import FailedToLoadResourceMsg from 'components/FailedToLoadResourceMsg'
import Loading from '@therms/web-common/components/Loading'
import { makeBoloCountRequestRoute, makeBolosRequestRoute } from '../shared/routePaths'
import PageNavigator from 'components/PageNavigator'
import { selectCurrentLocationId } from 'state/ducks/locations'

const BOLOs = () => {
  const currentLocationId = useSelector(selectCurrentLocationId)

  const [currentPage, setCurrentPage] = useState(0)
  const pageLimit = 10

  const { data: boloRecords, error } = useSWR(
    makeBolosRequestRoute(currentLocationId, {
      limit: pageLimit,
      skip: currentPage * pageLimit,
    }),
  )

  const { data: boloCount, boloCountError } = useSWR(makeBoloCountRequestRoute(currentLocationId))

  if (error || boloCountError) return <FailedToLoadResourceMsg />

  if (boloRecords === undefined)
    return (
      <div className="relative h-full">
        <div className="bg-dark-900 flex justify-end shadow sticky top-0 z-index-sticky">
          {boloCount?.length > pageLimit && (
            <PageNavigator
              currentPage={currentPage}
              limit={pageLimit}
              setCurrentPage={setCurrentPage}
              totalAmount={boloCount}
            />
          )}
        </div>

        <Loading />
      </div>
    )

  if (!boloRecords.length) return <EmptySectionSign iconName="exclamation-circle" name="BOLOs" />

  return (
    <div className="relative">
      <div className="bg-dark-900 flex justify-end shadow sticky top-0 z-index-sticky">
        <PageNavigator
          currentPage={currentPage}
          limit={pageLimit}
          setCurrentPage={setCurrentPage}
          totalAmount={boloCount}
        />
      </div>

      {boloRecords.map(boloRecord => (
        <div className="list-element-wrapper" key={`${boloRecord.title}-${boloRecord.createdAt}`}>
          <Bolo boloRecord={boloRecord} />
        </div>
      ))}
    </div>
  )
}

export default BOLOs

import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import useSWR from 'swr'

import ActivityTypeNames from 'modules/ActivityTypes/shared/ActivityTypeNames'
import DateTime from '@therms/web-common/components/DateTime'
import FailedToLoadResourceMsg from 'components/FailedToLoadResourceMsg'
import FAIcon from '@therms/web-common/components/Icon/FAIcon'
import Loading from '@therms/web-common/components/Loading'
import PriorityBlockBadge from 'components/PriorityBlockBadge'
import RibbonField from 'components/RibbonField'
import { makeDispatchRecordRoute } from 'modules/Dispatch/shared/routePaths'
import { selectCurrentLocationId } from 'state/ducks/locations'

import './DispatchSummary.scss'

const DispatchSummary = ({ dispatchId }) => {
  const currentLocationId = useSelector(selectCurrentLocationId)

  const { data: dispatchRecord, error } = useSWR(
    `/location/${currentLocationId}/dispatch/${dispatchId}`,
  )

  if (error) return <FailedToLoadResourceMsg />

  if (!dispatchRecord) return <Loading />

  const {
    activityTypeIds,
    priority,
    reportingParty,
    reportingPartySummary,
    reportingPhone,
    reportingTime,
  } = dispatchRecord.fields

  return (
    <section className="dispatch-summary-container shadow">
      <Link
        to={makeDispatchRecordRoute(dispatchRecord.id)}
        className="non-styled-link parent-link-highlight-text parent-slide-right"
      >
        <header className="bg-white flex justify-between p-2">
          <span className="font-bold flex items-center px-3 text-lg tracking-wider">
            {dispatchRecord.uniqueId}
          </span>

          <div className="pr-4 px-3">
            <FAIcon
              name="angle-right"
              className="child-link-highlight-text child-slide-right hide-on-print"
              lg
            />
          </div>
        </header>
      </Link>

      <div className="flex flex-wrap">
        <RibbonField name="Reporting Party" value={reportingParty} />
        <RibbonField name="Reporting Time" value={<DateTime timestamp={reportingTime} />} />
        {reportingPhone && (
          <RibbonField
            name="Phone"
            value={<a href={`tel:${reportingPhone}`}>{reportingPhone}</a>}
          />
        )}
      </div>

      <div className="bg-light-100 px-4 py-3">
        <p className="mb-0">{reportingPartySummary}</p>
      </div>

      <footer className="flex flex-wrap">
        {Boolean((activityTypeIds || 0).length) && (
          <div className="flex-grow-max">
            <ActivityTypeNames ids={activityTypeIds} />
          </div>
        )}

        <div className="flex-grow">
          <PriorityBlockBadge priority={priority} />
        </div>
      </footer>
    </section>
  )
}

DispatchSummary.propTypes = {
  dispatchId: PropTypes.string.isRequired,
}

export default DispatchSummary

import React, { useState } from 'react'
import PropTypes from 'prop-types'

import DateTime from '@therms/web-common/components/DateTime/index'
import FAIcon from '@therms/web-common/components/Icon/FAIcon'
import getPriorityBgClass from '@therms/web-common/components/Priority/getPriorityBgClass'
import Files from 'modules/Files/shared/Files'
import PriorityBlockBadge from 'components/PriorityBlockBadge'
import RecordThumbsContainer from 'modules/Records/shared/RecordThumbsContainer'
import RibbonField from 'components/RibbonField'
import UserName from 'modules/User/shared/UserName'

import './Bolo.scss'

const Bolo = ({ boloRecord }) => {
  const [expanded, setExpanded] = useState(false)

  const { body, createdAt, createdBy, expireDate, files, priority, recordIds, title } = boloRecord

  if (expanded) {
    return (
      <section className="bolo-container bg-light-100 shadow">
        <div className="flex">
          <div className={`${getPriorityBgClass(priority)} hidden md:block px-1`} />

          <div className="flex-grow">
            <header
              className="bg-white flex cursor-pointer items-center parent-link-highlight-text p-2 px-4"
              onClick={() => setExpanded(false)}
            >
              <h2 className="flex-1 mb-0 font-bold text-lg">{title}</h2>
              <div className="flex items-center text-base-100">
                <span className="hidden md:inline mr-2">
                  Expires <DateTime timestamp={expireDate} relativeTime="from now" />
                </span>
                <span className="child-link-highlight-text hide-on-print">
                  <FAIcon name="angle-up" lg />
                </span>
              </div>
            </header>

            <div className="flex flex-wrap">
              <RibbonField name="Created" value={<DateTime relativeTime timestamp={createdAt} />} />

              <RibbonField
                name="Expires"
                value={<DateTime relativeTime timestamp={expireDate} />}
              />

              <RibbonField name="By" value={<UserName userId={createdBy} />} />

              <div className="flex-grow">
                <PriorityBlockBadge priority={priority} />
              </div>
            </div>

            <p className="list-entry-light pt-3 px-3">{body}</p>

            {Array.isArray(recordIds) && Boolean(recordIds.length) && (
              <div className="list-entry-light flex flex-wrap p-2">
                <RecordThumbsContainer recordIds={recordIds} />
              </div>
            )}

            <Files fileUrls={files} />
          </div>
        </div>
      </section>
    )
  }

  return (
    <section
      className="bolo-container bg-white cursor-pointer flex parent-link-highlight-text shadow"
      onClick={() => setExpanded(true)}
    >
      <div className={`${getPriorityBgClass(priority)} px-1 self-stretch`} />

      <div className="flex-grow items-center md:flex md:py-4 p-4">
        <h2 className="font-bold mb-0 min-w-max-content text-lg truncate">{title}</h2>
        <span className="font-bold hidden md:block mx-2">|</span>
        <p className="flex-grow md:mb-0 md:truncate text-base-800">{body}</p>

        <div className="flex items-center justify-between">
          <span>
            Expires <DateTime timestamp={expireDate} relativeTime="from now" />
          </span>
          <span className="child-link-highlight-text hide-on-print px-4">
            <FAIcon name="angle-down" lg />
          </span>
        </div>
      </div>
    </section>
  )
}

Bolo.propTypes = {
  boloRecord: PropTypes.object.isRequired,
}

export default Bolo

import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'

import Settings from './screens/Settings'

import { ROUTES_SETTINGS_ROOT } from './shared/routePaths'

const SettingsRoutes = () => (
  <Switch>
    <Route exact path={ROUTES_SETTINGS_ROOT} component={Settings} />
  </Switch>
)

export default withRouter(SettingsRoutes)

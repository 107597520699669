import React from 'react'
import PropTypes from 'prop-types'

import ActivityTypeNames from '../../../../ActivityTypes/shared/ActivityTypeNames'
import Files from 'modules/Files/shared/Files'
import RecordThumbsContainer from '../../../../Records/shared/RecordThumbsContainer'
import RibbonField from 'components/RibbonField'

const EntriesCameraActivity = ({ reportEntry }) => {
  const fields = reportEntry.fields || {}

  return (
    <div className="reportentry-container">
      {fields.activityTypeIds && (
        <div className="p-1">
          <ActivityTypeNames ids={fields.activityTypeIds} badge />
        </div>
      )}

      <div className="flex flex-wrap">
        <RibbonField name="Camera #/ID" value={fields.cameraId} />
        <RibbonField name="Location" value={fields.cameraLocation} />
      </div>

      <div className="list-entry-light p-2 with-whitespace">{fields.activity}</div>

      {fields.recordIds && (
        <div>
          <div className="bg-dark-500 font-bold px-4 py-2 text-right text-base-200">
            Linked Records
          </div>
          <RecordThumbsContainer recordIds={fields.recordIds} />
        </div>
      )}

      <Files fileUrls={fields.files} />      
    </div>
  )
}

EntriesCameraActivity.propTypes = {
  reportEntry: PropTypes.object.isRequired,
}
EntriesCameraActivity.defaultProps = {}

export default EntriesCameraActivity

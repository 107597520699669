import React from 'react'
import PropTypes from 'prop-types'

import useActivityTypes from '../hooks/useActivityTypes'

import Select from '@therms/web-common/components/Inputs/Select'

const SelectActivityTypes = ({ onChange, value }) => {
  const activityTypes = useActivityTypes()

  const activityTypeOptions = Object.values(activityTypes).map(({ id, name }) => {
    return { label: name, value: id }
  })

  return (
    <Select
      multi
      name="activity-types"
      options={activityTypeOptions}
      placeholder="Select Activity Types"
      value={value}
      onChange={onChange}
    />
  )
}

SelectActivityTypes.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
}

SelectActivityTypes.defaultProps = {
  onChange: null,
  value: undefined,
}

export default SelectActivityTypes

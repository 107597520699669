import React from 'react'
import PropTypes from 'prop-types'
import useSWR from 'swr'

import FAIcon from '@therms/web-common/components/Icon/FAIcon'
import FailedToLoadResourceMsg from 'components/FailedToLoadResourceMsg'
import ReportItemCard from './ReportItemCard'

import { makeReportTypeRequestRoute } from '../shared/routePaths'

const ReportEntriesNumber = ({ itemCard, reportEntryCount, reportTypeId }) => {
  const { data: reportTypeRecord, error } = useSWR(makeReportTypeRequestRoute(reportTypeId))

  if (error) return <FailedToLoadResourceMsg />

  if (!reportTypeRecord) return <span className="loading" />

  if (reportTypeRecord.staticForm) return null

  if (itemCard)
    return (
      <ReportItemCard icon={<FAIcon name="hashtag" lg />} name="Entries">
        {reportEntryCount}
      </ReportItemCard>
    )

  return (
    <div className="bg-dark-900 rounded font-bold p-3 px-4 text-center text-base-200">
      <div className="capitalize text-lg">{reportEntryCount}</div>
      <div className="text-lg">Entries</div>
    </div>
  )
}

ReportEntriesNumber.propTypes = {
  itemCard: PropTypes.bool,
  reportEntryCount: PropTypes.number,
  reportTypeId: PropTypes.string,
}

ReportEntriesNumber.defaultProps = {
  itemCard: false,
  reportEntryCount: undefined,
  reportTypeId: undefined,
}

export default ReportEntriesNumber

import React from 'react'
import PropTypes from 'prop-types'

const Badge = ({ children, className }) => (
  <span
    className={`${className} font-bold inline-flex items-center p-1 px-2 rounded text-base-100 text-center text-xs`}
  >
    {children}
  </span>
)

Badge.propTypes = {
  children: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
}
Badge.defaultProps = {
  children: '',
  className: 'bg-',
}

export default Badge

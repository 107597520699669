import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import FAIcon from '@therms/web-common/components/Icon/FAIcon'

const IconTab = ({ children, iconLast, iconName, linkSlug }) => {
  return (
    <NavLink
      activeClassName="tab-active"
      className="bg-dark-900 flex hover:no-underline items-center no-underline p-3 tab"
      exact
      to={linkSlug}
    >
      <FAIcon className={`mx-2 ${iconLast && 'order-last'}`} name={iconName} lg />
      <span className="font-bold mx-2 text-lg">{children}</span>
    </NavLink>
  )
}

IconTab.propTypes = {
  children: PropTypes.string.isRequired,
  iconLast: PropTypes.bool,
  iconName: PropTypes.string,
  linkSlug: PropTypes.string,
}
IconTab.defaultProps = {
  iconLast: false,
  iconName: '',
  linkSlug: '',
}

export default IconTab

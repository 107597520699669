import React from 'react'
import { find } from 'lodash-es'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import useSWR from 'swr'

import Img from 'components/ImageLoader'
import FailedToLoadResourceMsg from 'components/FailedToLoadResourceMsg'
import Loading from '@therms/web-common/components/Loading'
import { makeOrganizationRequestRoute } from './routePaths'
import { selectCurrentLocationId } from 'state/ducks/locations'

const OrganizationLogo = ({ className }) => {
  const locationId = useSelector(selectCurrentLocationId)
  const locationsList = useSelector(state => state.locations.list)
  const currentLocation = find(locationsList, location => location.id === locationId)

  const organizationId = currentLocation.organizationId

  const { data: organizationRecord, error } = useSWR(makeOrganizationRequestRoute(organizationId))

  if (error) return <FailedToLoadResourceMsg />

  if (!organizationRecord) return <Loading />

  return <Img src={organizationRecord.logoUrl} alt="" className={className} />
}

export default OrganizationLogo

OrganizationLogo.propTypes = {
  className: PropTypes.string,
}

OrganizationLogo.defaultProps = {
  className: '',
}

import React from 'react'
import PropTypes from 'prop-types'

const RenderDisplayRadio = ({ options, formValue }) => {
  if (!formValue) return null

  // formValue is the index of the selected option
  const selectedOption = options.options[+formValue]

  return (
    <>
      {options.displayLabel && (
        <div className="font-weight-bold">{options.displayLabel}</div>
      )}

      <span>{selectedOption.displayLabel || selectedOption.formLabel}</span>
    </>
  )
}

RenderDisplayRadio.propTypes = {
  formValue: PropTypes.any,
  options: PropTypes.object.isRequired,
}
RenderDisplayRadio.defaultProps = {
  formValue: undefined,
}

export default RenderDisplayRadio

import React from 'react'
import PropTypes from 'prop-types'

import LocationNames from '../../../../Locations/shared/LocationNames'
import pluralCheck from 'utils/pluralCheck'
import ReportEntryDateTime from './ReportEntryDateTime'
import RibbonField from 'components/RibbonField'
import UserName from '../../../../User/shared/UserName'

const ReportEntryHeader = ({ report, reportEntry, reportEntryType }) => {
  const showSubLocations = Boolean((reportEntry.fields.subLocationIds || []).length)

  return (
    <div className="bg-dark-800">
      <div className="flex justify-content-between p-2 text-base-200">
        <div>
          <span>{reportEntryType.name} </span>
        </div>

        <div>
          <span className="mx-2">
            <ReportEntryDateTime reportEntry={reportEntry} report={report} />
          </span>
          <UserName userId={reportEntry.updatedBy} />
        </div>
      </div>

      {showSubLocations && (
        <RibbonField
          name={pluralCheck(reportEntry.fields.subLocationIds, 'Sub-Location', 'Sub-Locations')}
          value={<LocationNames ids={reportEntry.fields.subLocationIds} />}
        />
      )}
    </div>
  )
}

ReportEntryHeader.propTypes = {
  report: PropTypes.object.isRequired,
  reportEntry: PropTypes.object.isRequired,
  reportEntryType: PropTypes.object.isRequired,
}
ReportEntryHeader.defaultProps = {}

export default ReportEntryHeader

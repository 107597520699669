import React from 'react'
import PropTypes from 'prop-types'

import ActivityTypeNames from '../../../../ActivityTypes/shared/ActivityTypeNames'
import Files from 'modules/Files/shared/Files'
import RecordThumbsContainer from '../../../../Records/shared/RecordThumbsContainer'

const EntriesUseOfForce = ({ reportEntry }) => {
  const fields = reportEntry.fields || {}

  return (
    <div className="reportentry-container">
      {fields.activityTypeIds && (
        <div className="p-1">
          <ActivityTypeNames ids={fields.activityTypeIds} badge />
        </div>
      )}

      <div className="list-entry-light p-2">
        <div className="font-weight-bold">Person(s) Involved in Applying Use of Force</div>

        <div>{fields.personsInvolved}</div>
      </div>

      <div className="list-entry-light p-2">
        <div className="font-weight-bold">Force Applied</div>

        <div className="pl-2">
          {fields.forceAppliedPhysical && <div className="font-italic">Physical Force Applied</div>}
          {fields.forceAppliedBaton && <div className="font-italic">Baton Force Applied</div>}
          {fields.forceAppliedFirearm && <div className="font-italic">Firearm Force Applied</div>}
          {fields.forceAppliedChemical && (
            <div className="font-italic">Chemical/OC Spray Force Applied</div>
          )}
          {fields.forceAppliedTaser && <div className="font-italic">Taser Force Applied</div>}
          {fields.forceAppliedOther && (
            <div>
              <div className="d-inline-block">Other Force Applied:</div>

              <div className="d-inline-block font-italic ml-2">
                {fields.forceAppliedOtherDetails}
              </div>
            </div>
          )}
        </div>
      </div>
      {fields.details && (
        <div className="list-entry-light p-2">
          <strong>Details Leading Up To the Use of Force Incident</strong>

          <div className="with-whitespace">{fields.details}</div>
        </div>
      )}

      {fields.reason && (
        <div className="list-entry-light p-2">
          <strong>Reason for Use of Force</strong>

          <div className="with-whitespace">{fields.reason}</div>
        </div>
      )}

      {fields.injuries && (
        <div className="list-entry-light p-2">
          <strong>Injury Details</strong>

          <div className="with-whitespace">{fields.injuryDetails}</div>
        </div>
      )}

      {fields.stepsFollowing && (
        <div className="list-entry-light p-2">
          <strong>Steps or Actions Taken Following Use of Force</strong>

          <div className="with-whitespace">{fields.stepsFollowing}</div>
        </div>
      )}

      {fields.other && (
        <div className="list-entry-light p-2">
          <strong>Other Notes/Details</strong>

          <div className="with-whitespace">{fields.other}</div>
        </div>
      )}

      {fields.recordIds && (
        <div>
          <div className="bg-dark-500 font-bold px-4 py-2 text-right text-base-200">
            Linked Records
          </div>
          <RecordThumbsContainer recordIds={fields.recordIds} />
        </div>
      )}

      <Files fileUrls={fields.files} />
    </div>
  )
}

EntriesUseOfForce.propTypes = {
  reportEntry: PropTypes.object.isRequired,
}
EntriesUseOfForce.defaultProps = {}

export default EntriesUseOfForce

import React from 'react'
import PropTypes from 'prop-types'

const LabeledInput = ({ name, labelTitle, placeholder, type }) => {
  return (
    <div className="flex items-center labeled-text-input">
      <label
        htmlFor={name}
        className="bg-white border-r-4 border-gray-600 capitalize font-bold mb-0 mr-3 px-3 py-2 text-right w-32"
      >
        {labelTitle || name}
      </label>
      <input
        className="bg-white border-1 border-gray-600 flex-1 mb-0 px-2 py-1 rounded"
        id={name}
        name={name}
        placeholder={placeholder}
        type={type}
      />
    </div>
  )
}

LabeledInput.propTypes = {
  labelTitle: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
}

LabeledInput.defaultProps = {
  labelTitle: '',
  placeholder: '',
  type: 'text',
}

export default LabeledInput

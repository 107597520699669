import React from 'react'
import PropTypes from 'prop-types'

import DateTime from '@therms/web-common/components/DateTime'
import getPriorityBgClass from '@therms/web-common/components/Priority/getPriorityBgClass'
import RibbonField from 'components/RibbonField'

import './BoloThumb.scss'

const BoloThumb = ({ locationBolo }) => {
  const { body, createdAt, expireDate, priority, title } = locationBolo

  return (
    <div className="bg-light-100 bolo-thumb mb-2">
      <div className="flex">
        <div className={`${getPriorityBgClass(priority)} w-2`} />

        <div className="w-full">
          <div className="p-3">
            <h3 className="capitalize font-bold text-lg">{title}</h3>
            <p className="mb-0">{body}</p>
          </div>
          <div className="flex flex-wrap">
            <RibbonField
              name="Created"
              value={<DateTime timestamp={createdAt} relativeTime="ago" />}
            />
            <RibbonField
              name="Expires"
              value={<DateTime timestamp={expireDate} relativeTime="from now" />}
            />
          </div>
        </div>
      </div>

      <div className="bg-light-400 child-link-highlight-background h-2" />
    </div>
  )
}

BoloThumb.propTypes = {
  locationBolo: PropTypes.object.isRequired,
}

export default BoloThumb

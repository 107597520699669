import React from 'react'
import PropTypes from 'prop-types'

const VehicleFields = ({ recordFields }) => {
  const { color, model, make, plate, state, year } = recordFields

  return (
    <div className="p-1">
      <div className="flex">
        <span className="min-w-max-content mb-2 mx-2">
          <div>
            <strong className="font-bold">Model:</strong>
          </div>

          <span>{model || 'N/A'}</span>
        </span>

        <span className="min-w-max-content mx-2">
          <div>
            <strong className="font-bold">Make:</strong>
          </div>

          <span>{make || 'N/A'}</span>
        </span>

        <span className="min-w-max-content mx-2">
          <div>
            <strong className="font-bold">Color:</strong>
          </div>

          <span>{color || 'N/A'}</span>
        </span>
      </div>

      <div className="flex">
        <span className="min-w-max-content mx-2">
          <div>
            <strong className="font-bold">Plate:</strong>
          </div>

          <span>{plate || 'N/A'}</span>
        </span>

        <span className="min-w-max-content mx-2">
          <div>
            <strong className="font-bold">State:</strong>
          </div>

          <span>{state || 'N/A'}</span>
        </span>

        <span className="min-w-max-content mx-2">
          <div>
            <strong className="font-bold">Year:</strong>
          </div>

          <span>{year || 'N/A'}</span>
        </span>
      </div>
    </div>
  )
}

VehicleFields.propTypes = {
  recordFields: PropTypes.shape({
    color: PropTypes.string,
    make: PropTypes.string,
    model: PropTypes.string,
    plate: PropTypes.string,
    state: PropTypes.string,
    type: PropTypes.string,
    vin: PropTypes.string,
    year: PropTypes.number,
  }).isRequired,
}

export default VehicleFields

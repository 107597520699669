export const ROUTES_REPORTS_ROOT = '/reports'
export const ROUTES_REPORTS_SINGLE = '/reports/:reportId'

export const API_LOCATION_REPORTS = '/report/?locationId=:locationId'
export const API_REPORT = '/report/:reportId'
export const API_REPORT_COUNT = '/report/?locationId=:locationId&count=true'
export const API_REPORT_RECENT = '/report/?locationId=:locationId&recent=true'
export const API_REPORT_TYPE = '/report/report-type/:reportTypeId'
export const API_REPORT_ENTRIES = '/report/:reportId/report-entries'
export const API_REPORT_ENTRY_TYPE = 'report/report-entry-type/:reportEntryTypeId'
export const API_REPORT_SEARCH = '/report/search'

export const makeReportRecordRoute = (reportId) => {
  return ROUTES_REPORTS_SINGLE.replace(':reportId', reportId)
}

export const makeReportRequestRoute = (reportId) => {
  return API_REPORT.replace(':reportId', reportId)
}

export const makeReportCountRequestRoute = (locationId) => {
  return API_REPORT_COUNT.replace(':locationId', locationId)
}

export const makeReportsRequestRoute = (locationId, { limit = 10, skip = 0 } = {}) => {
  const path = API_LOCATION_REPORTS.replace(':locationId', locationId)
  return `${path}&limit=${limit}&skip=${skip}`
}

export const makeReportsRecentRequestRoute = (locationId) => {
  return API_REPORT_RECENT.replace(':locationId', locationId)
}

export const makeReportTypeRequestRoute = (reportTypeId) => {
  return API_REPORT_TYPE.replace(':reportTypeId', reportTypeId)
}

export const makeReportEntriesRequestRoute = (reportId, { limit = 10, skip = 0 } = {}) => {
  const path = API_REPORT_ENTRIES.replace(':reportId', reportId)
  return `${path}?limit=${limit}&skip=${skip}`
}

export const makeReportEntryTypeRequestRoute = (reportEntryTypeId) => {
  return API_REPORT_ENTRY_TYPE.replace(':reportEntryTypeId', reportEntryTypeId)
}

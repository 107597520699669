import React from 'react'
import PropTypes from 'prop-types'
import useSWR from 'swr'

import { makeRecordRequestRoute } from '../shared/routePaths'

import FailedToLoadResourceMsg from 'components/FailedToLoadResourceMsg'

const RecordUniqueId = ({ recordId }) => {
  const { data: recordRecord, error } = useSWR(makeRecordRequestRoute(recordId))

  if (error) return <FailedToLoadResourceMsg />

  if (!recordRecord) return <span className="loading" />

  return <>{recordRecord.uniqueId}</>
}

RecordUniqueId.propTypes = {
  recordId: PropTypes.string.isRequired,
}

export default RecordUniqueId

import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import FAIcon from '@therms/web-common/components/Icon/FAIcon'

const DashboardItemHeader = ({ linkPath, subTitle, title }) => {
  return Boolean(linkPath) ? (
    <Link to={linkPath} className="non-styled-link parent-link-highlight-text parent-slide-right">
      <header className="bg-white flex flex-wrap items-center justify-between shadow">
        <div className="px-4 py-3 flex-grow-max">
          <span className="font-bold text-lg">{title}</span>{' '}
          <span className="text-base-800">{subTitle}</span>
        </div>

        <div className="child-link-highlight-text flex flex-grow items-center justify-center px-4 py-2">
          <span className="mx-1">View All</span>
          <FAIcon name="angle-right" className="child-slide-right mx-2" lg />
        </div>
      </header>
    </Link>
  ) : (
    <header className="bg-white flex flex-wrap items-center justify-between">
      <div className="px-4 py-3">
        <span className="font-bold text-lg">{title}</span>{' '}
        <span className="text-base-800">{subTitle}</span>
      </div>
    </header>
  )
}

DashboardItemHeader.propTypes = {
  linkPath: PropTypes.string,
  subTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
}

DashboardItemHeader.defaultProps = {
  linkPath: undefined,
  subTitle: undefined,
}

export default DashboardItemHeader

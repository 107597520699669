import { sortBy } from 'lodash-es'
import moment from 'moment'

const filterRecentDispatches = dispatchesList => {
  if (Array.isArray(dispatchesList)) {
    return dispatchesList.filter(dispatch => {
      return moment(dispatch.timestamp).isSameOrAfter(moment().subtract(7, 'd'))
    })
  }

  return []
}

export const sortRecentDispatches = dispatchesList => {
  if (Array.isArray(dispatchesList)) {
    const filteredDispatches = filterRecentDispatches(dispatchesList)
    return sortBy(filteredDispatches, new Date(['timestamp']))
  }

  return []
}

import React from 'react'
import PropTypes from 'prop-types'
import useSWR from 'swr'

import { makeReportRequestRoute } from './routePaths'

import FailedToLoadResourceMsg from 'components/FailedToLoadResourceMsg'

const ReportUniqueId = ({ reportId }) => {
  const { data: reportRecord, error } = useSWR(makeReportRequestRoute(reportId))

  if (error) return <FailedToLoadResourceMsg />

  if (!reportRecord) return <span className="loading" />

  return <>{reportRecord.uniqueId}</>
}

ReportUniqueId.propTypes = {
  reportId: PropTypes.string.isRequired,
}

export default ReportUniqueId

import React from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'

import FAIcon from '@therms/web-common/components/Icon/FAIcon'

const NavItem = ({ iconName, itemTitle, linkSlug }) => {
  return (
    <NavLink
      activeClassName="tab-active"
      to={linkSlug}
      className="flex hover:no-underline items-center no-underline p-2 pl-4 tab"
    >
      <div className="flex justify-center w-8">
        <FAIcon name={iconName} lg />
      </div>
      <span className="font-bold no-underline ml-3">{itemTitle}</span>
    </NavLink>
  )
}

NavItem.propTypes = {
  iconName: PropTypes.string,
  itemTitle: PropTypes.string.isRequired,
  linkSlug: PropTypes.string.isRequired,
}

NavItem.defaultProps = {
  iconName: '',
}

export default NavItem

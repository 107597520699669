import React from 'react'
import PropTypes from 'prop-types'

import TagScanReportEntry from 'modules/Tag/shared/Report/TagScanReportEntry'

const TagScan = ({ reportEntry }) => {
  const { tagId, tagScanId } = reportEntry.fields || {}

  return <TagScanReportEntry tagId={tagId} tagScanId={tagScanId} />
}

TagScan.propTypes = {
  reportEntry: PropTypes.object.isRequired,
}
TagScan.defaultProps = {}

export default TagScan

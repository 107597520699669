import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import useCurrentLocation from '../../Locations/shared/useCurrentLocation'

const useActivityTypes = id => {
  const currentLocation = useCurrentLocation()
  // activity types are different per organization, so, we pick activity types for the current location's organization
  const [currentOrganizationActivityTypes, setCurrentOrganizationActivityTypes] = useState([])
  const allActivityTypesById = useSelector(state => state.activityTypes.byId)

  useEffect(() => {
    if (currentLocation) {
      setCurrentOrganizationActivityTypes(
        Object.values(allActivityTypesById).filter(
          ({ organizationId }) => organizationId === currentLocation.organizationId,
        ),
      )
    }
  }, [allActivityTypesById, currentLocation])

  if (Boolean(id)) return allActivityTypesById[id]

  if (!currentLocation) return []

  return currentOrganizationActivityTypes
}

useActivityTypes.propTypes = {
  id: PropTypes.string,
}

useActivityTypes.defaultProps = {
  id: undefined,
}

export default useActivityTypes

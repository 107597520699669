import React from 'react'
import PropTypes from 'prop-types'

import ActivityTypeNames from '../../../../ActivityTypes/shared/ActivityTypeNames'
import Files from 'modules/Files/shared/Files'
import RecordThumbsContainer from 'modules/Records/shared/RecordThumbsContainer'

const EntriesStatement = ({ reportEntry }) => {
  const fields = reportEntry.fields || {}

  return (
    <div className="reportentry-container">
      {fields.activityTypeIds && (
        <div className="p-1">
          <ActivityTypeNames ids={fields.activityTypeIds} badge />
        </div>
      )}

      {fields.nameOfPersonProvidingStatment && (
        <div className="list-entry-light p-2">
          <strong>Name of Person Providing Statement</strong>

          <div>{fields.nameOfPersonProvidingStatment}</div>
        </div>
      )}

      {fields.nameOfPersonTakingStatment && (
        <div className="list-entry-light p-2">
          <strong>Name of Person Taking Statement</strong>

          <div>{fields.nameOfPersonTakingStatment}</div>
        </div>
      )}

      <div className="list-entry-light p-2">
        <strong>Statement</strong>

        <div className="with-whitespace">{fields.statement || <em>no statement...</em>}</div>
      </div>

      {fields.notes && (
        <div className="list-entry-light p-2">
          <strong>Notes</strong>

          <div className="with-whitespace">{fields.notes}</div>
        </div>
      )}

      {fields.recordIds && (
        <div>
          <div className="bg-dark-500 font-bold px-4 py-2 text-right text-base-200">
            Linked Records
          </div>
          <RecordThumbsContainer recordIds={fields.recordIds} />
        </div>
      )}

      <Files fileUrls={fields.files} />
    </div>
  )
}

EntriesStatement.propTypes = {
  reportEntry: PropTypes.object.isRequired,
}
EntriesStatement.defaultProps = {}

export default EntriesStatement

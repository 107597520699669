import React from 'react'
import PropTypes from 'prop-types'

import FAIcon from '@therms/web-common/components/Icon/FAIcon'

const TaskExpander = ({ executed, expanded, handleOnClick }) => {
  if (!executed)
    return (
      <div className="bg-faded-300 flex hide-on-print items-center justify-center select-none text-base-100 w-24">
        <FAIcon name="ellipsis-h" lg />
      </div>
    )

  return (
    <button
      className="bg-dark-700 cursor-pointer flex flex-column items-center justify-center tab text-base-100 w-24"
      onClick={handleOnClick}
    >
      <p className="font-bold">{`${expanded ? 'Hide' : 'Show'} Executions`}</p>
    </button>
  )
}

TaskExpander.propTypes = {
  executed: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
  handleOnClick: PropTypes.func,
}

TaskExpander.defaultProps = {
  handleOnClick: undefined,
}

export default TaskExpander

import React from 'react'
import PropTypes from 'prop-types'

import SelectActivityTypes from 'modules/ActivityTypes/shared/SelectActivityTypes'
import TextSearch from '@therms/web-common/components/Inputs/TextSearch'

const ReportsSearch = ({ activityTypeValue, onActivityTypeChange, onTextChange, textValue }) => {
  return (
    <div className="flex flex-grow-max flex-wrap p-1">
      <div className="child-mb-0 flex-grow m-1">
        <TextSearch value={textValue} onChange={onTextChange} />
      </div>

      <div className="child-mb-0 flex-grow m-1">
        <SelectActivityTypes onChange={onActivityTypeChange} value={activityTypeValue} />
      </div>
    </div>
  )
}

ReportsSearch.propTypes = {
  activityTypeValue: PropTypes.array,
  onActivityTypeChange: PropTypes.func,
  onTextChange: PropTypes.func,
  textValue: PropTypes.string,
}

ReportsSearch.defaultProps = {
  activityTypeValue: [],
  onActivityTypeChange: () => {},
  onTextChange: () => {},
  textValue: PropTypes.string,
}

export default ReportsSearch

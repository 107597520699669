import React from 'react'
import PropTypes from 'prop-types'

const PropertyFullFieldList = ({ fields }) => {
  const { brand, color, model, name, quantity, status, value } = fields

  return (
    <ul className="flex flex-column flex-grow mb-0 text-base-900">
      <li className="flex flex-grow items-center list-entry-light px-3 py-1 rounded-t">
        <span>
          <strong className="font-strong">Name:</strong> {name}
        </span>
      </li>

      <li className="flex flex-grow items-center list-entry-light px-3 py-1">
        <span>
          <strong className="font-strong">Brand:</strong> {brand}
        </span>
      </li>

      <li className="flex flex-grow items-center list-entry-light px-3 py-1">
        <span>
          <strong className="font-strong">Model:</strong> {model}
        </span>
      </li>

      <li className="flex flex-grow items-center list-entry-light px-3 py-1">
        <span>
          <strong className="font-strong">Color:</strong> {color}
        </span>
      </li>

      <li className="flex flex-grow items-center list-entry-light px-3 py-1">
        <span>
          <strong className="font-strong">Quantity:</strong> {quantity || 'N/A'}
        </span>
      </li>

      <li className="flex flex-grow items-center list-entry-light px-3 py-1">
        <span>
          <strong className="font-strong">Value:</strong> {value}
        </span>
      </li>

      <li className="flex flex-grow items-center list-entry-light px-3 py-1 rounded-b">
        <span>
          <strong className="font-strong">Status:</strong> {status}
        </span>
      </li>
    </ul>
  )
}

PropertyFullFieldList.propTypes = {
  fields: PropTypes.shape({
    brand: PropTypes.string,
    color: PropTypes.string,
    model: PropTypes.string,
    name: PropTypes.string,
    quantity: PropTypes.number,
    status: PropTypes.string,
    value: PropTypes.string,
  }),
}

PropertyFullFieldList.defaultProps = {
  fields: PropTypes.shape({
    brand: 'N/A',
    color: 'N/A',
    model: 'N/A',
    name: 'N/A',
    quantity: undefined,
    status: 'N/A',
    value: 'N/A',
  }),
}

export default PropertyFullFieldList

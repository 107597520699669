import React from 'react'
import PropTypes from 'prop-types'

import RenderFormBuilderDisplay from "./components/RenderDisplay"

const EntriesCustomReportEntryType = ({ reportEntry, reportEntryType }) => {
  return (
    <div className="reportentry-container">
      <RenderFormBuilderDisplay
        displayLayout={reportEntryType.formBuilder.displayLayout}
        formValues={reportEntry.fields}
        fieldsById={reportEntryType.formBuilder.fieldsById}
        reportEntryType={reportEntryType}
        uniqueFormId={reportEntry.id}
      />
    </div>
  )
}

EntriesCustomReportEntryType.propTypes = {
  reportEntry: PropTypes.object.isRequired,
  reportEntryType: PropTypes.object.isRequired,
}
EntriesCustomReportEntryType.defaultProps = {}

export default EntriesCustomReportEntryType

import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import useSWR from 'swr'

import EmptySectionSign from 'components/EmptySectionSign'
import DashboardItemHeader from 'modules/Dashboard/shared/DashboardItemHeader'
import DispatchThumb from 'modules/Dispatch/components/DispatchThumb'
import FailedToLoadResourceMsg from 'components/FailedToLoadResourceMsg'
import Loading from '@therms/web-common/components/Loading'
import pluralCheck from 'utils/pluralCheck'
import { selectCurrentLocationId } from 'state/ducks/locations'
import { sortRecentDispatches } from '../../../utils/sortDispatches'

import { makeDispatchesRecentRequestRoute, ROUTES_DISPATCH_ROOT } from '../../routePaths'

const DashboardItemDispatch = () => {
  const currentLocationId = useSelector(selectCurrentLocationId)
  const { data: dispatchRecords, error } = useSWR(makeDispatchesRecentRequestRoute(currentLocationId))

  const sortedDispatches = useMemo(() => sortRecentDispatches(dispatchRecords), [dispatchRecords])

  if (error?.response?.status === 403) {
    return (
      <div className="bg-light-400 flex flex-grow p-3 shadow">
        <EmptySectionSign iconName="phone-square" name="recent Dispatches" />
      </div>
    )
  }

  if (error) return <FailedToLoadResourceMsg />

  if (dispatchRecords === undefined)
    return (
      <section className="bg-light-400 flex flex-grow p-3 shadow">
        <Loading />
      </section>
    )

  if (!sortedDispatches.length)
    return (
      <section className="bg-light-400 flex flex-grow p-3 shadow">
        <EmptySectionSign iconName="phone-square" name="recent Dispatches" />
      </section>
    )

  const headerSubTitle = `last 7 days: ${sortedDispatches.length} ${pluralCheck(
    sortedDispatches.length,
    'dispatch',
    'dispatches',
  )}`

  return (
    <section className="flex flex-column flex-grow shadow">
      <DashboardItemHeader
        linkPath={ROUTES_DISPATCH_ROOT}
        subTitle={headerSubTitle}
        title="Dispatch"
      />

      <div className="bg-light-400 flex-grow overflow-auto">
        {sortedDispatches.map(dispatchRecord => (
          <div className="mb-2" key={dispatchRecord.uniqueId}>
            <DispatchThumb dispatchRecord={dispatchRecord} />
          </div>
        ))}
      </div>
    </section>
  )
}

export default DashboardItemDispatch

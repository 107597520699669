import React from 'react'
import { Link } from 'react-router-dom'

import Img from 'components/ImageLoader'
import { ROUTES_DASHBOARD_ROOT } from 'modules/Dashboard/shared/routePaths'
import SiteBreadcrumbs from '../SiteBreadcrumbs'

import logo from 'images/wide-logo-black.png'
import './Header.scss'

const Header = () => (
  <div className="layout-header-container flex justify-between px-6 w-full">
    <div className="flex font-bold h-full items-center p-2 text-center text-xl">
      <SiteBreadcrumbs />
    </div>

    <div className="flex items-center">
      <Link to={ROUTES_DASHBOARD_ROOT}>
        <Img alt="therms" className="logo" src={logo} />
      </Link>
    </div>
  </div>
)

export default Header

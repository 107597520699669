import React from 'react'
import PropTypes from 'prop-types'
import useSWR from 'swr'

import FailedToLoadResourceMsg from 'components/FailedToLoadResourceMsg'
import Files from 'modules/Files/shared/Files'
import Loading from '@therms/web-common/components/Loading'
import { makeSingleTaskRequestRoute } from './routePaths'
import RibbonField from 'components/RibbonField'
import TaskExecution from '../components/TaskExecution'

const TaskActionOutline = ({ taskExecutionId, taskId }) => {
  const { data: task, error } = useSWR(makeSingleTaskRequestRoute(taskId))

  if (error) return <FailedToLoadResourceMsg />

  if (!task) return <Loading />

  const { body, title } = task

  return (
    <div>
      <div className="flex flex-wrap w-full">
        <RibbonField name="Title" value={title} />
        {Boolean(body) && <RibbonField name="Description" value={body} />}
      </div>

      <div className="bg-light-100 flex">
        <div className="flex-grow text-center">
          <TaskExecution id={taskExecutionId} />
        </div>
      </div>

      <Files fileUrls={task.files} />      
    </div>
  )
}

TaskActionOutline.propTypes = {
  taskExecutionId: PropTypes.string.isRequired,
  taskId: PropTypes.string.isRequired,
}

export default TaskActionOutline

import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import BOLOs from 'modules/BOLOs'
import Dashboard from 'modules/Dashboard'
import Dispatches from 'modules/Dispatch'
import Locations from 'modules/Locations'
import Mail from 'modules/Mail'
import Reports from 'modules/Reports/index.jsx'
import Settings from 'modules/Settings'
import Tasks from 'modules/Tasks'
import Records from 'modules/Records'

import { ROUTES_BOLOS_ROOT } from 'modules/BOLOs/shared/routePaths'
import { ROUTES_DASHBOARD_ROOT } from 'modules/Dashboard/shared/routePaths'
import { ROUTES_DISPATCH_ROOT } from 'modules/Dispatch/shared/routePaths'
import { ROUTES_LOCATIONS_ROOT } from 'modules/Locations/shared/routePaths'
import { ROUTES_MAIL_ROOT } from 'modules/Mail/shared/routePaths'
import { ROUTES_REPORTS_ROOT } from 'modules/Reports/shared/routePaths'
import { ROUTES_SETTINGS_ROOT } from 'modules/Settings/shared/routePaths'
import { ROUTES_TASKS_ROOT } from 'modules/Tasks/shared/routePaths'
import { ROUTES_RECORDS_SINGLE } from 'modules/Records/shared/routePaths'

import 'styles/index.scss'

export default () => (
  <Switch>
    <Route path={ROUTES_DASHBOARD_ROOT} exact component={Dashboard} />
    <Route path={ROUTES_LOCATIONS_ROOT} component={Locations} />
    <Route path={ROUTES_REPORTS_ROOT} component={Reports} />
    <Route path={ROUTES_BOLOS_ROOT} component={BOLOs} />
    <Route path={ROUTES_DISPATCH_ROOT} component={Dispatches} />
    <Route path={ROUTES_TASKS_ROOT} component={Tasks} />
    <Route path={ROUTES_MAIL_ROOT} component={Mail} />
    <Route path={ROUTES_SETTINGS_ROOT} component={Settings} />
    <Route path={ROUTES_RECORDS_SINGLE} component={Records} />
    <Redirect from="/" to={ROUTES_DASHBOARD_ROOT} />
  </Switch>
)

import React from 'react'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'

import Files from 'modules/Files/shared/Files'
import FailedToLoadResourceMsg from 'components/FailedToLoadResourceMsg'
import Loading from '@therms/web-common/components/Loading'
import RecordPerson from './screens/RecordPerson'
import RecordProperty from './screens/RecordProperty'
import RecordVehicle from './screens/RecordVehicle'
import { makeRecordRequestRoute } from './shared/routePaths'

const Record = () => {
  const { recordId } = useParams()

  const { data: recordRecord, error } = useSWR(makeRecordRequestRoute(recordId))

  if (error) return <FailedToLoadResourceMsg />

  if (!recordRecord) return <Loading />

  let RecordComponent

  switch (recordRecord.type) {
    case 'person':
      RecordComponent = <RecordPerson />
      break
    case 'property':
      RecordComponent = <RecordProperty />
      break
    case 'vehicle':
      RecordComponent = <RecordVehicle />
      break
    default:
      RecordComponent = null
  }

  return (
    <div>
      <div className="mb-3">{RecordComponent}</div>

      <Files fileUrls={recordRecord.files} />
    </div>
  )
}

export default Record

import React from 'react'
// import PropTypes from 'prop-types'

import LabeledTextInput from '../components/LabeledTextInput'
import Textarea from '@therms/web-common/components/Inputs/Textarea'
import FAIcon from '@therms/web-common/components/Icon/FAIcon'

import './NewMail.scss'

const NewMail = () => {
  return (
    <form action="" method="post" className="new-mail-form bg-light-100">
      <div className="p-4">
        <LabeledTextInput name="user" placeholder="Patrolman, Bob" />
        <LabeledTextInput
          name="subject"
          placeholder="Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta delectus"
        />

        <Textarea name="body" placeholder="Write your message here..." rows="12" />
      </div>
      <div className="bg-dark-500 flex items-center justify-end text-gray-100">
        <button
          id="send-new-mail"
          className="flex font-bold font items-center py-3 px-5 text-xl tracking-widest uppercase"
        >
          <span className="mr-4">SEND</span> <FAIcon name="angle-right" lg />
        </button>
      </div>
    </form>
  )
}

NewMail.propTypes = {}

export default NewMail

import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'

import Tasks from './screens/Tasks'
import Task from './screens/Task'

import EmptySectionSign from 'components/EmptySectionSign'
import RedirectOnLocationChange from 'modules/Locations/shared/RedirectOnLocationChange'
import TasksModuleConditional from './shared/TasksModuleConditional'

import { ROUTES_TASKS_ROOT, ROUTES_TASKS_SINGLE } from './shared/routePaths'

const TaskRoutes = () => (
  <TasksModuleConditional
    fallbackComponent={<EmptySectionSign forbidden iconName="tasks" name="Tasks" />}
  >
    <Switch>
      <Route exact path={ROUTES_TASKS_ROOT} component={Tasks} />
      <Route path={ROUTES_TASKS_SINGLE} component={RedirectOnLocationChange(Task)} />
    </Switch>
  </TasksModuleConditional>
)

export default withRouter(TaskRoutes)

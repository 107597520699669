import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import NavItem from 'components/NavItem'
import Logout from 'modules/Auth/shared/Logout'

import { currentLocationModulesSelector } from 'state/ducks/auth'

import { ROUTES_BOLOS_ROOT } from 'modules/BOLOs/shared/routePaths'
import { ROUTES_DASHBOARD_ROOT } from 'modules/Dashboard/shared/routePaths'
import { ROUTES_DISPATCH_ROOT } from 'modules/Dispatch/shared/routePaths'
import { ROUTES_REPORTS_ROOT } from 'modules/Reports/shared/routePaths'
import { ROUTES_TASKS_ROOT } from 'modules/Tasks/shared/routePaths'
// import { ROUTES_MAIL_ROOT } from 'modules/Mail/shared/routePaths'
// import { ROUTES_SETTINGS_ROOT } from 'modules/Settings/shared/routePaths'

import './NavMobileMenu.scss'

const NavMobileMenu = ({ setIsOpen }) => {
  const currentLocationModules = useSelector(currentLocationModulesSelector)
  const thisElRef = React.createRef()

  const { canViewBolo, canViewDispatch, canViewReports, canViewTasks } = currentLocationModules

  const onClickOutsideHandler = event => {
    const isClickInside = thisElRef.current.contains(event.target)
    if (!isClickInside) setIsOpen(false)
  }

  useEffect(() => {
    document.addEventListener('click', onClickOutsideHandler)
    return () => document.removeEventListener('click', onClickOutsideHandler)
  }, [])

  return (
    <div className="bg-white nav-mobile-menu py-2 shadow-inner-bottom w-full" ref={thisElRef}>
      <div className="flex-grow overflow-y-auto shadow-inner-bottom">
        <div onClick={() => setIsOpen(false)}>
          <NavItem iconName="columns" itemTitle="Dashboard" linkSlug={ROUTES_DASHBOARD_ROOT} />
        </div>

        {canViewBolo && (
          <div onClick={() => setIsOpen(false)}>
            <NavItem iconName="exclamation-circle" itemTitle="BOLO" linkSlug={ROUTES_BOLOS_ROOT} />
          </div>
        )}

        {canViewDispatch && (
          <div onClick={() => setIsOpen(false)}>
            <NavItem iconName="phone-square" itemTitle="Dispatch" linkSlug={ROUTES_DISPATCH_ROOT} />
          </div>
        )}

        {canViewReports && (
          <div onClick={() => setIsOpen(false)}>
            <NavItem iconName="file-alt" itemTitle="Reports" linkSlug={ROUTES_REPORTS_ROOT} />
          </div>
        )}

        {canViewTasks && (
          <div onClick={() => setIsOpen(false)}>
            <NavItem iconName="tasks" itemTitle="Tasks" linkSlug={ROUTES_TASKS_ROOT} />
          </div>
        )}

        {/* <div onClick={() => setIsOpen(false)}> */}
        {/*  <NavItem iconName="envelope" itemTitle="Mail" linkSlug={ROUTES_MAIL_ROOT} /> */}
        {/* </div> */}
      </div>

      <div className="pt-3">
        {/* <div onClick={() => setIsOpen(false)}> */}
        {/*  <NavItem iconName="cog" itemTitle="Settings" linkSlug={ROUTES_SETTINGS_ROOT} /> */}
        {/* </div> */}
        <Logout />
      </div>
    </div>
  )
}

NavMobileMenu.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
}

export default NavMobileMenu

import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash-es'

import inputsByType from './InputFields'

function RenderFormBuilderDisplay({ displayLayout, fieldsById, formValues, uniqueFormId }) {
  return (
    <div className="bg-light-100 p-2">
      {displayLayout.map(row => (
        <div className="row" key={row.rowId} style={{ justifyContent: row.justifyContent }}>
          {row.fields.map(col => {
            const DisplayField = inputsByType[fieldsById[col.fieldId].type]

            if (!DisplayField) return null

            if (get(fieldsById, `${col.fieldId}.options.displayOrganizationOnly`)) return null

            return (
              <div className={`col-${col.colWidth}`} key={col.fieldId}>
                <div>
                  <DisplayField
                    field={fieldsById[col.fieldId]}
                    formValue={formValues[col.fieldId]}
                    formValues={formValues}
                    options={fieldsById[col.fieldId].options}
                    uniqueFormId={uniqueFormId}
                  />
                </div>
              </div>
            )
          })}
        </div>
      ))}
    </div>
  )
}

RenderFormBuilderDisplay.propTypes = {
  displayLayout: PropTypes.array.isRequired,
  fieldsById: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  uniqueFormId: PropTypes.string.isRequired,
}

export default RenderFormBuilderDisplay

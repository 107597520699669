import React from 'react'
import { useSelector } from 'react-redux'
import useSWR from 'swr'

import DashboardItemHeader from 'modules/Dashboard/shared/DashboardItemHeader'
import EmptySectionSign from 'components/EmptySectionSign'
import FailedToLoadResourceMsg from 'components/FailedToLoadResourceMsg'
import Loading from '@therms/web-common/components/Loading'
import pluralCheck from 'utils/pluralCheck'
import ReportThumb from 'modules/Reports/components/ReportThumb'
import { selectCurrentLocationId } from 'state/ducks/locations'

import { makeReportsRecentRequestRoute, ROUTES_REPORTS_ROOT } from '../../routePaths'

const DashboardItemReport = () => {
  const currentLocationId = useSelector(selectCurrentLocationId)
  const { data: recentReportRecords, error } = useSWR(makeReportsRecentRequestRoute(currentLocationId))

  if (error?.response?.status === 403) {
    return (
      <div className="bg-light-400 flex flex-grow p-3 shadow">
        <EmptySectionSign forbidden iconName="file-alt" name="recent Reports" />
      </div>
    )
  }

  if (error) return <FailedToLoadResourceMsg />

  if (recentReportRecords === undefined)
    return (
      <section className="bg-light-400 flex flex-grow p-3 shadow">
        <Loading />
      </section>
    )

  if (!recentReportRecords.length)
    return (
      <section className="bg-light-400 flex flex-grow p-3 shadow">
        <EmptySectionSign iconName="file-alt" name="recent Reports" />
      </section>
    )

  const headerSubTitle = `last 7 days: ${recentReportRecords.length} ${pluralCheck(
    recentReportRecords.length,
    'report',
    'reports',
  )}`

  return (
    <section className="flex flex-column flex-grow shadow">
      <DashboardItemHeader
        linkPath={ROUTES_REPORTS_ROOT}
        subTitle={headerSubTitle}
        title="Reports"
      />

      <div className="bg-light-400 flex-grow overflow-auto">
        {recentReportRecords.map((reportRecord) => (
          <div key={reportRecord.id}>
            <ReportThumb reportRecord={reportRecord} />
          </div>
        ))}
      </div>
    </section>
  )
}

export default DashboardItemReport

import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import useSWR from 'swr'

import FailedToLoadResourceMsg from 'components/FailedToLoadResourceMsg'

const UserName = ({ userId, isCurrentUser }) => {
  const myFirstName = useSelector(state => state.auth.portalUser.firstName)
  const myLastName = useSelector(state => state.auth.portalUser.lastName)

  if (isCurrentUser)
    return (
      <span>
        {myFirstName} {myLastName}
      </span>
    )

  const { data: user, error } = useSWR(`/user/${userId}`)

  if (error) return <FailedToLoadResourceMsg />

  if (!user) return <span className="loading">...</span>

  return (
    <span className="list-name">
      {user.firstName} {user.lastName}
    </span>
  )
}

UserName.propTypes = {
  isCurrentUser: PropTypes.bool,
  userId: PropTypes.string,
}

UserName.defaultProps = {
  isCurrentUser: false,
  userId: '',
}

export default UserName

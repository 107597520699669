import ActivityType from './ActivityType'
import Checkbox from './Checkbox'
import DateTime from './DateTime'
import Files from './Files'
import Radio from './Radio'
import Record from './Record'
import Select from './Select'
import Text from './Text'
import TextArea from './TextArea'

export default {
  activitytype: ActivityType,
  checkbox: Checkbox,
  datetime: DateTime,
  files: Files,
  radio: Radio,
  record: Record,
  select: Select,
  text: Text,
  textarea: TextArea,
}

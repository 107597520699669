import { find } from 'lodash-es'
import { createAction, handleActions } from 'redux-actions'
import { createSelector } from 'reselect'

import cookie from 'js-cookie'
import { handleAuthFailed } from '../../services/auth'
import portalApi from '../../services/portalApi'
import {
  ROUTES_SESSION_MODULES_REQUEST,
  ROUTES_SESSION_REQUEST,
} from 'modules/Auth/shared/routePaths'
import { selectCurrentLocationId } from './locations'

const initialState = {
  /*
  aggregatedModuleAccess schema:
    {
      dispatch: bool,
      locationBolos: bool,
      reports: bool,
      tasks: bool,
    }
  */
  aggregatedModuleAccess: undefined,
  authenticated: false,
  authToken: undefined,
  portalUser: undefined,
}

// selectors
const selectAccessList = state => state.auth.portalUser.accessList

export const currentLocationModulesSelector = createSelector(
  [selectCurrentLocationId, selectAccessList],
  (locationId, accessList) => {
    return find(accessList, { locationId }).permissions
  },
)

// actions
const setAggregatedModuleAccess = createAction('setAggregatedModuleAccess')
const setAuthenticated = createAction('setAuthenticated')
const setAuthToken = createAction('setAuthToken')
const setPortalUser = createAction('setPortalUser')

export const fetchAndSetAuth = () => async dispatch => {
  const authToken = cookie.get(process.env.AUTH_COOKIE_NAME)

  if (!authToken) return handleAuthFailed()

  dispatch(setAuthToken(authToken))

  try {
    const response = await portalApi.get(ROUTES_SESSION_REQUEST)
    const portalUser = response?.data?.portalUser

    if (!portalUser || response.status !== 200) throw new Error('Authentication Failed')

    dispatch(setPortalUser(portalUser))
    dispatch(setAuthenticated(true))
  } catch (e) {
    console.warn(`fetchAndSetAuth() GET ${ROUTES_SESSION_REQUEST} failed`)

    dispatch(setAuthenticated(false))
    return handleAuthFailed()
  }

  return true
}

export const fetchAndSetAggregatedModuleAccess = () => async dispatch => {
  try {
    const response = await portalApi.get(ROUTES_SESSION_MODULES_REQUEST)
    const aggregatedModuleAccess = response?.data

    if (!aggregatedModuleAccess || response.status !== 200)
      throw new Error('Access to modules request failed')

    dispatch(setAggregatedModuleAccess(aggregatedModuleAccess))
  } catch (e) {
    console.warn(`fetchAndSetAggregatedModuleAccess() GET ${ROUTES_SESSION_MODULES_REQUEST} failed`)
    return handleAuthFailed()
  }
  return true
}

export default handleActions(
  {
    [setAggregatedModuleAccess]: (state, action) => ({
      ...state,
      aggregatedModuleAccess: action.payload,
    }),

    [setAuthenticated]: (state, action) => ({
      ...state,
      authenticated: action.payload,
    }),

    [setAuthToken]: (state, action) => ({
      ...state,
      authToken: action.payload,
    }),

    [setPortalUser]: (state, action) => ({
      ...state,
      portalUser: action.payload,
    }),
  },
  initialState,
)

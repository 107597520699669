import React from 'react'
import PropTypes from 'prop-types'
import useSWR from 'swr'

import FailedToLoadResourceMsg from 'components/FailedToLoadResourceMsg'
import { makeSingleLocationRequestRoute } from './routePaths'

const LocationName = ({ locationId }) => {
  const { data: location, error } = useSWR(makeSingleLocationRequestRoute(locationId))

  if (error) return <FailedToLoadResourceMsg />

  if (!location) return <span className="loading" />

  return <span>{location.name}</span>
}

LocationName.propTypes = {
  locationId: PropTypes.string.isRequired,
}

export default LocationName

import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import useSWR from 'swr'

import FailedToLoadResourceMsg from 'components/FailedToLoadResourceMsg'
import EmptySectionSign from 'components/EmptySectionSign'
import { makeTaskCountRequestRoute, makeTasksRequestRoute } from '../shared/routePaths'
import TaskSummary from '../TaskSummary'
import Loading from '@therms/web-common/components/Loading'
import PageNavigator from 'components/PageNavigator'
import { sortTasksByExecution } from '../utils/sortTasks'

const Tasks = () => {
  const currentLocationId = useSelector(state => state.locations.currentId)

  const [currentPage, setCurrentPage] = useState(0)
  const pageLimit = 10

  const { data: taskRecords, error } = useSWR(
    makeTasksRequestRoute(currentLocationId, { limit: pageLimit, skip: currentPage * pageLimit }),
  )

  const sortedTasks = useMemo(() => sortTasksByExecution(taskRecords), [taskRecords])

  const { data: taskCount, taskCountError } = useSWR(makeTaskCountRequestRoute(currentLocationId))

  if (error || taskCountError) return <FailedToLoadResourceMsg />

  if (taskRecords === undefined)
    return (
      <div className="relative h-full">
        <div className="bg-dark-900 flex justify-end shadow sticky top-0 z-index-sticky">
          {taskCount?.length > pageLimit && (
            <PageNavigator
              currentPage={currentPage}
              limit={pageLimit}
              setCurrentPage={setCurrentPage}
              totalAmount={taskCount}
            />
          )}
        </div>

        <Loading />
      </div>
    )

  if (!taskRecords.length) return <EmptySectionSign iconName="tasks" name="Tasks" />

  return (
    <div className="relative">
      <div className="bg-dark-900 flex justify-end shadow sticky top-0 z-index-sticky">
        <PageNavigator
          currentPage={currentPage}
          limit={pageLimit}
          setCurrentPage={setCurrentPage}
          totalAmount={taskCount}
        />
      </div>

      {sortedTasks.map(task => (
        <div className="list-element-wrapper" key={task.id}>
          <TaskSummary id={task.id} />
        </div>
      ))}
    </div>
  )
}

export default Tasks

import React from 'react'
import PropTypes from 'prop-types'

const DispatchEntry = ({ children }) => {
  if (!children) return null

  return (
    <div className="list-entry-light px-4 py-3">
      <div className="content-entry mx-auto">{children}</div>
    </div>
  )
}

DispatchEntry.propTypes = {
  children: PropTypes.node,
}

DispatchEntry.defaultProps = {
  children: '',
}

export default DispatchEntry

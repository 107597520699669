import React from 'react'
import PropTypes from 'prop-types'
import useSWR from 'swr'

import UserName from 'modules/User/shared/UserName'
import DateTime from '@therms/web-common/components/DateTime'
import Files from 'modules/Files/shared/Files'


import { makeSingleTagRequestRoute, makeSingleTagScanRequestRoute } from '../routePaths'

const TagScanReportEntry = ({ tagId, tagScanId }) => {
  const { data: tagScan } = useSWR(makeSingleTagScanRequestRoute(tagScanId))
  const { data: tag } = useSWR(makeSingleTagRequestRoute(tagId))

  if (!tagScan) return null

  return (
    <div className="bg-white">
      <div className="flex flex-wrap p-2">
        <div className="mr-3">
          <span className="font-bold">Name </span>
          {!tag ? <span className="loading" /> : tag.name}
        </div>

        <div className="mr-1">
          <span className="font-bold">Scanned by </span>
          <UserName userId={tagScan.userId} />
        </div>

        <div>
          <span className="font-bold">at </span>
          <DateTime timestamp={tagScan.scanTime} />
        </div>
      </div>

      {!!tagScan?.note && <div className="p-2">{tagScan.note}</div>}

      <Files fileUrls={tagScan.files} />
    </div>
  )
}

TagScanReportEntry.propTypes = {
  tagId: PropTypes.string.isRequired,
  tagScanId: PropTypes.string.isRequired,
}

TagScanReportEntry.defaultProps = {}

export default TagScanReportEntry

import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'

import Report from './screens/Report'
import Reports from './screens/Reports'

import EmptySectionSign from 'components/EmptySectionSign'
import ReportsModuleConditional from './shared/ReportsModuleConditional'
import RedirectOnLocationChange from 'modules/Locations/shared/RedirectOnLocationChange'

import { ROUTES_REPORTS_ROOT, ROUTES_REPORTS_SINGLE } from './shared/routePaths'

const ReportsRoutes = () => (
  <ReportsModuleConditional
    fallbackComponent={<EmptySectionSign forbidden iconName="file-alt" name="Reports" />}
  >
    <Switch>
      <Route exact path={ROUTES_REPORTS_ROOT} component={Reports} />
      <Route path={ROUTES_REPORTS_SINGLE} component={RedirectOnLocationChange(Report)} />
    </Switch>
  </ReportsModuleConditional>
)

export default withRouter(ReportsRoutes)

import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import useSWR from 'swr'

import FAIcon from '@therms/web-common/components/Icon/FAIcon'
import FailedToLoadResourceMsg from 'components/FailedToLoadResourceMsg'
import Loading from '@therms/web-common/components/Loading'

import { makeReportRecordRoute, makeReportRequestRoute } from '../shared/routePaths'

const ReportHeader = ({ isLink, reportId }) => {
  const { data: reportRecord, error } = useSWR(makeReportRequestRoute(reportId))

  if (error) return <FailedToLoadResourceMsg />

  if (!reportRecord) return <Loading />

  const { uniqueId } = reportRecord

  if (isLink)
    return (
      <Link
        to={makeReportRecordRoute(reportId)}
        className="non-styled-link parent-link-highlight-text parent-slide-right"
      >
        <header className="bg-white flex justify-between p-2">
          <span className="font-bold flex items-center px-3">{uniqueId}</span>

          <div className="child-link-highlight-text pr-4 px-3">
            <FAIcon name="angle-right" className="child-slide-right hide-on-print" lg />
          </div>
        </header>
      </Link>
    )

  return (
    <header className="bg-white p-2">
      <span className="font-bold flex items-center px-3">{uniqueId}</span>
    </header>
  )
}

ReportHeader.propTypes = {
  isLink: PropTypes.bool,
  reportId: PropTypes.string.isRequired,
}

ReportHeader.defaultProps = {
  isLink: false,
}

export default ReportHeader

import React from 'react'
import PropTypes from 'prop-types'

const MailHeader = ({ children }) => {
  return (
    <thead className="mb-4">
      <tr className="font-bold text-xl tracking-widest">{children}</tr>
    </thead>
  )
}

MailHeader.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MailHeader

import React from 'react'
import PropTypes from 'prop-types'

import DateTime from '@therms/web-common/components/DateTime'

const RenderDisplayDateTime = ({ options, formValue }) => {
  if (!formValue) return null

  const dateMode = options.mode === 'datetime' || options.mode === 'date'
  const timeMode = options.mode === 'datetime' || options.mode === 'time'

  return (
    <>
      {options.displayLabel && (
        <div className="font-weight-bold">{options.displayLabel}</div>
      )}
      <DateTime date={dateMode} time={timeMode} timestamp={formValue} />
    </>
  )
}

RenderDisplayDateTime.propTypes = {
  formValue: PropTypes.any,
  options: PropTypes.object.isRequired,
}
RenderDisplayDateTime.defaultProps = {
  formValue: undefined,
}

export default RenderDisplayDateTime

import Debug from 'debug'

const debug = Debug('ws-portal')

const LOGGING_ON = process.env.NODE_ENV !== 'production'

if (LOGGING_ON) localStorage.debug = 'ws-portal'


/**
 * Only log if there there is a msg passed in the second argument
 *
 * @param componentName {string}
 * @param props {any}
 */
export const debugOnError = (componentName, ...props) => {
  if (LOGGING_ON && !props.filter(x=>x).length) return

  debug(`${componentName}: `, ...props)
}

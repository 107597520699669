import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { currentLocationModulesSelector } from 'state/ducks/auth'

const TasksModuleConditional = ({ children, fallbackComponent }) => {
  const hasPermissions = (useSelector(currentLocationModulesSelector) || {}).canViewTasks

  if (hasPermissions) return <>{children}</>

  if (fallbackComponent) return fallbackComponent

  return null
}

TasksModuleConditional.propTypes = {
  children: PropTypes.element.isRequired,
  fallbackComponent: PropTypes.element,
}

TasksModuleConditional.defaultProps = {
  fallbackComponent: undefined,
}

export default TasksModuleConditional

import React from 'react'
import PropTypes from 'prop-types'

import Files from 'modules/Files/shared/Files'

const RenderDisplayFiles = ({ options, formValue }) => {
  return (
    <>
      {options.displayLabel && <div className="font-weight-bold">{options.displayLabel}</div>}

      <Files fileUrls={formValue} />
    </>
  )
}

RenderDisplayFiles.propTypes = {
  formValue: PropTypes.array,
  options: PropTypes.object.isRequired,
}

RenderDisplayFiles.defaultProps = {
  formValue: [],
}

export default RenderDisplayFiles

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import FAIcon from '@therms/web-common/components/Icon/FAIcon'

const EmptySection = ({ forbidden, iconName, isWide, name }) => {
  const notice = forbidden ? `You don't have access to ${name}.` : `There are no ${name}.`

  const instruction = forbidden
    ? `Please contact the organization responsible for providing access to this location ${name}.`
    : `${name} for this location will appear here once created.`

  return (
    <div
      className={classnames(
        { 'flex-column text-center': Boolean(!isWide) },
        'flex flex-grow font-bold items-center h-full justify-center p-4 select-none text-base-700',
      )}
    >
      {Boolean(iconName) && (
        <div
          className={classnames({ 'mb-2': Boolean(!isWide), 'mr-6': Boolean(isWide) }, 'text-6xl')}
        >
          <FAIcon name={iconName} lg />
        </div>
      )}

      <div className="max-w-sm">
        <p className="my-0 text-2xl">{notice}</p>
        <p className="first-uppercase">{instruction}</p>
      </div>
    </div>
  )
}

EmptySection.propTypes = {
  forbidden: PropTypes.bool,
  iconName: PropTypes.string,
  isWide: PropTypes.bool,
  name: PropTypes.string,
}

EmptySection.defaultProps = {
  forbidden: false,
  iconName: undefined,
  isWide: false,
  name: undefined,
}

export default EmptySection

import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'

import Dispatch from './screens/Dispatch'
import Dispatches from './screens/Dispatches'

import EmptySectionSign from 'components/EmptySectionSign'
import DispatchModuleConditional from './shared/DispatchModuleConditional'
import RedirectOnLocationChange from 'modules/Locations/shared/RedirectOnLocationChange'

import { ROUTES_DISPATCH_ROOT, ROUTES_DISPATCH_SINGLE } from './shared/routePaths'

const DispatchRoutes = () => (
  <DispatchModuleConditional
    fallbackComponent={<EmptySectionSign forbidden iconName="phone-square" name="Dispatches" />}
  >
    <Switch>
      <Route exact path={ROUTES_DISPATCH_ROOT} component={Dispatches} />
      <Route path={ROUTES_DISPATCH_SINGLE} component={RedirectOnLocationChange(Dispatch)} />
    </Switch>
  </DispatchModuleConditional>
)

export default withRouter(DispatchRoutes)

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentLocation } from 'state/ducks/locations'

import FAIcon from '@therms/web-common/components/Icon/FAIcon'
import LocationCard from '../shared/LocationCard'

import './Locations.scss'

const LocationsList = () => {
  const dispatch = useDispatch()

  const locations = useSelector(state => state.locations.list)

  const pickLocation = location => dispatch(setCurrentLocation(location))

  if (!locations.length)
    return (
      <div className="flex-column text-center flex flex-grow font-bold items-center h-full justify-center p-4 select-none text-base-700">
        <div className="text-center">
          <div className="mb-2 text-6xl">
            <FAIcon name="building" lg />
          </div>

          <p className="my-0 text-2xl">You don't have access to any Locations</p>

          <p className="first-uppercase">
            Please contact the organization responsible for providing access to the Location(s) you
            wish to access
          </p>
        </div>
      </div>
    )

  return (
    <div className="locations-grid">
      {locations.map(location => (
        <div key={location.id} onClick={() => pickLocation(location)}>
          <LocationCard location={location} />
        </div>
      ))}
    </div>
  )
}

LocationsList.propTypes = {}
LocationsList.defaultProps = {}

export default LocationsList

import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import useSWR from 'swr'

import Img from 'components/ImageLoader'
import FailedToLoadResourceMsg from 'components/FailedToLoadResourceMsg'
import Loading from '@therms/web-common/components/Loading'
import Modal from '@therms/web-common/components/Modal'
import PersonFullFieldList from './PersonFullFieldList'
import PropertyFullFieldList from './PropertyFullFieldList'
import VehicleFullFieldList from './VehicleFullFieldList'

import { getImageSmallUrl } from 'modules/Files/utils/imageSizeUrls'
import getSeverityBgClass from 'utils/Severity/getSeverityBgClass'
import { makeRecordRoute, makeRecordRequestRoute } from '../shared/routePaths'
import placeholderImg from '../../../images/missing_record_300x300.png'

const RecordModal = ({ closeHandler, recordId }) => {
  const { data: recordRecord, error } = useSWR(makeRecordRequestRoute(recordId))

  if (error)
    return (
      <Modal closeHandler={closeHandler} title="Record">
        <FailedToLoadResourceMsg />
      </Modal>
    )

  if (!recordRecord)
    return (
      <Modal closeHandler={closeHandler} title="Record">
        <Loading />
      </Modal>
    )

  const { fields, primaryImageUrl, type, uniqueId } = recordRecord

  let RecordFields

  switch (type) {
    case 'person':
      RecordFields = PersonFullFieldList
      break

    case 'property':
      RecordFields = PropertyFullFieldList
      break

    case 'vehicle':
      RecordFields = VehicleFullFieldList
      break

    default:
      break
  }

  const imgSrc = primaryImageUrl ? getImageSmallUrl(primaryImageUrl) : placeholderImg

  return (
    <Modal closeHandler={closeHandler} title={`Record: ${type} - ${uniqueId}`}>
      <div className="flex flex-column items-center">
        <div className="bg-light-200 p-2 rounded mb-3">
          <Img src={imgSrc} alt="" className="rounded" height="300" weight="300" />
        </div>

        {(fields.dangerous || fields.disease || fields.mentalIllness) && (
          <div className="flex flex-wrap mb-3">
            {fields.dangerous && (
              <div className={`${getSeverityBgClass(3)} flex items-center m-1 rounded`}>
                <div className="font-bold p-2 text-base-100 text-center">
                  Dangerous or Combative
                </div>
              </div>
            )}

            {fields.disease && (
              <div className={`${getSeverityBgClass(2)} flex items-center m-1 rounded`}>
                <div className="font-bold p-2 text-base-100 text-center">Disease/Infection</div>
              </div>
            )}

            {fields.mentalIllness && (
              <div className={`${getSeverityBgClass(1)} flex items-center m-1 rounded`}>
                <div className="font-bold p-2 text-base-100 text-center">
                  Mental Illness or 5150
                </div>
              </div>
            )}
          </div>
        )}

        <div className="mb-3 w-9/12">
          <RecordFields fields={fields} />
        </div>

        <Link to={makeRecordRoute(recordId)}>See full entry</Link>
      </div>
    </Modal>
  )
}

RecordModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  recordId: PropTypes.string.isRequired,
}

export default RecordModal

import { useSelector } from 'react-redux'
import { selectCurrentLocationId } from 'state/ducks/locations'

const useCurrentLocation = () => {
  const currentLocationId = useSelector(selectCurrentLocationId)

  const currentLocation = useSelector(state =>
    state.locations.list.find(location => location.id === currentLocationId),
  )

  return currentLocation
}

export default useCurrentLocation

import React from 'react'
import PropTypes from 'prop-types'

// import SmallBriefRecordCards from '../../../../../../Records/shared/RecordsSmallBrief'

// todo
const RenderRecord = ({ formValues }) => <div key={JSON.stringify(formValues)} />
// formValues.recordIds ? <SmallBriefRecordCards recordIds={formValues.recordIds} badge /> : <div />

RenderRecord.propTypes = {
  formValues: PropTypes.object.isRequired,
}
RenderRecord.defaultProps = {}

export default RenderRecord

import React from 'react'
import PropTypes from 'prop-types'

import DateTime from '@therms/web-common/components/DateTime'

const PersonFields = ({ recordFields }) => {
  const { name, alias, dob, gender, height, weight } = recordFields

  return (
    <div className="p-1">
      <div className="flex">
        <span className="min-w-max-content mb-2 mx-2">
          <div>
            <strong className="font-bold">Name:</strong>
          </div>

          <span>{name || alias || 'N/A'}</span>
        </span>

        <span className="min-w-max-content mx-2">
          <div>
            <strong className="font-bold">Birth:</strong>
          </div>

          <span>
            {dob && <DateTime timestamp={dob} time={false} />}
            {!dob && 'N/A'}
          </span>
        </span>
      </div>

      <div className="flex">
        <span className="min-w-max-content mx-2">
          <div>
            <strong className="font-bold">Gender:</strong>
          </div>

          <span>{gender || 'N/A'}</span>
        </span>

        <span className="min-w-max-content mx-2">
          <div>
            <strong className="font-bold">Height:</strong>
          </div>

          <span>{height || 'N/A'}</span>
        </span>

        <span className="min-w-max-content mx-2">
          <div>
            <strong className="font-bold">Weight:</strong>
          </div>

          <span>{weight || 'N/A'}</span>
        </span>
      </div>
    </div>
  )
}

PersonFields.propTypes = {
  recordFields: PropTypes.shape({
    alias: PropTypes.string,
    dangerous: PropTypes.bool,
    dob: PropTypes.string,
    eyes: PropTypes.string,
    gender: PropTypes.string,
    hair: PropTypes.string,
    height: PropTypes.string,
    name: PropTypes.string,
    race: PropTypes.string,
    weight: PropTypes.number,
  }).isRequired,
}

export default PersonFields

import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash-es'

const RenderDisplaySelect = ({ options, formValue }) => {
  if (!formValue || (Array.isArray(formValue) && !formValue.length)) return null

  let selectedOptions

  // formValue is the index of the selected option
  if (Array.isArray(formValue)) {
    selectedOptions = formValue
      .map(
        optionsIndex =>
          // if custom tag then use the value bcus it's not an index
          options.options[+optionsIndex] || { displayLabel: optionsIndex },
      )
      .map((option, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <span key={option.displayLabel + i}>
          {option.displayLabel || option.formLabel}
          {i + 1 >= formValue.length ? '' : ', '}
        </span>
      ))
  } else {
    // a select field that allows user-created options may not have options.options
    selectedOptions = get(options, `options.${+formValue}`)

    // a custom tag added to the select input won't be an index of an option
    if (selectedOptions)
      selectedOptions =
        selectedOptions.displayLabel || selectedOptions.formLabel
    else selectedOptions = formValue
  }

  return (
    <>
      {options.displayLabel && (
        <div className="font-weight-bold">{options.displayLabel}</div>
      )}

      <span>{selectedOptions}</span>
    </>
  )
}

RenderDisplaySelect.propTypes = {
  formValue: PropTypes.any,
  options: PropTypes.object.isRequired,
}
RenderDisplaySelect.defaultProps = {
  formValue: undefined,
}

export default RenderDisplaySelect

import React from 'react'
import PropTypes from 'prop-types'

const PropertyFields = ({ recordFields }) => {
  const { name, quantity, status, value } = recordFields

  return (
    <div className="p-1">
      <div className="flex">
        <span className="min-w-max-content mb-2 mx-2">
          <div>
            <strong className="font-bold">Name:</strong>
          </div>

          <span>{name || 'N/A'}</span>
        </span>

        <span className="min-w-max-content mx-2">
          <div>
            <strong className="font-bold">Quantity:</strong>
          </div>

          <span>{quantity || 'N/A'}</span>
        </span>
      </div>

      <div className="flex">
        <span className="min-w-max-content mx-2">
          <div>
            <strong className="font-bold">Value:</strong>
          </div>

          <span>{value || 'N/A'}</span>
        </span>

        <span className="min-w-max-content mx-2">
          <div>
            <strong className="font-bold">Status:</strong>
          </div>

          <span>{status || 'N/A'}</span>
        </span>
      </div>
    </div>
  )
}

PropertyFields.propTypes = {
  recordFields: PropTypes.shape({
    brand: PropTypes.string,
    color: PropTypes.string,
    model: PropTypes.string,
    name: PropTypes.string,
    quantity: PropTypes.number,
    status: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
}

export default PropertyFields

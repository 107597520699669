import React from 'react'
import PropTypes from 'prop-types'

import Files from 'modules/Files/shared/Files'
import Rating from './components/Rating'
import RecordThumbsContainer from 'modules/Records/shared/RecordThumbsContainer'

const EntriesDisciplinaryAction = ({ reportEntry }) => {
  const fields = reportEntry.fields || {}

  return (
    <div className="reportentry-container">
      <Rating label="Attendance/Puncuality" rating={fields.attendance} />
      <Rating label="Communication Skills" rating={fields.communication} />
      <Rating label="Teamwork" rating={fields.teamwork} />
      <Rating label="Appearance/Uniform" rating={fields.appearance} />
      <Rating label="Professional Judgement" rating={fields.judgement} />
      <Rating label="Contributes to a Positive Work Environment" rating={fields.environment} />
      <Rating label="Exhibits a Service Oriented Attitude" rating={fields.attitude} />
      <Rating label="Courteous" rating={fields.courteous} />
      <Rating label="Requires Minimal Supervision" rating={fields.requireSupervision} />
      <Rating label="Attendance/Puncuality" rating={fields.attendance} />
      <Rating label="Responds Well to Supervision" rating={fields.respondsSupervision} />
      <Rating label="Completes Duties/Requests" rating={fields.requestsDuties} />

      {fields.other && (
        <>
          <div className="mt-2">
            <strong>Comments</strong>
          </div>

          <div className="with-whitespace">{fields.other}</div>
        </>
      )}

      {fields.recordIds && (
        <div>
          <div className="bg-dark-500 font-bold px-4 py-2 text-right text-base-200">
            Linked Records
          </div>
          <RecordThumbsContainer recordIds={fields.recordIds} />
        </div>
      )}

      <Files fileUrls={fields.files} />
    </div>
  )
}

EntriesDisciplinaryAction.propTypes = {
  reportEntry: PropTypes.object.isRequired,
  // reportEntryType: PropTypes.object.isRequired,
}
EntriesDisciplinaryAction.defaultProps = {}

export default EntriesDisciplinaryAction

import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import DateTime from '@therms/web-common/components/DateTime'

const ReportEntryDateTime = ({ report, reportEntry }) => {
  const showDate = report.dateEnd && !moment(report.dateStart).isSame(report.dateEnd, 'day')
  const { dateEnd } = reportEntry
  const { dateStart } = reportEntry

  return (
    <div className="inline-block text-base-500">
      {showDate && (
        <span className="text-base-700">
          <span className="mr-1">
            <DateTime time={false} timestamp={dateStart} /> /
          </span>
        </span>
      )}

      <span className="">
        <DateTime date={false} timestamp={dateStart} />
        {Boolean(dateEnd) && (
          <>
            {' '}
            - <DateTime date={false} timestamp={dateEnd} />
          </>
        )}
      </span>
    </div>
  )
}

ReportEntryDateTime.propTypes = {
  report: PropTypes.object.isRequired,
  reportEntry: PropTypes.object.isRequired,
}
ReportEntryDateTime.defaultProps = {}

export default ReportEntryDateTime

import React from 'react'
import PropTypes from 'prop-types'

import Files from 'modules/Files/shared/Files'
import RecordThumbsContainer from 'modules/Records/shared/RecordThumbsContainer'

const EntriesInterview = ({ reportEntry }) => {
  const fields = reportEntry.fields || {}

  return (
    <div className="reportentry-container">
      <div className="list-entry-light p-2">
        <strong>Name of Person Interviewed</strong>

        <div>{fields.nameOfInterviewed}</div>
      </div>

      <div className="list-entry-light p-2">
        <strong>Location of Interview</strong>

        <div>{fields.locationOfInterview}</div>
      </div>

      <div className="list-entry-light p-2">
        <strong>Interview Details</strong>

        <div className="with-whitespace">{fields.details}</div>
      </div>

      {fields.notes && (
        <div className="list-entry-light p-2">
          <strong>Notes</strong>

          <div className="with-whitespace">{fields.notes}</div>
        </div>
      )}

      {fields.recordIds && (
        <div>
          <div className="bg-dark-500 font-bold px-4 py-2 text-right text-base-200">
            Linked Records
          </div>
          <RecordThumbsContainer recordIds={fields.recordIds} />
        </div>
      )}

      <Files fileUrls={fields.files} />
    </div>
  )
}

EntriesInterview.propTypes = {
  reportEntry: PropTypes.object.isRequired,
}
EntriesInterview.defaultProps = {}

export default EntriesInterview

import React from 'react'
import PropTypes from 'prop-types'

import ModuleMobileSwitcherItem from './ModuleMobileSwitcher/ModuleMobileSwitcherItem'

const ModuleMobileSwitcher = ({ activeModule, allowedModules, setActiveModule }) => {
  const { dispatch, locationBolos, reports, tasks } = allowedModules

  return (
    <div className="bg-white flex">
      {dispatch && (
        <ModuleMobileSwitcherItem
          activeModule={activeModule}
          iconName="phone-square"
          moduleName="dispatch"
          name="Dispatch"
          setActiveModule={setActiveModule}
        />
      )}

      {locationBolos && (
        <ModuleMobileSwitcherItem
          activeModule={activeModule}
          iconName="exclamation-circle"
          moduleName="locationBolos"
          name="BOLOs"
          setActiveModule={setActiveModule}
        />
      )}

      {reports && (
        <ModuleMobileSwitcherItem
          activeModule={activeModule}
          iconName="file-alt"
          moduleName="reports"
          name="Reports"
          setActiveModule={setActiveModule}
        />
      )}

      {tasks && (
        <ModuleMobileSwitcherItem
          activeModule={activeModule}
          iconName="tasks"
          moduleName="tasks"
          name="Tasks"
          setActiveModule={setActiveModule}
        />
      )}
    </div>
  )
}

ModuleMobileSwitcher.propTypes = {
  activeModule: PropTypes.string,
  allowedModules: PropTypes.object.isRequired,
  setActiveModule: PropTypes.func.isRequired,
}

ModuleMobileSwitcher.defaultProps = {
  activeModule: undefined,
}

export default ModuleMobileSwitcher

import { models } from '@therms/models'

import Arrest from './Arrest'
import Basic from './Basic'
import CameraActivity from './CameraActivity'
import DisciplinaryAction from './DisciplinaryAction'
import Dispatch from './DispatchServiceCall'
import EquipmentDLS from './EquipmentDLS'
import FootPatrol from './FootPatrol'
import Interview from './Interview'
import Observation from './Observation'
import OnDutyInjury from './OnDutyInjury'
import RetailTheft from './RetailTheft'
import StaffPeerEvaluation from './StaffPeerEvaluation'
import Statement from './Statement'
import TagScan from './TagScan'
import Task from './Task'
import UseOfForce from './UseOfForce'
import VehicleAccident from './VehicleAccident'
import VehiclePatrol from './VehiclePatrol'

const { builtInsByName } = models.reportEntryType.fixtures

export default {  
  [builtInsByName.arrest.id]: Arrest,
  [builtInsByName.basic.id]: Basic,
  [builtInsByName.cameraactivity.id]: CameraActivity,
  [builtInsByName.disciplinaryaction.id]: DisciplinaryAction,
  [builtInsByName.dispatchservicecall.id]: Dispatch,
  [builtInsByName.equipmentdls.id]: EquipmentDLS,
  [builtInsByName.footpatrol.id]: FootPatrol,
  [builtInsByName.interview.id]: Interview,
  [builtInsByName.observation.id]: Observation,
  [builtInsByName.ondutyinjury.id]: OnDutyInjury,
  [builtInsByName.retailtheft.id]: RetailTheft,
  [builtInsByName.staffpeerevaluation.id]: StaffPeerEvaluation,
  [builtInsByName.statement.id]: Statement,
  [builtInsByName.tagscan.id]: TagScan,
  [builtInsByName.task.id]: Task,
  [builtInsByName.useofforce.id]: UseOfForce,
  [builtInsByName.vehicleaccident.id]: VehicleAccident,
  [builtInsByName.vehiclepatrol.id]: VehiclePatrol,
}

import { createAction, handleActions } from 'redux-actions'
import portalApi from '../../services/portalApi'

import { keyBy } from 'lodash-es'

const initialState = {
  byId: {},
  hasFetched: false,
}

// actions
const setActivityTypesById = createAction('setActivityTypesById')
const setActivityTypesFetched = createAction('setActivityTypesFetched')

export const fetchAndSetActivityTypesById = () => async dispatch => {
  try {
    const response = await portalApi.get('/activitytype')
    const activityTypesList = response?.data
    const activityTypesById = keyBy(activityTypesList, 'id')

    dispatch(setActivityTypesById(activityTypesById))
    dispatch(setActivityTypesFetched(true))
  } catch (error) {
    console.warn('fetchAndSetActivityTypesById() GET /activitytype failed')

    dispatch(setActivityTypesById({}))
  }
}

export default handleActions(
  {
    [setActivityTypesById]: (state, action) => ({
      ...state,
      byId: action.payload,
    }),

    [setActivityTypesFetched]: (state, action) => ({
      ...state,
      hasFetched: action.payload,
    }),
  },
  initialState,
)

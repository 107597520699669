import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

import { ROUTES_DASHBOARD_ROOT } from 'modules/Dashboard/shared/routePaths'

import { selectCurrentLocationId } from 'state/ducks/locations'

const RedirectOnLocationChange = WrappedComponent => () => {
  const history = useHistory()
  const locationId = useSelector(selectCurrentLocationId)
  const [currentLocationId, setCurrentLocationId] = useState(locationId)

  useEffect(() => {
    if (currentLocationId && locationId !== currentLocationId) {
      setCurrentLocationId(locationId)
      history.push(ROUTES_DASHBOARD_ROOT)
    }
  }, [locationId])

  return <WrappedComponent />
}

RedirectOnLocationChange.propTypes = {
  WrappedComponent: PropTypes.element.isRequired,
}

export default RedirectOnLocationChange

import axios from 'axios'
import moment from 'moment'

export const downloadFile = async (fileUrl) => {
  const response = await axios(fileUrl, { responseType: 'blob' }).catch((e) => console.error(e))
  const fileName = response.headers['x-amz-meta-name']

  const blobUrl = window.URL.createObjectURL(response.data)
  const a = document.createElement('a')

  a.href = blobUrl
  a.download = fileName || `${moment().format('YYYY-MM-DD')}_THERMS`
  a.style.display = 'none'
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

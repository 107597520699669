import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import FAIcon from '@therms/web-common/components/Icon/FAIcon'

const ModuleMobileSwitcherItem = ({
  activeModule,
  iconName,
  moduleName,
  name,
  setActiveModule,
}) => {
  return (
    <button
      className={classnames(
        { 'selected-top-mark text-base-900': activeModule === moduleName },
        'flex flex-grow flex-wrap font-bold items-center highlight-hover justify-center p-1 text-base-700',
      )}
      onClick={() => setActiveModule(moduleName)}
    >
      <FAIcon name={iconName} />
      <span className="mx-1">{name || moduleName}</span>
    </button>
  )
}

ModuleMobileSwitcherItem.propTypes = {
  activeModule: PropTypes.string,
  iconName: PropTypes.string,
  moduleName: PropTypes.string.isRequired,
  name: PropTypes.string,
  setActiveModule: PropTypes.func.isRequired,
}

ModuleMobileSwitcherItem.defaultProps = {
  activeModule: undefined,
  iconName: undefined,
  name: undefined,
}

export default ModuleMobileSwitcherItem

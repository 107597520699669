import React from 'react'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'

import Img from 'components/ImageLoader'
import FailedToLoadResourceMsg from 'components/FailedToLoadResourceMsg'
import Loading from '@therms/web-common/components/Loading'
import { makeRecordRequestRoute } from '../shared/routePaths'
import VehicleFullFieldList from '../components/VehicleFullFieldList'
import { getImageSmallUrl } from 'modules/Files/utils/imageSizeUrls'

const RecordPerson = () => {
  const { recordId } = useParams()
  const { data: recordRecord, error } = useSWR(makeRecordRequestRoute(recordId))

  if (error) return <FailedToLoadResourceMsg />

  if (!recordRecord) return <Loading />

  const { fields, primaryImageUrl, uniqueId } = recordRecord

  return (
    <main>
      <header className="bg-white font-bold px-4 py-2">Vehicle Record - {uniqueId}</header>
      <div className="flex flex-col md:flex-row">
        {!!primaryImageUrl && (
          <div>
            <Img
              src={getImageSmallUrl(primaryImageUrl)}
              alt=""
              className="mx-auto object-cover md:w-auto"
              height="175"
              width="175"
            />
          </div>
        )}

        <VehicleFullFieldList fields={fields} />
      </div>
    </main>
  )
}

export default RecordPerson

import React from 'react'
import PropTypes from 'prop-types'
import useSWR from 'swr'

import { debugOnError } from 'utils/debug'
import { makeReportEntryTypeRequestRoute } from '../../shared/routePaths'
import builtInReportEntryTypePresentationsById from './builtInReportEntryTypePresentationsById'

import FailedToLoadResourceMsg from '../../../../components/FailedToLoadResourceMsg'
import ReportEntryHeader from './components/ReportEntryHeader'
import EntriesCustomReportEntryType from './CustomReportEntryType'
import Loading from '@therms/web-common/components/Loading'

import './ReportEntry.scss'

const ReportEntry = ({ report, reportEntry }) => {
  const { data: reportEntryType, error: reportEntryTypeError } = useSWR(
    makeReportEntryTypeRequestRoute(reportEntry.reportEntryTypeId),
  )

  debugOnError('ReportEntry.jsx', reportEntryTypeError)

  if (!reportEntryType) return <Loading />
  if (reportEntryTypeError) return <FailedToLoadResourceMsg />

  let ReportEntryPresentation = null

  if (/builtin/.test(reportEntryType.id)) {
    ReportEntryPresentation = builtInReportEntryTypePresentationsById[reportEntryType.id]
  } else {
    ReportEntryPresentation = EntriesCustomReportEntryType
  }

  return (
    <div className="report-entry-container shadow">
      <ReportEntryHeader
        report={report}
        reportEntry={reportEntry}
        reportEntryType={reportEntryType}
      />

      <div className="bg-light-400 report-entry-body">
        <ReportEntryPresentation reportEntry={reportEntry} reportEntryType={reportEntryType} />
      </div>
    </div>
  )
}

ReportEntry.propTypes = {
  report: PropTypes.object.isRequired,
  reportEntry: PropTypes.object.isRequired,
}
ReportEntry.defaultProps = {}

export default ReportEntry

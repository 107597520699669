import React from 'react'
// import PropTypes from 'prop-types'

import MailHeader from '../components/MailHeader'
import MailNavigation from '../components/MailNavigation'
import MailRow from '../components/MailRow'

const Sent = () => {
  const sentMails = []

  // placeholder
  for (let step = 0; step < 20; step++) {
    sentMails.push({
      from: 'Patrolman, Bob',
      id: step,
      read: false,
      subject:
        'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nihil vitae, similique odio in qui provident animi rem quae maxime ut labore eius? Tenetur',
      time: '2019-11-2110:17',
    })
  }

  return (
    <div>
      <div className="bg-light-100 p-4">
        <table className="table-fixed w-100">
          <MailHeader>
            <th className="w-3/12 md:w-2/12 lg:w-1/12">Read</th>
            <th className="w-3/12 md:w-3/12 lg:w-2/12">Time</th>
            <th className="w-3/12 md:w-3/12 lg:w-2/12">From</th>
            <th className="w-3/12 md:w-4/12 lg:w-7/12">Subject</th>
          </MailHeader>
          <tbody>
            {sentMails.map(sentMail => (
              <MailRow key={sentMail.id}>
                <td className="py-3 truncate">{sentMail.read || 'pending...'}</td>
                <td className="py-3 truncate">{sentMail.time}</td>
                <td className="font-semibold py-3 truncate">{sentMail.from}</td>
                <td className="font-light py-3 truncate">{sentMail.subject}</td>
              </MailRow>
            ))}
          </tbody>
        </table>
      </div>
      <MailNavigation />
    </div>
  )
}

Sent.propTypes = {}

export default Sent

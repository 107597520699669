import React from 'react'
import PropTypes from 'prop-types'

import ActivityTypeNames from 'modules/ActivityTypes/shared/ActivityTypeNames'

const RenderActivityType = ({ formValue, formValues }) => {
  const activityTypeIds = []

  // backwards compatibility before we hard-coded the name of SelectActivityTypes
  // input in formbuilder name="activityTypeIds" 13DEC2019
  if (Array.isArray(formValue)) {
    activityTypeIds.push(...formValue)
  }

  if (Array.isArray(formValues.activityTypeIds)) {
    activityTypeIds.push(...formValues.activityTypeIds)
  }

  if (activityTypeIds.length) return <ActivityTypeNames ids={activityTypeIds} badge />

  return null
}

RenderActivityType.propTypes = {
  formValue: PropTypes.arrayOf(PropTypes.string),
  formValues: PropTypes.object.isRequired,
}
RenderActivityType.defaultProps = {
  formValue: undefined,
}

export default RenderActivityType

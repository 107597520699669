// babel polyfills
import 'core-js'
import 'regenerator-runtime/runtime'

import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'

if (process.env.NODE_ENV === 'production' && process.env.DEPLOY_ENV) {
  Sentry.init({
    dsn: 'https://136615713bc046c3aea3431eaee37135@o128985.ingest.sentry.io/1475074',
    environment: process.env.DEPLOY_ENV,
    ignoreErrors: [
      /ChunkLoadError/, // this can happen for many reasons but mostly network issues
      // network connection and app in background related errors
      /^Network Error$/,
      /status code 404/,
      /status code 401/,
      /status code 422/,
      /object has no keys/,
      /Request aborted/,
      /timeout of 0ms exceeded/, // axios throws this when network/connx error
      /timeout of 20000ms exceeded/,
      // Sentry WEB-CORE-AY iOS Firefox bug
      /webkit\.messageHandlers\.contextMenuMessageHandler\.postMessage/,
    ],
  })
}

import App from './App'

ReactDOM.render(<App />, document.getElementById('root'))

if (module.hot) {
  module.hot.accept()
}

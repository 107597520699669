import React from 'react'
import PropTypes from 'prop-types'

import FAIcon from '@therms/web-common/components/Icon/FAIcon'
import UserName from 'modules/User/shared/UserNames'
import ActivityTypesNames from '../../../../ActivityTypes/shared/ActivityTypeNames'
import Files from 'modules/Files/shared/Files'
import RecordThumbsContainer from '../../../../Records/shared/RecordThumbsContainer'

const EntriesDisciplinaryAction = ({ reportEntry }) => {
  const fields = reportEntry.fields || {}

  return (
    <div className="reportentry-container">
      {fields.activityTypeIds && (
        <div className="my-2">
          <ActivityTypesNames badge id={fields.activityTypeIds} />
        </div>
      )}

      {fields.reason && (
        <>
          <div className="mt-2">
            <strong>Reason for Disciplinary Action</strong>
          </div>

          <div className="with-whitespace">{fields.reason}</div>
        </>
      )}

      {fields.previousWarning && (
        <div className="text-secondary">
          <FAIcon name="check-square" /> <strong>Previous Warnings Given</strong>
        </div>
      )}

      {fields.actionTaken && (
        <>
          <div className="mt-2">
            <strong>Disciplinary Action Taken</strong>
          </div>

          <div className="with-whitespace">{fields.actionTaken}</div>
        </>
      )}

      <div className="mt-2">
        <strong>Action Taken By</strong> <UserName id={fields.actionByUserId} />
      </div>

      {fields.other && (
        <>
          <div className="mt-2">
            <strong>Other Notes/Details</strong>
          </div>

          <div className="with-whitespace">{fields.other}</div>
        </>
      )}

      {fields.recordIds && (
        <div>
          <div className="bg-dark-500 font-bold px-4 py-2 text-right text-base-200">
            Linked Records
          </div>
          <RecordThumbsContainer recordIds={fields.recordIds} />
        </div>
      )}

      <Files fileUrls={fields.files} />      
    </div>
  )
}

EntriesDisciplinaryAction.propTypes = {
  reportEntry: PropTypes.object.isRequired,
}
EntriesDisciplinaryAction.defaultProps = {}

export default EntriesDisciplinaryAction

export const ROUTES_DISPATCH_ROOT = '/dispatches'
export const ROUTES_DISPATCH_SINGLE = '/dispatches/:dispatchId'
export const API_LOCATION_DISPATCHES = '/location/:locationId/dispatch'
export const API_LOCATION_DISPATCHES_RECENT = '/location/:locationId/dispatch?recent=true'
export const API_LOCATION_DISPATCH_SINGLE = '/location/:locationId/dispatch/:dispatchId'
export const API_DISPATCH_COUNT = '/location/:locationId/dispatch?count=true'

export const makeDispatchRecordRoute = dispatchId => {
  return ROUTES_DISPATCH_SINGLE.replace(':dispatchId', dispatchId)
}

export const makeDispatchesRequestRoute = (locationId, { limit = 10, skip = 0 } = {}) => {
  const path = API_LOCATION_DISPATCHES.replace(':locationId', locationId)
  return `${path}?limit=${limit}&skip=${skip}`
}

export const makeDispatchesRecentRequestRoute = (locationId) => {
  return API_LOCATION_DISPATCHES_RECENT.replace(':locationId', locationId)
}

export const makeDispatchRequestRoute = ({ dispatchId, locationId } = {}) => {
  return API_LOCATION_DISPATCH_SINGLE.replace(':locationId', locationId).replace(
    ':dispatchId',
    dispatchId,
  )
}

export const makeDispatchCountRequestRoute = locationId => {
  return API_DISPATCH_COUNT.replace(':locationId', locationId)
}

import React from 'react'
import PropTypes from 'prop-types'

import ActivityTypeNames from '../../../../ActivityTypes/shared/ActivityTypeNames'
import FAIcon from '@therms/web-common/components/Icon/FAIcon'
import Files from 'modules/Files/shared/Files'
import RecordThumbsContainer from 'modules/Records/shared/RecordThumbsContainer'

const EntriesRetailTheft = ({ reportEntry }) => {
  const fields = reportEntry.fields || {}

  return (
    <div className="reportentry-container">
      {fields.activityTypeIds && (
        <div className="p-1">
          <ActivityTypeNames ids={fields.activityTypeIds} badge />
        </div>
      )}

      {fields.merchandise && (
        <div className="list-entry-light p-2">
          <div>
            <strong>Merchandise Name & Description</strong>
          </div>

          <div>{fields.merchandise}</div>
        </div>
      )}

      {fields.merchRecovered && (
        <div className="font-italic list-entry-light">
          <FAIcon name="check-square" /> Merchandise Recovered
        </div>
      )}

      {fields.suspectInformation && (
        <div className="list-entry-light p-2">
          <div className="font-weight-bold">Suspect Information</div>

          <div className="with-whitespace">{fields.suspectInformation}</div>
        </div>
      )}

      {fields.notes && (
        <div className="list-entry-light p-2">
          <div className="font-weight-bold">Notes</div>

          <div className="with-whitespace">{fields.notes}</div>
        </div>
      )}

      {fields.recordIds && (
        <div>
          <div className="bg-dark-500 font-bold px-4 py-2 text-right text-base-200">
            Linked Records
          </div>
          <RecordThumbsContainer recordIds={fields.recordIds} />
        </div>
      )}

      <Files fileUrls={fields.files} />      
    </div>
  )
}

EntriesRetailTheft.propTypes = {
  reportEntry: PropTypes.object.isRequired,
}
EntriesRetailTheft.defaultProps = {}

export default EntriesRetailTheft

import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import useSWR from 'swr'

import ActivityTypeNames from 'modules/ActivityTypes/shared/ActivityTypeNames'
import DateTime from '@therms/web-common/components/DateTime'
import DispatchEntry from '../components/DispatchEntry'
import Loading from '@therms/web-common/components/Loading'
import LocationName from 'modules/Locations/shared/LocationName'
import FAIcon from '@therms/web-common/components/Icon/FAIcon'
import FailedToLoadResourceMsg from 'components/FailedToLoadResourceMsg'
import RecordThumbsContainer from 'modules/Records/shared/RecordThumbsContainer'
import RibbonField from 'components/RibbonField'

import { makeDispatchRecordRoute, makeDispatchRequestRoute } from './routePaths'
import { selectCurrentLocationId } from 'state/ducks/locations'
import Files from 'modules/Files/shared/Files'

const ServiceCallActionOutline = ({ dispatchId }) => {
  const currentLocationId = useSelector(selectCurrentLocationId)
  const { data: dispatchRecord, error } = useSWR(
    makeDispatchRequestRoute({ dispatchId, locationId: currentLocationId }),
  )

  if (error) return <FailedToLoadResourceMsg />

  if (!dispatchRecord) return <Loading />

  const {
    id,
    locationId,
    subLocationId,
    uniqueId,
    fields: {
      actionNeededClearedSummary,
      actionNeededClearedRecordIds,
      activityTypeIds,
      reportingParty,
      reportingPartySummary,
      reportingPhone,
      reportingTime,
    },
    files,
  } = dispatchRecord

  const dispatchLocation = (
    <div>
      <span>
        <LocationName locationId={locationId} />{' '}
      </span>
      {subLocationId && (
        <span>
          <FAIcon name="angle-right" className="mx-2" />
          <LocationName locationId={subLocationId} />{' '}
        </span>
      )}
    </div>
  )

  return (
    <section>
      <div className="flex flex-wrap">
        <RibbonField name="ID" value={uniqueId} />

        <RibbonField name="Location" value={dispatchLocation} />

        <RibbonField name="Reporting Party" value={reportingParty} />

        <RibbonField name="Reporting Time" value={<DateTime timestamp={reportingTime} />} />

        {reportingPhone && (
          <RibbonField
            name="Phone"
            value={<a href={`tel:${reportingPhone}`}>{reportingPhone}</a>}
          />
        )}
      </div>

      {Array.isArray(activityTypeIds) && (
        <div className="bg-dark-900">
          <ActivityTypeNames ids={activityTypeIds} />
        </div>
      )}

      <DispatchEntry>
        <p className="font-bold">Dispatch Summary:</p>
        <p className="px-2">{reportingPartySummary}</p>
      </DispatchEntry>

      {actionNeededClearedSummary && (
        <>
          <DispatchEntry>
            <p className="font-bold">Disposition:</p>
            <p className="px-2">{actionNeededClearedSummary}</p>
          </DispatchEntry>

          {Boolean(actionNeededClearedRecordIds?.length) && (
            <DispatchEntry>
              <RecordThumbsContainer recordIds={actionNeededClearedRecordIds} />
            </DispatchEntry>
          )}
        </>
      )}

      <Files fileUrls={files} />

      <Link to={makeDispatchRecordRoute(id)} className="non-styled-link">
        <button className="bg-link-500 block font-bold highlight-hover p-1 text-base-100 w-full">
          See Full Entry
        </button>
      </Link>
    </section>
  )
}

ServiceCallActionOutline.propTypes = {
  dispatchId: PropTypes.string.isRequired,
}

export default ServiceCallActionOutline

import { createAction, handleActions } from 'redux-actions'
import portalApi from '../../services/portalApi'

const initialState = {
  currentId: null,
  currentLocation: undefined,
  hasFetched: false,
  list: [],
}

// selectors
export const selectCurrentLocationId = state => state.locations.currentId

// actions
export const setCurrentLocation = createAction('setCurrentLocation')

const setLocations = createAction('setLocations', locationList => locationList)
const setLocationsFetched = createAction('setLocationsFetched')

export const fetchAndSetLocations = () => async dispatch => {
  try {
    const response = await portalApi.get('/location')
    const locationList = response?.data

    dispatch(setLocations(locationList))

    dispatch(setCurrentLocation(locationList[0]))

    dispatch(setLocationsFetched(true))
  } catch (error) {
    console.warn('fetchAndSetLocations() GET /location failed')

    dispatch(setLocations([]))
  }
}

export default handleActions(
  {
    [setCurrentLocation]: (state, action) => ({
      ...state,
      currentId: action.payload.id,
      currentLocation: action.payload,
    }),

    [setLocations]: (state, action) => ({
      ...state,
      list: action.payload,
    }),

    [setLocationsFetched]: (state, action) => ({
      ...state,
      hasFetched: action.payload,
    }),
  },
  initialState,
)

import axios from 'axios'
import { handleAuthFailed } from './auth'
import cookies from "js-cookie"

const axiosConfig = {
  baseURL: process.env.PORTAL_SERVER,

  // headers: {'X-Custom-Header': 'foobar'}
  headers: { 'Cache-Control': 'no-cache' },

  // // `onUploadProgress` allows handling of progress events for uploads
  // onUploadProgress(progressEvent) {
  // // Do whatever you want with the native progress event
  //   console.log('upload', progressEvent.loaded, progressEvent.total)
  // },

  // `onDownloadProgress` allows handling of progress events for downloads
  // onDownloadProgress(progressEvent) {
  // // Do whatever you want with the native progress event
  //   console.log('download', progressEvent.loaded, progressEvent.total)
  // },

  timeout: 30000, // 3g connections can be slow
  withCredentials: true,
}

const portalApi = axios.create(axiosConfig)

portalApi.interceptors.request.use(
  function(config) {
    // add jwt to req header
    // eslint-disable-next-line no-param-reassign
    config.headers[process.env.JWT_HEADER_KEY] = cookies.get(
      process.env.AUTH_COOKIE_NAME,
    )

    return config
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error)
  },
)

// Add a response interceptor
portalApi.interceptors.response.use(
  response => {
    // Do something with response data
    // console.log('ok', response.status)
    return response
  },
  error => {
    if (error.response && error.response.status === 401) handleAuthFailed()
    // else if (error.response.status === 404) console.log('here')

    return Promise.reject(error)
  },
)

export default portalApi

import React from 'react'
import PropTypes from 'prop-types'
import useSWR from 'swr'

import DateTime from '@therms/web-common/components/DateTime/index'
import FailedToLoadResourceMsg from 'components/FailedToLoadResourceMsg'
import Loading from '@therms/web-common/components/Loading'
import { makeTaskExecutionSingleRequestRoute } from '../shared/routePaths'
import RibbonField from 'components/RibbonField'
import UserName from 'modules/User/shared/UserName'

const TaskExecution = ({ id, small }) => {
  const { data: taskExecution, error } = useSWR(makeTaskExecutionSingleRequestRoute(id))

  if (error) return <FailedToLoadResourceMsg />

  if (!taskExecution && small)
    return (
      <div>
        <RibbonField name={<span className="loading" />} value={<span className="loading" />} />
      </div>
    )

  if (!taskExecution) return <Loading />

  const { body, createdBy, timestamp, updatedBy } = taskExecution

  if (small)
    return (
      <div className="flex">
        <div className="w-3/12">
          <RibbonField name="By" value={<UserName userId={updatedBy} />} />
        </div>

        <div className="flex-grow w-3/12">
          <RibbonField name="When" value={<DateTime timestamp={timestamp} relativeTime />} />
        </div>

        {body && (
          <div className="flex-grow w-6/12">
            <RibbonField name="Notes" value={body} />
          </div>
        )}
      </div>
    )

  return (
    <div className="list-entry-light p-3 text-base-800 text-center">
      <div className="flex items-center justify-center">
        <span className="mx-3">Completed By</span>
      </div>

      <div className="font-bold mb-2 mx-3">
        <UserName userId={createdBy} />
        <DateTime timestamp={timestamp} relativeTime />
      </div>

      {body && (
        <div className="mt-2 sm:mx-auto sm:w-3/6">
          <p className="mb-0">Completion Notes</p>
          <p className="italic mb-0">{body}</p>
        </div>
      )}
    </div>
  )
}

TaskExecution.propTypes = {
  id: PropTypes.string.isRequired,
  small: PropTypes.bool,
}

TaskExecution.defaultProps = {
  small: false,
}

export default TaskExecution

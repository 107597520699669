import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import useSWR from 'swr'

import FailedToLoadResourceMsg from 'components/FailedToLoadResourceMsg'

const UserTitle = ({ userId, isCurrentUser }) => {
  const myTitle = useSelector(state => state.auth.portalUser.title)

  if (isCurrentUser) return <>{myTitle}</>

  const { data: user, error } = useSWR(`/user/${userId}`)

  if (error) return <FailedToLoadResourceMsg />

  if (!user) return <span className="loading">...</span>

  return <>{user.title}</>
}

UserTitle.propTypes = {
  isCurrentUser: PropTypes.bool,
  userId: PropTypes.string,
}

UserTitle.defaultProps = {
  isCurrentUser: false,
  userId: '',
}

export default UserTitle

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useSWR from 'swr'

import FailedToLoadResourceMsg from 'components/FailedToLoadResourceMsg'
import { getHumanReadable } from '@therms/web-common/utils/scheduleFrequency'
import Loading from '@therms/web-common/components/Loading'
import { makeSingleTaskRoute, makeSingleTaskRequestRoute } from './shared/routePaths'
import RibbonField from 'components/RibbonField'
import TaskHeader from './components/TaskHeader'
import TaskExecutions from './TaskExecutions'
import TaskExecution from './components/TaskExecution'
import TaskExpander from './components/TaskExpander'

import useIsMobileScreen from 'modules/Layout/shared/useIsMobileScreen'

import './TaskSummary.scss'

const TaskSummary = ({ id }) => {
  const { data: task, error } = useSWR(makeSingleTaskRequestRoute(id))
  const [executionExpanded, setExecutionExpanded] = useState(false)
  const isMobile = useIsMobileScreen()

  if (error) return <FailedToLoadResourceMsg />

  if (!task) return <Loading />

  const { body, lastTaskExecution, occurrence, scheduleFrequency, title } = task

  const handleExpanderClick = () => setExecutionExpanded(!executionExpanded)

  return (
    <section className="task-container shadow">
      <TaskHeader description={body} path={makeSingleTaskRoute(id)} title={title} />

      <div className="flex flex-wrap w-full">
        <RibbonField name="Occurrence" value={occurrence} />
        {scheduleFrequency && (
          <RibbonField name="Time" value={getHumanReadable(scheduleFrequency)} />
        )}
      </div>

      <div className="bg-light-100 flex">
        <div className="flex-grow px-4 py-3 text-center">
          {lastTaskExecution.taskExecutionId && (
            <TaskExecution id={lastTaskExecution.taskExecutionId} />
          )}

          {!lastTaskExecution.taskExecutionId && (
            <div className="list-entry-light px-4 py-3 text-base-700">
              <p className="mb-0">This task has not been yet completed</p>
            </div>
          )}
        </div>

        {!isMobile && (
          <TaskExpander
            expanded={executionExpanded}
            handleOnClick={handleExpanderClick}
            executed={Boolean(lastTaskExecution.taskExecutionId)}
          />
        )}
      </div>

      {executionExpanded && (
        <div>
          <div className="bg-dark-800 p-2 px-3 text-base-200">Task Executions</div>
          <TaskExecutions small taskId={id} />
        </div>
      )}
    </section>
  )
}

TaskSummary.propTypes = {
  id: PropTypes.string.isRequired,
}

export default TaskSummary

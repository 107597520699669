import React from 'react'
import PropTypes from 'prop-types'
import useSWR from 'swr'

import FailedToLoadResourceMsg from 'components/FailedToLoadResourceMsg'
import { makeSingleLocationRequestRoute } from './routePaths'

const LocationAddress = ({ locationId }) => {
  const { data: location, error } = useSWR(makeSingleLocationRequestRoute(locationId))

  if (error) return <FailedToLoadResourceMsg />

  if (!location) return <span className="loading" />

  return (
    <div className="flex sm:block">
      <p className="mb-0 mr-1">{location.address.streetAddress}</p>
      <p className="mb-0 text-base-700 text-sm">
        {location.address.city}, <span className="font-bold">{location.address.state}</span>
      </p>
    </div>
  )
}

LocationAddress.propTypes = {
  locationId: PropTypes.string.isRequired,
}

export default LocationAddress

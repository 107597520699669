import React from 'react'
import PropTypes from 'prop-types'
import useSWR from 'swr'

import FailedToLoadResourceMsg from 'components/FailedToLoadResourceMsg'
import { makeReportTypeRequestRoute } from '../../shared/routePaths'

const ReportTypeName = ({ reportTypeId }) => {
  const { data: reportType, error } = useSWR(makeReportTypeRequestRoute(reportTypeId))

  if (error) return <FailedToLoadResourceMsg />

  if (!reportType) return <span className="loading" />

  return <span>{reportType.name}</span>
}

ReportTypeName.propTypes = {
  reportTypeId: PropTypes.string.isRequired,
}

export default ReportTypeName

export const ROUTES_RECORDS_SINGLE = '/records/:recordId'
export const API_REPORT_SINGLE = '/record/:recordId'

export const makeRecordRoute = recordId => {
  return ROUTES_RECORDS_SINGLE.replace(':recordId', recordId)
}

export const makeRecordRequestRoute = recordId => {
  return API_REPORT_SINGLE.replace(':recordId', recordId)
}

import React from 'react'
import PropTypes from 'prop-types'

const RenderDisplayTextArea = ({ options, formValue }) => {
  if (!formValue) return null

  return (
    <>
      {options.displayLabel && (
        <div className="font-weight-bold">{options.displayLabel}</div>
      )}

      <p className="with-whitespace">{formValue}</p>
    </>
  )
}

RenderDisplayTextArea.propTypes = {
  formValue: PropTypes.any,
  options: PropTypes.object.isRequired,
}
RenderDisplayTextArea.defaultProps = {
  formValue: undefined,
}

export default RenderDisplayTextArea

import React from 'react'
import PropTypes from 'prop-types'
import FAIcon from '@components/Icon/FAIcon'

const RenderDisplayCheckBox = ({ options, formValue }) => (
  <span className="font-weight-medium item-entry-light p-2">
    {formValue ? (
      <span>
        <FAIcon name="check" /> {options.displayCheckedLabel}
      </span>
    ) : (
      options.displayUncheckedLabel
    )}
  </span>
)

RenderDisplayCheckBox.propTypes = {
  formValue: PropTypes.any,
  options: PropTypes.object.isRequired,
}
RenderDisplayCheckBox.defaultProps = {
  formValue: undefined,
}

export default RenderDisplayCheckBox

import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'

import Locations from './screens/Locations'

import RedirectOnLocationChange from 'modules/Locations/shared/RedirectOnLocationChange'
import { ROUTES_LOCATIONS_ROOT } from './shared/routePaths'

const LocationRoutes = () => (
  <Switch>
    <Route exact path={ROUTES_LOCATIONS_ROOT} component={RedirectOnLocationChange(Locations)} />
  </Switch>
)

export default withRouter(LocationRoutes)

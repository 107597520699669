import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { fetchAndSetAggregatedModuleAccess, fetchAndSetAuth } from 'state/ducks/auth'
import ErrorBoundaryWithDetails from '../../../components/ErrorBoundary/ErrorBoundaryWithDetails'
import Loading from '@therms/web-common/components/Loading'

const AuthWrapper = ({ children }) => {
  const dispatch = useDispatch()
  const isAuthenticated = useSelector(state => state.auth.authenticated)

  useEffect(() => {
    dispatch(fetchAndSetAuth())
    dispatch(fetchAndSetAggregatedModuleAccess())
  }, [])

  // todo: make a nice fullscreen loading view
  if (!isAuthenticated) return <Loading />

  return <ErrorBoundaryWithDetails>{children}</ErrorBoundaryWithDetails>
}

AuthWrapper.propTypes = {
  children: PropTypes.any.isRequired,
}

export default AuthWrapper
